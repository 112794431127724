// eslint-disable-next-line no-unused-vars
import { XT1Record } from "../api/client/src";
import authStore from "../store/authStore";

/**
 * 
 * @param {Date | Number} date
 * @param {String} defaultIfNull
 * @returns {String}
 */ 
export function styleDate(date, defaultIfNull) {
  if (date) {
    if (!(date instanceof Date )) {
      date = new Date(date);
    }
    return date.toLocaleDateString("en-us", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
      timeZoneName: "short",
      timeZone: "UTC",
    });
  }
  if (defaultIfNull !== undefined) {
    return defaultIfNull;
  }
  throw TypeError("Tried to style a null Date object, and no default string value was provided");
}

/**
 * 
 * @param {XT1Record} recordToDownload 
 * @returns {String}
 */
export function getdownloadButtonInfo(recordToDownload) {
  const info = {text: "Generate Report (PDF)", enabled: false};

  if (!recordToDownload) {
    return info;
  }

  // if a report for a record doesn't exist
  if (!recordToDownload.report_exists) {
    // If the user is allowed to create a report
    if (authStore.loggedInUserInfo.current_session_permissions.includes("sysReportCreate")) {
      // If it's possible to create a report
      if (recordToDownload.start_time !== null && recordToDownload.stop_time !== null) {
        info.enabled = true;
      }
      // If not possible, button remains disabled
    }
    // If user doesn't have permission, button is hidden
    else {
      return null;
    }
  }
  // If a PDF for a record exists in the database
  else {
    // If the user is allowed to view the report
    if (authStore.loggedInUserInfo.current_session_permissions.includes("sysReportView")) {
      info.text = "View Report (PDF)";
      info.enabled = true;
    }
    // If the user doesn't have permission, button is hidden
    else {
      return null;
    }
  }
  return info;
}