/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
/**
* Enum class StatusEnum.
* @enum {}
* @readonly
*/
export default class StatusEnum {
    
        /**
         * value: "OK"
         * @const
         */
        "OK" = "OK";

    
        /**
         * value: "Alarm"
         * @const
         */
        "Alarm" = "Alarm";

    

    /**
    * Returns a <code>StatusEnum</code> enum value from a Javascript object name.
    * @param {Object} data The plain JavaScript object containing the name of the enum value.
    * @return {module:model/StatusEnum} The enum <code>StatusEnum</code> value.
    */
    static constructFromObject(object) {
        return object;
    }
}

