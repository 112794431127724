/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import BatteryHealthEnum from './BatteryHealthEnum';
import MemoryHealthEnum from './MemoryHealthEnum';
import StatusEnum from './StatusEnum';
import TagStatusEnum from './TagStatusEnum';
import TagTypeEnum from './TagTypeEnum';

/**
 * The XT1Record model module.
 * @module model/XT1Record
 * @version 0.0.0
 */
class XT1Record {
    /**
     * Constructs a new <code>XT1Record</code>.
     * @alias module:model/XT1Record
     * @param id {String} 
     * @param scans {Array.<String>} 
     * @param tagId {Number} 
     * @param firstPoint {Date} 
     * @param lastPoint {Date} 
     * @param numberOfPoints {Number} 
     * @param tripLength {Number} In Seconds
     * @param highestRecordedTemperature {Number} 
     * @param lowestRecordedTemperature {Number} 
     * @param meanKineticTemp {Date} 
     * @param created {Date} 
     * @param lastModified {Date} 
     */
    constructor(id, scans, tagId, firstPoint, lastPoint, numberOfPoints, tripLength, highestRecordedTemperature, lowestRecordedTemperature, meanKineticTemp, created, lastModified) { 
        
        XT1Record.initialize(this, id, scans, tagId, firstPoint, lastPoint, numberOfPoints, tripLength, highestRecordedTemperature, lowestRecordedTemperature, meanKineticTemp, created, lastModified);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, id, scans, tagId, firstPoint, lastPoint, numberOfPoints, tripLength, highestRecordedTemperature, lowestRecordedTemperature, meanKineticTemp, created, lastModified) { 
        obj['id'] = id;
        obj['scans'] = scans;
        obj['tag_id'] = tagId;
        obj['first_point'] = firstPoint;
        obj['last_point'] = lastPoint;
        obj['number_of_points'] = numberOfPoints;
        obj['trip_length'] = tripLength;
        obj['highest_recorded_temperature'] = highestRecordedTemperature;
        obj['lowest_recorded_temperature'] = lowestRecordedTemperature;
        obj['mean_kinetic_temp'] = meanKineticTemp;
        obj['created'] = created;
        obj['last_modified'] = lastModified;
    }

    /**
     * Constructs a <code>XT1Record</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/XT1Record} obj Optional instance to populate.
     * @return {module:model/XT1Record} The populated <code>XT1Record</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new XT1Record();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'String');
            }
            if (data.hasOwnProperty('scans')) {
                obj['scans'] = ApiClient.convertToType(data['scans'], ['String']);
            }
            if (data.hasOwnProperty('tag_id')) {
                obj['tag_id'] = ApiClient.convertToType(data['tag_id'], 'Number');
            }
            if (data.hasOwnProperty('last_scanned')) {
                obj['last_scanned'] = ApiClient.convertToType(data['last_scanned'], 'Date');
            }
            if (data.hasOwnProperty('first_point')) {
                obj['first_point'] = ApiClient.convertToType(data['first_point'], 'Date');
            }
            if (data.hasOwnProperty('last_point')) {
                obj['last_point'] = ApiClient.convertToType(data['last_point'], 'Date');
            }
            if (data.hasOwnProperty('number_of_points')) {
                obj['number_of_points'] = ApiClient.convertToType(data['number_of_points'], 'Number');
            }
            if (data.hasOwnProperty('trip_length')) {
                obj['trip_length'] = ApiClient.convertToType(data['trip_length'], 'Number');
            }
            if (data.hasOwnProperty('highest_recorded_temperature')) {
                obj['highest_recorded_temperature'] = ApiClient.convertToType(data['highest_recorded_temperature'], 'Number');
            }
            if (data.hasOwnProperty('lowest_recorded_temperature')) {
                obj['lowest_recorded_temperature'] = ApiClient.convertToType(data['lowest_recorded_temperature'], 'Number');
            }
            if (data.hasOwnProperty('mean_kinetic_temp')) {
                obj['mean_kinetic_temp'] = ApiClient.convertToType(data['mean_kinetic_temp'], 'Date');
            }
            if (data.hasOwnProperty('created')) {
                obj['created'] = ApiClient.convertToType(data['created'], 'Date');
            }
            if (data.hasOwnProperty('last_modified')) {
                obj['last_modified'] = ApiClient.convertToType(data['last_modified'], 'Date');
            }
            if (data.hasOwnProperty('last_action_taken')) {
                obj['last_action_taken'] = ApiClient.convertToType(data['last_action_taken'], 'Date');
            }
            if (data.hasOwnProperty('status')) {
                obj['status'] = StatusEnum.constructFromObject(data['status']);
            }
            if (data.hasOwnProperty('primary_reference')) {
                obj['primary_reference'] = ApiClient.convertToType(data['primary_reference'], 'String');
            }
            if (data.hasOwnProperty('secondary_reference')) {
                obj['secondary_reference'] = ApiClient.convertToType(data['secondary_reference'], 'String');
            }
            if (data.hasOwnProperty('shipping_reference')) {
                obj['shipping_reference'] = ApiClient.convertToType(data['shipping_reference'], 'String');
            }
            if (data.hasOwnProperty('start_time')) {
                obj['start_time'] = ApiClient.convertToType(data['start_time'], 'Date');
            }
            if (data.hasOwnProperty('stop_time')) {
                obj['stop_time'] = ApiClient.convertToType(data['stop_time'], 'Date');
            }
            if (data.hasOwnProperty('measurement_interval')) {
                obj['measurement_interval'] = ApiClient.convertToType(data['measurement_interval'], 'Number');
            }
            if (data.hasOwnProperty('start_delay')) {
                obj['start_delay'] = ApiClient.convertToType(data['start_delay'], 'Number');
            }
            if (data.hasOwnProperty('high_threshold')) {
                obj['high_threshold'] = ApiClient.convertToType(data['high_threshold'], 'Number');
            }
            if (data.hasOwnProperty('low_threshold')) {
                obj['low_threshold'] = ApiClient.convertToType(data['low_threshold'], 'Number');
            }
            if (data.hasOwnProperty('last_latitude')) {
                obj['last_latitude'] = ApiClient.convertToType(data['last_latitude'], 'Number');
            }
            if (data.hasOwnProperty('last_longitude')) {
                obj['last_longitude'] = ApiClient.convertToType(data['last_longitude'], 'Number');
            }
            if (data.hasOwnProperty('tag_type')) {
                obj['tag_type'] = TagTypeEnum.constructFromObject(data['tag_type']);
            }
            if (data.hasOwnProperty('tag_status')) {
                obj['tag_status'] = ApiClient.convertToType(data['tag_status'], TagStatusEnum);
            }
            if (data.hasOwnProperty('memory_health')) {
                obj['memory_health'] = ApiClient.convertToType(data['memory_health'], MemoryHealthEnum);
            }
            if (data.hasOwnProperty('battery_health')) {
                obj['battery_health'] = ApiClient.convertToType(data['battery_health'], BatteryHealthEnum);
            }
            if (data.hasOwnProperty('num_consecutive_excursions')) {
                obj['num_consecutive_excursions'] = ApiClient.convertToType(data['num_consecutive_excursions'], 'Number');
            }
            if (data.hasOwnProperty('report_exists')) {
                obj['report_exists'] = ApiClient.convertToType(data['report_exists'], 'Boolean');
            }
        }
        return obj;
    }


}

/**
 * @member {String} id
 */
XT1Record.prototype['id'] = undefined;

/**
 * @member {Array.<String>} scans
 */
XT1Record.prototype['scans'] = undefined;

/**
 * @member {Number} tag_id
 */
XT1Record.prototype['tag_id'] = undefined;

/**
 * @member {Date} last_scanned
 */
XT1Record.prototype['last_scanned'] = undefined;

/**
 * @member {Date} first_point
 */
XT1Record.prototype['first_point'] = undefined;

/**
 * @member {Date} last_point
 */
XT1Record.prototype['last_point'] = undefined;

/**
 * @member {Number} number_of_points
 */
XT1Record.prototype['number_of_points'] = undefined;

/**
 * In Seconds
 * @member {Number} trip_length
 */
XT1Record.prototype['trip_length'] = undefined;

/**
 * @member {Number} highest_recorded_temperature
 */
XT1Record.prototype['highest_recorded_temperature'] = undefined;

/**
 * @member {Number} lowest_recorded_temperature
 */
XT1Record.prototype['lowest_recorded_temperature'] = undefined;

/**
 * @member {Date} mean_kinetic_temp
 */
XT1Record.prototype['mean_kinetic_temp'] = undefined;

/**
 * @member {Date} created
 */
XT1Record.prototype['created'] = undefined;

/**
 * @member {Date} last_modified
 */
XT1Record.prototype['last_modified'] = undefined;

/**
 * Datetime of the most recent device action taken of any kind
 * @member {Date} last_action_taken
 */
XT1Record.prototype['last_action_taken'] = undefined;

/**
 * @member {module:model/StatusEnum} status
 */
XT1Record.prototype['status'] = undefined;

/**
 * @member {String} primary_reference
 */
XT1Record.prototype['primary_reference'] = undefined;

/**
 * @member {String} secondary_reference
 */
XT1Record.prototype['secondary_reference'] = undefined;

/**
 * @member {String} shipping_reference
 */
XT1Record.prototype['shipping_reference'] = undefined;

/**
 * @member {Date} start_time
 */
XT1Record.prototype['start_time'] = undefined;

/**
 * @member {Date} stop_time
 */
XT1Record.prototype['stop_time'] = undefined;

/**
 * In minutes
 * @member {Number} measurement_interval
 */
XT1Record.prototype['measurement_interval'] = undefined;

/**
 * In minutes
 * @member {Number} start_delay
 */
XT1Record.prototype['start_delay'] = undefined;

/**
 * In Degrees Celsius
 * @member {Number} high_threshold
 */
XT1Record.prototype['high_threshold'] = undefined;

/**
 * In Degrees Celsius
 * @member {Number} low_threshold
 */
XT1Record.prototype['low_threshold'] = undefined;

/**
 * @member {Number} last_latitude
 */
XT1Record.prototype['last_latitude'] = undefined;

/**
 * @member {Number} last_longitude
 */
XT1Record.prototype['last_longitude'] = undefined;

/**
 * @member {module:model/TagTypeEnum} tag_type
 */
XT1Record.prototype['tag_type'] = undefined;

/**
 * @member {module:model/TagStatusEnum} tag_status
 */
XT1Record.prototype['tag_status'] = undefined;

/**
 * @member {module:model/MemoryHealthEnum} memory_health
 */
XT1Record.prototype['memory_health'] = undefined;

/**
 * @member {module:model/BatteryHealthEnum} battery_health
 */
XT1Record.prototype['battery_health'] = undefined;

/**
 * Number of consecutive temperature points outside thresholds before an alarm is triggered
 * @member {Number} num_consecutive_excursions
 */
XT1Record.prototype['num_consecutive_excursions'] = undefined;

/**
 * @member {Boolean} report_exists
 */
XT1Record.prototype['report_exists'] = undefined;






export default XT1Record;

