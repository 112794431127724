import { view } from "@risingstack/react-easy-state";
import { 
  Box, 
  Button, 
  Container, 
  Typography, 
  Paper 
} from "@mui/material";
import browserHistory from "../../router";
import { Logo } from "../../components/Logo";
import SupportEmail from "../../components/SupportEmail";

const ForgotPasswordConfirm = view(() => {
  return (
    <Paper variant="background">
      <Container maxWidth="xs" variant="card">
        <Logo />
        <Typography variant="h4">Email Sent</Typography>
        <Box
          textAlign="center"
          sx={{
            maxWidth: "277px",
            margin: "auto",
            mb: "25px",
            "@media (min-width:425px)": {
              maxWidth: "344px",
            },
          }}
        >
          <Typography variant="body2" sx={{ my: 2 }}>
            Please check your email.
          </Typography>
          <Typography variant="body2">
            If an account with the email address you entered exists, you will
            receive an email with instructions on how to reset your password.
          </Typography>
        </Box>
        <Box sx={{ display: "block", justifyContent: "center", width: "100%", mb:"25px" }}>
          <Button
            variant="contained"
            fullWidth
            onClick={() => browserHistory.back()}
            sx={{ fontWeight: "bold" }}
          >
            Enter Another Email Address
          </Button>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            "@media (min-width:576px)": {
              flexDirection: "row",
            },
          }}
        >
          <SupportEmail />
        </Box>
      </Container>
    </Paper>
  );
});

export default ForgotPasswordConfirm;
