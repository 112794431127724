import * as React from "react";
import {
  AppBar,
  Box,
  Button,
  Container,
  IconButton,
  Link,
  Menu,
  MenuItem,
  styled,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import authStore from "../store/authStore";
// import browserHistory from "../router";
import { store, view } from "@risingstack/react-easy-state";
import SearchBar from "./SearchBar";
import Notifications from "./Notifications";

const menuItems = [
  // {
  //   title: "Admin settings",
  //   onClick: () => browserHistory.push("/ServerAdmin"),
  // },
  {
    title: "Log Out",
    onClick: authStore.logout,
  },
];

const Offset = styled('div')(({ theme }) => theme.mixins.toolbar);

const OrgSwitcher = view(() => {
  const state = store({
    anchorEl: null,
    get open() {return !!state.anchorEl},
    clickOpen(event) {state.anchorEl = event.currentTarget},
    clickClose() {state.anchorEl = null;}
  })

  if (authStore.orgs.length < 2) {
    return <></>;
  }
  return (
    <>
      <Button
        id="basic-button"
        aria-controls={state.open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={state.open ? 'true' : undefined}
        onClick={state.clickOpen}
      >
        {authStore.orgs.find(org => org.id === authStore.loggedInUserInfo.last_session_org_id).name}
      </Button>
      <Menu
        id="org-switch-menu"
        variant="menu"
        anchorEl={state.anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={state.open}
        onClose={state.clickClose}
      >
        {authStore.orgs.map((org) => (
          <MenuItem key={org.id} onClick={() => {
            authStore.switchOrgContext(org.id).then(() => window.location.reload(false))
          }}>
            {org.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
});


const Header = view(({classes}) => {
  const state = store({
    anchorEl: null,
  });

  const handleOpenMenu = (event) => {
    state.anchorEl = event.currentTarget;
  };

  const handleCloseMenu = () => {
    state.anchorEl = null;
  };

  return (
    <>
      <AppBar
        position="fixed"
        elevation={0}
        sx={{
          zIndex: (theme) => theme.zIndex.drawer - 1,
          backgroundColor: "#FFF",
          borderBottom: (theme) => `1px solid ${theme.palette.grey[200]}`,
          paddingLeft: '20px',
          '@media (min-width:768px)': {
            paddingLeft: '102px',
          },
        }}
      >
        <Container variant='grid'>
          <Toolbar variant='nopadding'>
            <Link 
              href="/" 
              sx={{
                display: 'block',
                '@media (min-width:768px)': {
                  display: 'none',
                },
                '&:active':{background:'initial'}
                }}
            >
              <img
                src="../xtcloud-lockup-color.png"
                alt="XTCloud logo"
                height={"25px"}
              />
            </Link>
            <SearchBar />
            <Box sx={{ flexGrow: 0 }}>
              <OrgSwitcher/>
              <Notifications />
              <Tooltip title="Open menu">
                <IconButton 
                  onClick={handleOpenMenu} 
                  aria-label="menu"
                  sx={{
                    display:"inline-block",
                    '@media (min-width:768px)': {
                      display: 'none',
                    },
                  }}
                >
                  <AccountCircleIcon fontSize="large" color="primary" />
                </IconButton>
              </Tooltip>
              <Box 
                component="span" 
                color="primary.main"
                onClick={authStore.logout}
                sx={{
                  fontWeight: 'bold',
                  fontSize: '14px',
                  mr: "20px",
                  display: 'none',
                  cursor: 'pointer',
                  '@media (min-width:768px)': {
                    display: 'inline-block',
                  },
                }}
              >
                LOG OUT
              </Box>
              <Menu
                sx={{ 
                  mt: "45px", 
                }}
                id="menu-appbar"
                anchorEl={state.anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                open={Boolean(state.anchorEl)}
                onClose={handleCloseMenu}
              >
                {menuItems.map((menuItem) => (
                  <MenuItem key={menuItem.title} onClick={handleCloseMenu}>
                    <Typography onClick={menuItem.onClick} textAlign="center">
                      {menuItem.title}
                    </Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <Offset />
    </>
  );
});

export default Header;
