import { store } from "@risingstack/react-easy-state";
// eslint-disable-next-line no-unused-vars
import { Alarm, AlarmDismissalUpload, ExcursionDetails } from "../api/client/src";
import { devicesApi } from "../api/clientInstances";

/**
 * @type {{
 * alarms: Alarm[],
 * alarmsLoading: boolean,
 * excursions: ExcursionDetails[],
 * excursionsLoading: boolean,
 * clear: () => void,
 * getAlarms: () => Promise<Alarm>,
 * getExcursionsForRecord: (record_id: str) => Promise<ExcursionDetails>,
 * dismissAlarm: (alarm_id: str) => Promise<any>,
 * dismissAllAlarms: () => Promise<any>,
 * }}
 */
const alarmStore = store({
  alarms: [],
  alarmsLoading: true,
  excursions: [],
  excursionsLoading: false,
  clear() {
    alarmStore.alarms = [];
    alarmStore.alarmsLoading = true;
    alarmStore.excursions = [];
    alarmStore.excursionsLoading = false;
  },
  getAlarms() {
    return devicesApi.getAlarms().then(data => {
      alarmStore.alarms = data;
      alarmStore.alarmsLoading = false;
      return data;
    });
  },
  getExcursionsForRecord(record_id) {
    alarmStore.excursions = [];
    alarmStore.excursionsLoading = true;
    return devicesApi.getExcursionsForRecord(record_id)
      .then(data => {
        alarmStore.excursions = data;
        alarmStore.excursionsLoading = false;
        return alarmStore.alarms;
      })
  },
  dismissAlarm(alarm_id) {
    return devicesApi.dismissAlarm(
      new AlarmDismissalUpload(alarm_id, "WEB")
    )
  },
  dismissAllAlarms() {
    for (const alarm of alarmStore.alarms) {
      alarmStore.dismissAlarm(alarm.id);
    }
    alarmStore.alarms = [];
  }
});

export default alarmStore