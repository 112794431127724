import { store, view } from "@risingstack/react-easy-state";
import { useSearchParams } from "react-router-dom";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { styled } from "@mui/material/styles";
import browserHistory from "../router";
import {Link, InputBase, Box} from "@mui/material";
import { useAutocomplete } from '@mui/base/AutocompleteUnstyled';
import { autocompleteClasses } from '@mui/material/Autocomplete';
import deviceStore from "../store/deviceStore";

const Search = styled("div")(({ theme }) => ({
  "& .MuiInputBase-root": {
    width: "100%"
  },
  display: "none",
  [theme.breakpoints.up("ms")]: {
    display:'block',
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    marginRight: theme.spacing(2),
    maxWidth:"720px",
    marginLeft: theme.spacing(3),
    width: "50%",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 1),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: theme.palette.grey[400],
  zIndex: 1
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: theme.palette.common.black,
  transition: theme.transitions.create("width"),
  "& .MuiInputBase-input": {
    border:'1px solid #CED4DA',
    borderRadius: '4px',
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(3)})`,
    transition: theme.transitions.create("border"),
    width: "100%",
    "&:hover": {
      border:`1px solid ${theme.palette.primary.main}`,
      // borderRadius: '4px',
      transition: theme.transitions.create("border"),
    },
  },
}));

const Listbox = styled('ul')(
  ({ theme }) => `
  width: 100%;
  margin: 2px 0 0;
  padding: 10px;
  border-radius: 8px;
  position: absolute;
  list-style: none;
  background-color: #fff;
  overflow: auto;
  box-shadow: 0px 4px 45px rgba(11, 53, 181, 0.1);
  z-index: 1;

  & li {
    padding: 8px 12px;
    color: #262626;
    font-size: 16px;
    background-color: white;
    &.all-results {
      color: #0B35B5;
      cursor: pointer;
    }
    &:hover {
      background-color: #F3F6FF;
    }
    & span {
      color: #797B80;
      font-weight: 400;
    }
    & .MuiLink-root {
      font-size: 16px;
      text-decoration: none;
      font-weight: bold;
    }

    & li[aria-selected='true'] {
      background-color: white;
      font-weight: 600;
      color: '#262626'
    }
  
    & li.${autocompleteClasses.focused} {
      background-color: ${theme.palette.mode === 'dark' ? '#003b57' : '#e6f7ff'};
      cursor: pointer;
      color: #262626;
    }
  }`)

const SearchBar = view(() => {
  const setSearchParams = useSearchParams()[1];
  // let [limit, setLimit] = useState(5)
  const limit = store({
    value: 5,
    reset: () => (limit.value = 5),
    onChange: () => (limit.value = deviceStore.records.length)
  });
  const {
    getRootProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
  } = useAutocomplete({
    id: 'search-record',
    options: deviceStore.records,
    // filterOptions: options => options.id,
    // inputProps: {"aria-label": "Search by tag, record, or reference ID..."},
    getOptionLabel: (option) => option.id,
  });

  return (
    <Search>
      <SearchIconWrapper>
        <SearchOutlinedIcon />
      </SearchIconWrapper>
      <div {...getRootProps()}>
        <StyledInputBase
          inputProps={{...getInputProps()}}
          placeholder="Search"
          // onChange={handleChange}
          // value={searchParams.searchTerms} 
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              if (browserHistory.location.pathname !== "/search") {
                browserHistory.push({
                  pathname: "/search",
                  search: "?" + new URLSearchParams({searchTerms: e.target.value}).toString(),
                });
              }
              else {
                setSearchParams({searchTerms: e.target.value});
              }
            } else {
              limit.reset();
            }
          }}
        />
      </div>
        {groupedOptions.length > 0 ? (
          <Listbox {...getListboxProps()}>
            {groupedOptions.slice(0, limit.value).map((option, index) => (
              <li {...getOptionProps({ option, index })}>
                <Link href={`/recorddetail/${option.id}`} >
                  {`${option.id} `}<Box component="span">record has an alarm</Box>
                </Link>
              </li>
            ))}
            {groupedOptions.length > limit.value ? 
              (<li className="all-results" onClick={limit.onChange}>
                view all results
              </li>)
              : null
            }
          </Listbox>
        ) : null}
  </Search>
  );
});

export default SearchBar;
