import { view } from "@risingstack/react-easy-state";
import { Box, Typography } from "@mui/material";
import { GridToolbar } from "@mui/x-data-grid-premium";
import RecordGridPremium from "../RecordGridPremium";
import deviceStore from "../../store/deviceStore";
// eslint-disable-next-line no-unused-vars
import { XT1Scan } from "../../api/client/src";
import { styleDate } from "../../utils/utilFunctions";

const columns = [
  {
    field: "name",
    headerName: "Name",
    valueGetter: /** @param {{row: XT1Scan}} */ ({ row }) => {
      if (row.created_by_user.first_name || row.created_by_user.last_name) {
        return [row.created_by_user.first_name, row.created_by_user.last_name].join(" ");
      } else {
        return "No data";
      }
    },
    flex: 1,
  },
  {
    field: "time",
    headerName: "Time",
    valueGetter: /** @param {{row: XT1Scan}} */({ row }) => {
      return row.timestamp.toString();
    },
    valueFormatter: ({value}) => {
      return styleDate(value, "No data");
    },
    flex: 1,
  },
  {
    field: "location",
    headerName: "Location",
    valueGetter: /** @param {{row: XT1Scan}} */({ row }) => {
      if (row.latitude !== null && row.longitude !== null) {
        return [row.latitude, row.longitude].join(", ");
      } else {
        return "No data";
      }
    },
    flex: 1,
  },
  {
    field: "device_type",
    headerName: "Device type",
    valueGetter: /** @param {{row: XT1Scan}} */({ row }) => {
      return row.mobile_device_type;
    },
    flex: 1,
  },
  {
    field: "app_version",
    headerName: "App Version",
    valueGetter: /** @param {{row: XT1Scan}} */({ row }) => {
      return row.mobile_app_version;
    },
   flex: 1,
  },
];

const ScanDetails = view(({ scans }) => {
  if (!deviceStore.scansLoading) {
    return (
      <Box
        sx={{
          height: 400,
        }}
      >
        <RecordGridPremium
          components={{ Toolbar: GridToolbar }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
          disableDensitySelector
          rows={scans}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          disableSelectionOnClick
        />
      </Box>
    );
  } else {
    return <Typography>This record has not loaded yet.</Typography>;
  }
});

export default ScanDetails;
