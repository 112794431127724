
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { FormControl, InputLabel, MenuItem, Select, Avatar, TextField,
  Checkbox, Grid, Box, Typography, Button, ListItemText } from '@mui/material';
import {view, store, autoEffect} from '@risingstack/react-easy-state';
import OrganizationRegisterForm from '../../api/client/src/model/OrganizationRegisterForm';
import { authApi } from '../../api/clientInstances';
import { haltPromiseChain } from '../../api/StopPromise';
import authStore from '../../store/authStore';

const CreateOrgModal = view(() => {
  autoEffect(async () => {
    await authApi.listUsersWithHttpInfo()
      .catch(() => {return haltPromiseChain();})
      .then(result => {
        if (result.data) {
          store_.users = result.data;
        }
      });
  })

  const store_ = store({
    users: [],
    showErrors: false,
  });
  const form = store(new OrganizationRegisterForm("", [], []));

  const handleSubmit = (event) => {
    store_.showErrors = true;
    event.preventDefault();
    
    console.log(form);
    authStore.createOrg(form).then(res => console.log(res));
  };

  return (
    <Box
      sx={{
        marginTop: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        Create Organization
      </Typography>
      <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              name="name"
              id="name"
              label="Name"
              value={form.name}
              onChange={(e) => form.name = e.target.value}
              required
              fullWidth
              autoFocus
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="users-dropdown">Users</InputLabel>
              <Select
                labelId="users-dropdown"
                id="users"
                name="users"
                label="Users"
                multiple
                renderValue={(selected) => store_.users.filter(user => selected.includes(user.id)).map(user => user.username).join(', ')}
                value={form.users}
                onChange={e => {
                  const ids = e.target.value;
                  console.log(ids);
                  form.users = ids;
                }}
              >
                {store_.users.map((user) => (
                  <MenuItem key={user.id} value={user.id}>
                    <Checkbox checked={form.users.includes(user.id)} />
                    <ListItemText primary={(user.username)} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="admin-users-dropdown">Admins</InputLabel>
              <Select
                labelId="admin-users-dropdown"
                id="admin-users"
                name="admin-users"
                label="Admins"
                multiple
                renderValue={(selected) => store_.users.filter(user => selected.includes(user.id)).map(user => user.username).join(', ')}
                value={form.admin_users}
                onChange={e => {
                  const ids = e.target.value;
                  console.log(ids);
                  form.admin_users = ids;
                }}
              >
                {store_.users.map((user) => (
                  <MenuItem key={user.id} value={user.id}>
                    <Checkbox checked={form.admin_users.includes(user.id)} />
                    <ListItemText primary={(user.username)} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Register
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
});

export default CreateOrgModal;