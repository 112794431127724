import { store, view } from "@risingstack/react-easy-state";
import { devicesApi } from "../api/clientInstances";
import {
  Alert,
  Badge,
  Box,
  Button,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import { AlarmDismissalUpload } from "../api/client/src";
import alarmStore from "../store/alarmStore";


const ClearAllButton = view(({alarmsArray, onClick}) => {
  if (alarmsArray.length === 0) {
    return <Typography>No new notifications</Typography>;
  } else {
    return (
      <Button
        variant="contained"
        disableElevation
        onClick={onClick}
        size={"small"}
      >
        Dismiss All
      </Button>
    );
  }
})


const Notifications = view(() => {
  const state = store({
    anchorEl: null,
    get count() {return alarmStore.alarms.length;},
    // Added for accessibility and screen readers
    get notificationsLabel() {
      if (state.count === 0) {
        return "No notifications";
      }
      if (state.count > 99) {
        return "More than 99 notifications";
      }
      return `${state.count} notifications`;
    }
  });

  const handleClickMenu = (event) => {
    state.anchorEl = event.currentTarget;
  };

  const handleCloseMenu = () => {
    state.anchorEl = null;
  };


  return (
    <>
      <Tooltip title="View notifications">
        <IconButton
          aria-label={state.notificationsLabel}
          onClick={handleClickMenu}
          variant="notification"
        >
          <Badge badgeContent={state.count} color="secondary" max={99}>
            <NotificationsNoneOutlinedIcon fontSize="large" color="grey[500]" />
          </Badge>
        </IconButton>
      </Tooltip>
      <Menu
        id="notifications-menu"
        variant="menu"
        anchorEl={state.anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={Boolean(state.anchorEl)}
        onClose={handleCloseMenu}
      >
        {alarmStore.alarms.map((alarm, index) => (
          <MenuItem key={alarm.id} >
            <Alert
              severity="error"
              closeText="Dismiss"
              onClose={() => {
                devicesApi
                  .dismissAlarm(new AlarmDismissalUpload(alarm.id, "WEB"))
                  .then(() => {
                    alarmStore.alarms.splice(index, 1);
                  });
              }}
              sx={{ 
                border: "none",
                width: "100%",
                color: "#5B5C60"
              }}
            >
              <Link href={"/recorddetail/" + alarm.record_id} fontWeight={"bold"}>
                Record {alarm.record_id}
              </Link>
              &nbsp;has an alarm
            </Alert>
          </MenuItem>
        ))}
        <Box textAlign="right" px={2.5} py={1}>
          <ClearAllButton
            alarmsArray={alarmStore.alarms}
            onClick={alarmStore.dismissAllAlarms}
          />
        </Box>
      </Menu>
    </>
  );
});

export default Notifications;
