import {
  Route,
  unstable_HistoryRouter as HistoryRouter,
  Routes,
  Outlet,
} from "react-router-dom";
import browserHistory from "./router/index";
import { autoEffect, clearEffect, view } from "@risingstack/react-easy-state";
import authStore from "./store/authStore";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import ForgotPassword from "./pages/password-resetting/ForgotPassword";
import ChangePassword from "./pages/password-resetting/ChangePassword";
import ChangePasswordConfirm from "./pages/password-resetting/ChangePasswordConfirm";
import ServerAdmin from "./pages/ServerAdmin";
import AllRecords from "./pages/AllRecords";
import RecordDetail from "./pages/RecordDetail";
import PageNotFound from "./pages/PageNotFound";
import { ThemeProvider } from "@mui/system";
import theme from "./theme";
import { Box, CssBaseline } from "@mui/material";
import { LicenseInfo } from "@mui/x-license-pro";
import SearchResults from "./pages/SearchResults";
import AllNavigation from "./components/AllNavigation";
import ForgotPasswordConfirm from "./pages/password-resetting/ForgotPasswordConfirm";
import deviceStore from "./store/deviceStore";
import alarmStore from "./store/alarmStore";

LicenseInfo.setLicenseKey(
  "ac19df703d7cb1bbe5b6eb34bbfa3981Tz00NzY5NCxFPTE2ODk5NTk4MjQ4NTMsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y"
);

const effect = autoEffect(async () => {
  await authStore.getSession();
  authStore.getOrgs();
  deviceStore.getRecords();
  alarmStore.getAlarms();
});
clearEffect(effect);


const Layout = () => {
  return (
    <>
      <AllNavigation />
      <Box
        sx={{
          marginLeft: "0",
          "@media (min-width:768px)": { marginLeft: "102px" },
          "@media (max-width:768px)": { paddingBottom: "109px" },
        }}
      >
        <Outlet />
      </Box>
    </>
  )
};

const App = view(() => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <HistoryRouter history={browserHistory}>
        <Routes>
          {/* These routes won't get the header and side navigation declared in the Layout component */}
          <Route path="/login" element={<Login />} />
          <Route path="/forgotpassword" element={<ForgotPassword />} />
          <Route path="/forgotpasswordconfirm" element={<ForgotPasswordConfirm />} />
          <Route path="/passwordreset/:uuid/:token" element={<ChangePassword />} />
          <Route path="/passwordresetconfirm" element={<ChangePasswordConfirm />} />
          <Route path="/" element={<Layout />}>
            {/* These routes *will* get the header and side navigation declared in the Layout component */}
            <Route path="/" element={<Dashboard />} />
            <Route path="/serveradmin" element={<ServerAdmin />} />
            <Route path="/allrecords" element={<AllRecords />} />
            <Route path="/recorddetail/:record_id" element={<RecordDetail />}/>
            <Route path="/search" element={<SearchResults/>}/>
            <Route path="/*" element={<PageNotFound />} />
          </Route>
        </Routes>
      </HistoryRouter>
    </ThemeProvider>
  );
});

export default App;
