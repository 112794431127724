/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import Alarm from '../model/Alarm';
import AlarmAcknowledgementUpload from '../model/AlarmAcknowledgementUpload';
import AlarmDetails from '../model/AlarmDetails';
import AlarmDismissalUpload from '../model/AlarmDismissalUpload';
import ConfigureActionUpload from '../model/ConfigureActionUpload';
import DeviceActionUploadResult from '../model/DeviceActionUploadResult';
import ExcursionDetails from '../model/ExcursionDetails';
import FileURL from '../model/FileURL';
import Generic from '../model/Generic';
import RecordID from '../model/RecordID';
import StartActionUpload from '../model/StartActionUpload';
import StopActionUpload from '../model/StopActionUpload';
import UpdateReferencesActionUpload from '../model/UpdateReferencesActionUpload';
import XT1Record from '../model/XT1Record';
import XT1Scan from '../model/XT1Scan';
import XT1ScanUpload from '../model/XT1ScanUpload';
import XT1Sensor from '../model/XT1Sensor';

/**
* Devices service.
* @module api/DevicesApi
* @version 0.0.0
*/
export default class DevicesApi {

    /**
    * Constructs a new DevicesApi. 
    * @alias module:api/DevicesApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * @param {module:model/AlarmAcknowledgementUpload} alarmAcknowledgementUpload 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Generic} and HTTP response
     */
    acknowledgeAlarmWithHttpInfo(alarmAcknowledgementUpload) {
      let postBody = alarmAcknowledgementUpload;
      // verify the required parameter 'alarmAcknowledgementUpload' is set
      if (alarmAcknowledgementUpload === undefined || alarmAcknowledgementUpload === null) {
        throw new Error("Missing the required parameter 'alarmAcknowledgementUpload' when calling acknowledgeAlarm");
      }

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['cookieAuth', 'tokenAuth'];
      let contentTypes = ['application/json', 'application/x-www-form-urlencoded', 'multipart/form-data'];
      let accepts = ['application/json'];
      let returnType = Generic;
      return this.apiClient.callApi(
        '/api/devices/xt1/alarm-acknowledge/', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {module:model/AlarmAcknowledgementUpload} alarmAcknowledgementUpload 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Generic}
     */
    acknowledgeAlarm(alarmAcknowledgementUpload) {
      return this.acknowledgeAlarmWithHttpInfo(alarmAcknowledgementUpload)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {module:model/AlarmDismissalUpload} alarmDismissalUpload 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Generic} and HTTP response
     */
    dismissAlarmWithHttpInfo(alarmDismissalUpload) {
      let postBody = alarmDismissalUpload;
      // verify the required parameter 'alarmDismissalUpload' is set
      if (alarmDismissalUpload === undefined || alarmDismissalUpload === null) {
        throw new Error("Missing the required parameter 'alarmDismissalUpload' when calling dismissAlarm");
      }

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['cookieAuth', 'tokenAuth'];
      let contentTypes = ['application/json', 'application/x-www-form-urlencoded', 'multipart/form-data'];
      let accepts = ['application/json'];
      let returnType = Generic;
      return this.apiClient.callApi(
        '/api/devices/xt1/alarm-dismiss/', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {module:model/AlarmDismissalUpload} alarmDismissalUpload 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Generic}
     */
    dismissAlarm(alarmDismissalUpload) {
      return this.dismissAlarmWithHttpInfo(alarmDismissalUpload)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {module:model/RecordID} recordID 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/FileURL} and HTTP response
     */
    generateReportWithHttpInfo(recordID) {
      let postBody = recordID;
      // verify the required parameter 'recordID' is set
      if (recordID === undefined || recordID === null) {
        throw new Error("Missing the required parameter 'recordID' when calling generateReport");
      }

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['cookieAuth', 'tokenAuth'];
      let contentTypes = ['application/json', 'application/x-www-form-urlencoded', 'multipart/form-data'];
      let accepts = ['application/json'];
      let returnType = FileURL;
      return this.apiClient.callApi(
        '/api/devices/xt1/reports/', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {module:model/RecordID} recordID 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/FileURL}
     */
    generateReport(recordID) {
      return this.generateReportWithHttpInfo(recordID)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} alarmId specifies the alarm to get details for
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/AlarmDetails} and HTTP response
     */
    getAlarmWithHttpInfo(alarmId) {
      let postBody = null;
      // verify the required parameter 'alarmId' is set
      if (alarmId === undefined || alarmId === null) {
        throw new Error("Missing the required parameter 'alarmId' when calling getAlarm");
      }

      let pathParams = {
        'alarm_id': alarmId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['cookieAuth', 'tokenAuth'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = AlarmDetails;
      return this.apiClient.callApi(
        '/api/devices/xt1/alarms/{alarm_id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} alarmId specifies the alarm to get details for
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/AlarmDetails}
     */
    getAlarm(alarmId) {
      return this.getAlarmWithHttpInfo(alarmId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {Object} opts Optional parameters
     * @param {Boolean} opts.includeAcknowledged If provided, results will include Alarms that have been acknowledged by the user already
     * @param {Boolean} opts.includeDismissed If provided, results will include Alarms that have been dismissed by the user already
     * @param {Number} opts.recordId If provided, only returns results related to the specified record
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/Alarm>} and HTTP response
     */
    getAlarmsWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'include_acknowledged': opts['includeAcknowledged'],
        'include_dismissed': opts['includeDismissed'],
        'record_id': opts['recordId']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['cookieAuth', 'tokenAuth'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [Alarm];
      return this.apiClient.callApi(
        '/api/devices/xt1/alarms/', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {Object} opts Optional parameters
     * @param {Boolean} opts.includeAcknowledged If provided, results will include Alarms that have been acknowledged by the user already
     * @param {Boolean} opts.includeDismissed If provided, results will include Alarms that have been dismissed by the user already
     * @param {Number} opts.recordId If provided, only returns results related to the specified record
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/Alarm>}
     */
    getAlarms(opts) {
      return this.getAlarmsWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/XT1Scan>} and HTTP response
     */
    getAllScansWithHttpInfo() {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['cookieAuth', 'tokenAuth'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [XT1Scan];
      return this.apiClient.callApi(
        '/api/devices/xt1/scans/', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/XT1Scan>}
     */
    getAllScans() {
      return this.getAllScansWithHttpInfo()
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/XT1Sensor>} and HTTP response
     */
    getAllSensorsWithHttpInfo() {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['cookieAuth', 'tokenAuth'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [XT1Sensor];
      return this.apiClient.callApi(
        '/api/devices/xt1/', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/XT1Sensor>}
     */
    getAllSensors() {
      return this.getAllSensorsWithHttpInfo()
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {Number} recordId If provided, only returns results related to the specified record
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/ExcursionDetails>} and HTTP response
     */
    getExcursionsForRecordWithHttpInfo(recordId) {
      let postBody = null;
      // verify the required parameter 'recordId' is set
      if (recordId === undefined || recordId === null) {
        throw new Error("Missing the required parameter 'recordId' when calling getExcursionsForRecord");
      }

      let pathParams = {
      };
      let queryParams = {
        'record_id': recordId
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['cookieAuth', 'tokenAuth'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [ExcursionDetails];
      return this.apiClient.callApi(
        '/api/devices/xt1/excursions/', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {Number} recordId If provided, only returns results related to the specified record
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/ExcursionDetails>}
     */
    getExcursionsForRecord(recordId) {
      return this.getExcursionsForRecordWithHttpInfo(recordId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {Array.<String>} recordIds specifies the record to get scans for
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/XT1Scan>} and HTTP response
     */
    getRecordScansWithHttpInfo(recordIds) {
      let postBody = null;
      // verify the required parameter 'recordIds' is set
      if (recordIds === undefined || recordIds === null) {
        throw new Error("Missing the required parameter 'recordIds' when calling getRecordScans");
      }

      let pathParams = {
      };
      let queryParams = {
        'record_ids': this.apiClient.buildCollectionParam(recordIds, 'multi')
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['cookieAuth', 'tokenAuth'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [XT1Scan];
      return this.apiClient.callApi(
        '/api/devices/xt1/records/scans/', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {Array.<String>} recordIds specifies the record to get scans for
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/XT1Scan>}
     */
    getRecordScans(recordIds) {
      return this.getRecordScansWithHttpInfo(recordIds)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {Object} opts Optional parameters
     * @param {Boolean} opts.latest If provided, only returns the most recent record for any relevant tags
     * @param {Number} opts.tagId If provided, only returns results related to the specified tag
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/XT1Record>} and HTTP response
     */
    getRecordsWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
        'latest': opts['latest'],
        'tag_id': opts['tagId']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['cookieAuth', 'tokenAuth'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [XT1Record];
      return this.apiClient.callApi(
        '/api/devices/xt1/records/', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {Object} opts Optional parameters
     * @param {Boolean} opts.latest If provided, only returns the most recent record for any relevant tags
     * @param {Number} opts.tagId If provided, only returns results related to the specified tag
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/XT1Record>}
     */
    getRecords(opts) {
      return this.getRecordsWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} reportId UUID of the report that was generated
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link File} and HTTP response
     */
    getReportPDFWithHttpInfo(reportId) {
      let postBody = null;
      // verify the required parameter 'reportId' is set
      if (reportId === undefined || reportId === null) {
        throw new Error("Missing the required parameter 'reportId' when calling getReportPDF");
      }

      let pathParams = {
      };
      let queryParams = {
        'report_id': reportId
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['cookieAuth', 'tokenAuth'];
      let contentTypes = [];
      let accepts = ['application/pdf', 'application/json'];
      let returnType = File;
      return this.apiClient.callApi(
        '/api/devices/xt1/reports/', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} reportId UUID of the report that was generated
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link File}
     */
    getReportPDF(reportId) {
      return this.getReportPDFWithHttpInfo(reportId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {module:model/ConfigureActionUpload} configureActionUpload 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/DeviceActionUploadResult} and HTTP response
     */
    uploadConfigureActionWithHttpInfo(configureActionUpload) {
      let postBody = configureActionUpload;
      // verify the required parameter 'configureActionUpload' is set
      if (configureActionUpload === undefined || configureActionUpload === null) {
        throw new Error("Missing the required parameter 'configureActionUpload' when calling uploadConfigureAction");
      }

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['cookieAuth', 'tokenAuth'];
      let contentTypes = ['application/json', 'application/x-www-form-urlencoded', 'multipart/form-data'];
      let accepts = ['application/json'];
      let returnType = DeviceActionUploadResult;
      return this.apiClient.callApi(
        '/api/devices/xt1/configure-action/', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {module:model/ConfigureActionUpload} configureActionUpload 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/DeviceActionUploadResult}
     */
    uploadConfigureAction(configureActionUpload) {
      return this.uploadConfigureActionWithHttpInfo(configureActionUpload)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {module:model/XT1ScanUpload} xT1ScanUpload 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/DeviceActionUploadResult} and HTTP response
     */
    uploadScanWithHttpInfo(xT1ScanUpload) {
      let postBody = xT1ScanUpload;
      // verify the required parameter 'xT1ScanUpload' is set
      if (xT1ScanUpload === undefined || xT1ScanUpload === null) {
        throw new Error("Missing the required parameter 'xT1ScanUpload' when calling uploadScan");
      }

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['cookieAuth', 'tokenAuth'];
      let contentTypes = ['application/json', 'application/x-www-form-urlencoded', 'multipart/form-data'];
      let accepts = ['application/json'];
      let returnType = DeviceActionUploadResult;
      return this.apiClient.callApi(
        '/api/devices/xt1/scans/', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {module:model/XT1ScanUpload} xT1ScanUpload 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/DeviceActionUploadResult}
     */
    uploadScan(xT1ScanUpload) {
      return this.uploadScanWithHttpInfo(xT1ScanUpload)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {module:model/StartActionUpload} startActionUpload 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/DeviceActionUploadResult} and HTTP response
     */
    uploadStartActionWithHttpInfo(startActionUpload) {
      let postBody = startActionUpload;
      // verify the required parameter 'startActionUpload' is set
      if (startActionUpload === undefined || startActionUpload === null) {
        throw new Error("Missing the required parameter 'startActionUpload' when calling uploadStartAction");
      }

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['cookieAuth', 'tokenAuth'];
      let contentTypes = ['application/json', 'application/x-www-form-urlencoded', 'multipart/form-data'];
      let accepts = ['application/json'];
      let returnType = DeviceActionUploadResult;
      return this.apiClient.callApi(
        '/api/devices/xt1/start-action/', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {module:model/StartActionUpload} startActionUpload 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/DeviceActionUploadResult}
     */
    uploadStartAction(startActionUpload) {
      return this.uploadStartActionWithHttpInfo(startActionUpload)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {module:model/StopActionUpload} stopActionUpload 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/DeviceActionUploadResult} and HTTP response
     */
    uploadStopActionWithHttpInfo(stopActionUpload) {
      let postBody = stopActionUpload;
      // verify the required parameter 'stopActionUpload' is set
      if (stopActionUpload === undefined || stopActionUpload === null) {
        throw new Error("Missing the required parameter 'stopActionUpload' when calling uploadStopAction");
      }

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['cookieAuth', 'tokenAuth'];
      let contentTypes = ['application/json', 'application/x-www-form-urlencoded', 'multipart/form-data'];
      let accepts = ['application/json'];
      let returnType = DeviceActionUploadResult;
      return this.apiClient.callApi(
        '/api/devices/xt1/stop-action/', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {module:model/StopActionUpload} stopActionUpload 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/DeviceActionUploadResult}
     */
    uploadStopAction(stopActionUpload) {
      return this.uploadStopActionWithHttpInfo(stopActionUpload)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {module:model/UpdateReferencesActionUpload} updateReferencesActionUpload 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/DeviceActionUploadResult} and HTTP response
     */
    uploadUpdateReferencesActionWithHttpInfo(updateReferencesActionUpload) {
      let postBody = updateReferencesActionUpload;
      // verify the required parameter 'updateReferencesActionUpload' is set
      if (updateReferencesActionUpload === undefined || updateReferencesActionUpload === null) {
        throw new Error("Missing the required parameter 'updateReferencesActionUpload' when calling uploadUpdateReferencesAction");
      }

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['cookieAuth', 'tokenAuth'];
      let contentTypes = ['application/json', 'application/x-www-form-urlencoded', 'multipart/form-data'];
      let accepts = ['application/json'];
      let returnType = DeviceActionUploadResult;
      return this.apiClient.callApi(
        '/api/devices/xt1/update-references-action/', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {module:model/UpdateReferencesActionUpload} updateReferencesActionUpload 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/DeviceActionUploadResult}
     */
    uploadUpdateReferencesAction(updateReferencesActionUpload) {
      return this.uploadUpdateReferencesActionWithHttpInfo(updateReferencesActionUpload)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


}
