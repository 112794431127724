/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import AboveOrBelowEnum from './AboveOrBelowEnum';

/**
 * The ExcursionSummary model module.
 * @module model/ExcursionSummary
 * @version 0.0.0
 */
class ExcursionSummary {
    /**
     * Constructs a new <code>ExcursionSummary</code>.
     * @alias module:model/ExcursionSummary
     * @param aboveOrBelow {module:model/AboveOrBelowEnum} 
     * @param highThreshold {Number} In Degrees Celsius
     * @param lowThreshold {Number} In Degrees Celsius
     * @param startTime {Date} 
     * @param endTime {Date} 
     * @param degreeMinutes {Number} Total area under the curves of excursion, In minutes times degrees Celsius
     * @param totalDuration {Number} Sum of the durations of all excursions above or below the threshold, or in total, depending on what this summary is representing
     * @param highestRecordedTemperature {Number} In Degrees Celsius. Will be null if this is a summary for excursions below
     * @param lowestRecordedTemperature {Number} In Degrees Celsius. Will be absent if this is a summary for excursions above
     */
    constructor(aboveOrBelow, highThreshold, lowThreshold, startTime, endTime, degreeMinutes, totalDuration, highestRecordedTemperature, lowestRecordedTemperature) { 
        
        ExcursionSummary.initialize(this, aboveOrBelow, highThreshold, lowThreshold, startTime, endTime, degreeMinutes, totalDuration, highestRecordedTemperature, lowestRecordedTemperature);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, aboveOrBelow, highThreshold, lowThreshold, startTime, endTime, degreeMinutes, totalDuration, highestRecordedTemperature, lowestRecordedTemperature) { 
        obj['above_or_below'] = aboveOrBelow;
        obj['high_threshold'] = highThreshold;
        obj['low_threshold'] = lowThreshold;
        obj['start_time'] = startTime;
        obj['end_time'] = endTime;
        obj['degree_minutes'] = degreeMinutes;
        obj['total_duration'] = totalDuration;
        obj['highest_recorded_temperature'] = highestRecordedTemperature;
        obj['lowest_recorded_temperature'] = lowestRecordedTemperature;
    }

    /**
     * Constructs a <code>ExcursionSummary</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/ExcursionSummary} obj Optional instance to populate.
     * @return {module:model/ExcursionSummary} The populated <code>ExcursionSummary</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ExcursionSummary();

            if (data.hasOwnProperty('above_or_below')) {
                obj['above_or_below'] = ApiClient.convertToType(data['above_or_below'], AboveOrBelowEnum);
            }
            if (data.hasOwnProperty('high_threshold')) {
                obj['high_threshold'] = ApiClient.convertToType(data['high_threshold'], 'Number');
            }
            if (data.hasOwnProperty('low_threshold')) {
                obj['low_threshold'] = ApiClient.convertToType(data['low_threshold'], 'Number');
            }
            if (data.hasOwnProperty('start_time')) {
                obj['start_time'] = ApiClient.convertToType(data['start_time'], 'Date');
            }
            if (data.hasOwnProperty('end_time')) {
                obj['end_time'] = ApiClient.convertToType(data['end_time'], 'Date');
            }
            if (data.hasOwnProperty('degree_minutes')) {
                obj['degree_minutes'] = ApiClient.convertToType(data['degree_minutes'], 'Number');
            }
            if (data.hasOwnProperty('total_duration')) {
                obj['total_duration'] = ApiClient.convertToType(data['total_duration'], 'Number');
            }
            if (data.hasOwnProperty('highest_recorded_temperature')) {
                obj['highest_recorded_temperature'] = ApiClient.convertToType(data['highest_recorded_temperature'], 'Number');
            }
            if (data.hasOwnProperty('lowest_recorded_temperature')) {
                obj['lowest_recorded_temperature'] = ApiClient.convertToType(data['lowest_recorded_temperature'], 'Number');
            }
        }
        return obj;
    }


}

/**
 * @member {module:model/AboveOrBelowEnum} above_or_below
 */
ExcursionSummary.prototype['above_or_below'] = undefined;

/**
 * In Degrees Celsius
 * @member {Number} high_threshold
 */
ExcursionSummary.prototype['high_threshold'] = undefined;

/**
 * In Degrees Celsius
 * @member {Number} low_threshold
 */
ExcursionSummary.prototype['low_threshold'] = undefined;

/**
 * @member {Date} start_time
 */
ExcursionSummary.prototype['start_time'] = undefined;

/**
 * @member {Date} end_time
 */
ExcursionSummary.prototype['end_time'] = undefined;

/**
 * Total area under the curves of excursion, In minutes times degrees Celsius
 * @member {Number} degree_minutes
 */
ExcursionSummary.prototype['degree_minutes'] = undefined;

/**
 * Sum of the durations of all excursions above or below the threshold, or in total, depending on what this summary is representing
 * @member {Number} total_duration
 */
ExcursionSummary.prototype['total_duration'] = undefined;

/**
 * In Degrees Celsius. Will be null if this is a summary for excursions below
 * @member {Number} highest_recorded_temperature
 */
ExcursionSummary.prototype['highest_recorded_temperature'] = undefined;

/**
 * In Degrees Celsius. Will be absent if this is a summary for excursions above
 * @member {Number} lowest_recorded_temperature
 */
ExcursionSummary.prototype['lowest_recorded_temperature'] = undefined;






export default ExcursionSummary;

