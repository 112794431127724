
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { FormControl, InputLabel, MenuItem, Select, Avatar, TextField, Grid, Box, Typography, Button } from '@mui/material';
import {view, store, autoEffect} from '@risingstack/react-easy-state';
import UserRegisterForm from '../../api/client/src/model/UserRegisterForm';
import authStore from '../../store/authStore';

const AddUserModal = view(() => {
  const state = store({
    showErrors: false,
  });
  const form = store(new UserRegisterForm(
    "", "", "", "", "", 
    authStore.orgs.length ? authStore.orgs[0].id : "",
    "",
  ))

  autoEffect(() => {
    authStore.getPermissionRoleChoices().then(() => {
      if (authStore.permissionRoleChoices.length > 0) {
        form.role_id = authStore.permissionRoleChoices[authStore.permissionRoleChoices.length - 1].id;
      }
    });
  });

  const handleSubmit = (event) => {
    state.showErrors = true;
    event.preventDefault();
    
    console.log(form);
    authStore.registerUser(form).then(res => console.log(res));
  };

  return (
    <Box
      sx={{
        marginTop: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        Add User
      </Typography>
      <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              autoComplete="given-name"
              name="firstName"
              id="firstName"
              label="First Name"
              value={form.first_name}
              onChange={(e) => form.first_name = e.target.value}
              required
              fullWidth
              autoFocus
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              autoComplete="family-name"
              name="lastName"
              id="lastName"
              label="Last Name"
              value={form.last_name}
              onChange={(e) => form.last_name = e.target.value}
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="username"
              label="Username"
              name="username"
              autoComplete="username"
              required
              fullWidth
              value={form.username}
              onChange={(e) => form.username = e.target.value}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              value={form.email}
              onChange={(e) => form.email = e.target.value}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="new-password"
              value={form.password}
              onChange={(e) => form.password = e.target.value}
              required
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="org-dropdown">Organization</InputLabel>
              <Select
                labelId="org-dropdown"
                id="organization"
                name="organization"
                label="Organization"
                value={form.organization_id}
                onChange={e => form.organization_id = e.target.value}
              >
                {authStore.orgs.map(org => (<MenuItem key={org.id} value={org.id}>{org.name}</MenuItem>))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="role-dropdown">Role</InputLabel>
              <Select
                labelId="role-dropdown"
                id="role"
                name="role"
                label="Role"
                value={form.role_id}
                onChange={e => form.role_id = e.target.value}
              >
                {authStore.permissionRoleChoices.map(role => (<MenuItem key={role.id} value={role.id}>{role.name}</MenuItem>))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            {(authStore.permissionRoleChoices.find((role) => role.id === form.role_id)?.permission_names || []).join(", ")}
          </Grid>
          <Grid item xs={12}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Add User
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
});

export default AddUserModal;