/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The PasswordResetConfirm model module.
 * @module model/PasswordResetConfirm
 * @version 0.0.0
 */
class PasswordResetConfirm {
    /**
     * Constructs a new <code>PasswordResetConfirm</code>.
     * @alias module:model/PasswordResetConfirm
     * @param uuid {String} 
     * @param token {String} 
     * @param newPassword {String} 
     */
    constructor(uuid, token, newPassword) { 
        
        PasswordResetConfirm.initialize(this, uuid, token, newPassword);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, uuid, token, newPassword) { 
        obj['uuid'] = uuid;
        obj['token'] = token;
        obj['new_password'] = newPassword;
    }

    /**
     * Constructs a <code>PasswordResetConfirm</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/PasswordResetConfirm} obj Optional instance to populate.
     * @return {module:model/PasswordResetConfirm} The populated <code>PasswordResetConfirm</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new PasswordResetConfirm();

            if (data.hasOwnProperty('uuid')) {
                obj['uuid'] = ApiClient.convertToType(data['uuid'], 'String');
            }
            if (data.hasOwnProperty('token')) {
                obj['token'] = ApiClient.convertToType(data['token'], 'String');
            }
            if (data.hasOwnProperty('new_password')) {
                obj['new_password'] = ApiClient.convertToType(data['new_password'], 'String');
            }
        }
        return obj;
    }


}

/**
 * @member {String} uuid
 */
PasswordResetConfirm.prototype['uuid'] = undefined;

/**
 * @member {String} token
 */
PasswordResetConfirm.prototype['token'] = undefined;

/**
 * @member {String} new_password
 */
PasswordResetConfirm.prototype['new_password'] = undefined;






export default PasswordResetConfirm;

