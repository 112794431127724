import React from "react";
import { Container, Typography } from "@mui/material";
import { SummaryBox, RecordImage, Content, ContentBox, Status, BatteryLow, BatteryOK } from "./RecordComponents";
import LinearProgress from '@mui/material/LinearProgress';
// eslint-disable-next-line no-unused-vars
import { XT1Record } from "../api/client/src";

export const RecordSummary = /** @param {{record: XT1Record}} */({record}) => {
  const alarmSummary = [
    {
      key: 'Alarm Status',
      value: record ? <Status status={record.status}>{record.status}</Status>: <LinearProgress sx={{width: "30px"}}/>
    },
    // Placeholder for Dana
    // {
    //   key: 'Mean kinetic temperature',
    //   value: record ? record.mean_kinetic_temp : <LinearProgress sx={{width: "30px"}}/>
    // }
  ]
  const tagDetails = [
    {
      key: 'Tag Type',
      value: record ? record.tag_type : <LinearProgress sx={{width: "30px"}}/>
    },
    {
      key: 'Tag ID',
      value: record ? record.tag_id : <LinearProgress sx={{width: "30px"}}/>
    },
    {
      key: 'Tag Status',
      value: record ? record.tag_status : <LinearProgress sx={{width: "30px"}}/>
    },
    {
      key: 'Battery Status',
      value: record
        ? record.battery_health !== "LOW_BATTERY" 
          ? <BatteryOK /> 
          : record.battery_health === "LOW_BATTERY"
            ? <BatteryLow /> 
            : "No data" 
        : <LinearProgress sx={{width: "30px"}} />
    }
  ]
  const tagConfigurations = [
    {
      key: 'High Threshold',
      value: record ? `${record.high_threshold}°C` : <LinearProgress sx={{width: "30px"}}/>
    },
    {
      key: 'Low Threshold',
      value: record ? `${record.low_threshold}°C` : <LinearProgress sx={{width: "30px"}}/>
    },
    {
      key: 'Measurement Interval',
      value: record ? `${record.measurement_interval} mins` : <LinearProgress sx={{width: "30px"}}/>
    },
    {
      key: 'Start Delay',
      value: record ? `${record.start_delay} mins` : <LinearProgress sx={{width: "30px"}}/>
    },
    {
      key: (<>Minimum Excursion Duration <br/>Required to Trigger Alarm</>),
      value: record ? record.num_consecutive_excursions ? (record.num_consecutive_excursions * record.measurement_interval) + " mins" : "No data" :  <LinearProgress sx={{width: "30px"}}/>
    }
  ]
  const recordReferenceIds = [
    {
      key: "Primary Reference",
      value: record ? (
        record.primary_reference ? (
          record.primary_reference
        ) : (
          "No data"
        )
      ) : (
        <LinearProgress sx={{ width: "30px" }} />
      ),
    },
    {
      key: 'Secondary Reference',
      value: record ? (
        record.secondary_reference ? (
          record.secondary_reference
        ) : (
          "No data"
        )
      ) : (
        <LinearProgress sx={{ width: "30px" }} />
      ),
    },
    {
      key: 'Shipping Reference',
      value: record ? (
        record.shipping_reference ? (
          record.shipping_reference
        ) : (
          "No data"
        )
      ) : (
        <LinearProgress sx={{ width: "30px" }} />
      ),
    },
  ]
  return (
    <SummaryBox>
      <Container sx={{textAlign: 'center'}}>
        <RecordImage 
          component='img'  
          src="../xt1-tag-ok.png"
          alt="XT1 tag"
        />
      </Container>
      <Container>
        <Typography variant="detail">RECORD ID</Typography>
        <Typography variant="h4" mb={"20px"}>{record ? record.id: <LinearProgress sx={{width: "30px"}}/>}</Typography>
        <ContentBox>
          {alarmSummary.map((item, index)=> <Content key={index}>
            <Typography variant="h6">{item.key}</Typography>
            <Typography variant="body2">{item.value}</Typography>
          </Content>)}
        </ContentBox>
        <Typography variant="detail">TAG DETAILS</Typography>
        <ContentBox>
          {tagDetails.map((item, index)=> <Content key={index}>
            <Typography variant="h6">{item.key}</Typography>
            <Typography variant="body2">{item.value}</Typography>
          </Content>)}
        </ContentBox>
        <Typography variant="detail">TAG CONFIGURATIONS</Typography>
        <ContentBox>
          {tagConfigurations.map((item, index)=> <Content key={index}>
            <Typography variant="h6">{item.key}</Typography>
            <Typography variant="body2">{item.value}</Typography>
          </Content>)}
        </ContentBox>
        <Typography variant="detail">REFERENCE IDS</Typography>
        <ContentBox>
          {recordReferenceIds.map((item, index)=> <Content key={index}>
            <Typography variant="h6">{item.key}</Typography>
            <Typography variant="body2">{item.value}</Typography>
          </Content>)}
        </ContentBox>
      </Container>
    </SummaryBox>
  )
}