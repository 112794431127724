import { view } from "@risingstack/react-easy-state";
import { Paper } from "@mui/material";
import { Logo } from "../../components/Logo";
import { 
  Box, 
  Button, 
  Container, 
  Typography 
} from "@mui/material";
import browserHistory from "../../router";
import SupportEmail from "../../components/SupportEmail";

const ChangePasswordConfirm = view(() => {
  return (
    <Paper variant="background">
      <Container maxWidth="xs" variant="card">
        <Logo />
        <Typography variant="h4">Password Reset</Typography>
        <Box
          textAlign="center"
          sx={{
            maxWidth: "277px",
            margin: "auto",
            mb: "25px",
            "@media (min-width:425px)": {
              maxWidth: "344px",
            },
          }}
        >
          <Typography variant="body2" sx={{ my: 2 }}>
            Your password has been changed.
          </Typography>
        </Box>
        <Button
          variant="contained"
          fullWidth
          sx={{ mb: 3.125 }}
          onClick={() => browserHistory.push("/login")}
        >
          Log In
        </Button>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            "@media (min-width:576px)": {
              flexDirection: "row",
            },
          }}
        >
          <SupportEmail />
        </Box>
      </Container>
    </Paper>
  );
});

export default ChangePasswordConfirm;
