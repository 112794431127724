import React from "react";
import { autoEffect, store, view } from "@risingstack/react-easy-state";
import { Box, Link, Typography } from "@mui/material";
import RecordsGridPremium from "./RecordsGridPremium";
import BatteryChargingFullIcon from '@mui/icons-material/BatteryChargingFull';
import Battery20Icon from '@mui/icons-material/Battery20';
import clsx from "clsx";
import CustomClient from "../api/CustomClient";
import deviceStore from "../store/deviceStore";
import { XT1Record } from "../api/client/src";
import { styleDate } from "../utils/utilFunctions";

const columns = [
  {
    field: "primary_reference",
    headerName: "Primary Reference",
    valueGetter: ({ row }) => {
      if (row.primary_reference) {
        return row.primary_reference;
      } else {
        return "No data";
      }
    },
    flex: 1,
  },
  {
    field: "id",
    headerName: "Record ID",
    renderCell: (params) => (
      <Link href={"/recorddetail/" + params.row.id}>
        {params.row.id}
      </Link>
    ),
    flex: 1,
  },
  {
    field: "status",
    headerName: "Alarm Status",
    valueGetter: ({ row }) => {
      if (row.status) {
        return row.status;
      } else {
        return "No data";
      }
    },
    cellClassName: ({ value }) => {
      return clsx("cell-status", {
        ok: value === "OK",
        alarm: value === "Alarm",
      });
    },
    flex: 1,
  },
  {
    field: "start_time",
    headerName: "Start Time",
    valueGetter: ({ row }) => {
      if (row.start_time) {
        return row.start_time.getTime();
      } else {
        return 0;
      }
    },
    valueFormatter: ({value}) => {
      return styleDate(value, "No data");
    },
    cellClassName: ({ value }) => {
      return clsx("cell-starttime");
    },
    flex: 1,
  },
  {
    field: "stop_time",
    headerName: "Stop Time",
    valueGetter: ({ row }) => {
      if (row.stop_time) {
        return row.stop_time.getTime();
      } else {
        return 0;
      }
    },
    valueFormatter: ({value}) => {
      return styleDate(value, "No data");
    },
    flex: 1,
  },
  {
    field: "secondary_reference",
    headerName: "Secondary Reference",
    valueGetter: ({ row }) => {
      if (row.secondary_reference != null) {
        return row.secondary_reference;
      } else {
        return "No data";
      }
    },
    flex: 1,
  },
  {
    field: "shipping_reference",
    headerName: "Shipping Reference",
    valueGetter: ({ row }) => {
      if (row.shipping_reference != null) {
        return row.shipping_reference;
      } else {
        return "No data";
      }
    },
  },
  {
    field: "highest_recorded_temp",
    headerName: "Highest Recorded Temperature (°C)",
    valueGetter: ({ row }) => {
      if (row.highest_recorded_temperature !== null) {
        return row.highest_recorded_temperature;
      } else {
        return "No data";
      }
    },
    flex: 1,
  },
  {
    field: "lowest_recorded_temp",
    headerName: "Lowest Recorded Temperature (°C)",
    valueGetter: ({ row }) => {
      if (row.lowest_recorded_temperature !== null) {
        return row.lowest_recorded_temperature;
      } else {
        return "No data";
      }
    },
    flex: 1,
  },
  {
    field: "mean_kinetic_temp",
    headerName: "Mean Kinetic Temperature (°C)",
    valueGetter: ({ row }) => {
      if (row.mean_kinetic_temp) {
        return Math.round(row.mean_kinetic_temp * 100) / 100;
      } else {
        return "No data";
      }
    },
    flex: 1,
  },
  // {
  //   field: "excursion_duration_below",
  //   headerName: "Excursion Duration Below",
  //   valueGetter: ({ row }) => {
  //     if (row.excursion_duration_below) {
  //       return row.excursion_duration_below;
  //     } else {
  //       return "No data";
  //     }
  //   },
  //  flex: 1,
  // },
  // {
  //   field: "excursion_duration_above",
  //   headerName: "Excursion Duration Above",
  //   valueGetter: ({ row }) => {
  //     if (row.excursion_duration_above) {
  //       return row.excursion_duration_above;
  //     } else {
  //       return "No data";
  //     }
  //   },
  //   flex: 1,
  // },
  {
    field: "tag_type",
    headerName: "Tag Type",
    valueGetter: ({ row }) => {
      if (row.tag_type) {
        return row.tag_type;
      } else {
        return "No data";
      }
    },
    flex: 1,
  },
  {
    field: "battery_health",
    headerName: "Battery Status",
    renderCell: ({ row }) => {
      if (row.battery_health !== "LOW_BATTERY") {
        return (
          <>
            <BatteryChargingFullIcon
              sx={{
                transform: "rotate(-90deg)",
                marginRight: "4px",
                color: "#37B24D",
              }}
            />
            <Box component={"span"}>OK</Box>
          </>
        );
      } else {
        return (
          <>
            <Battery20Icon
              sx={{
                transform: "rotate(-90deg)",
                marginRight: "4px",
                color: "#F03E3E",
              }}
            />
            <Box component={"span"}>Low</Box>
          </>
        );
      }
    },
    cellClassName: ({ value }) => {
      return clsx("cell-battery");
    },
    flex: 1,
  },
  {
    field: "last_scanned",
    headerName: "Last Scanned",
    valueGetter: ({ row }) => {
      if (row.last_scanned) {
        return row.last_scanned.getTime();
      } else {
        return 0;
      }
    },
    valueFormatter: ({value}) => {
      return styleDate(value, "No data");
    },
    cellClassName: ({ value }) => {
      return clsx("cell-lastmodify");
    },
    flex: 1,
  },
];

const AllRecordsDataGrid = view(() => {
  const state = store({
    recordData: [],
  });

  autoEffect(() => {
    setTimeout(() => {
      const data = CustomClient.convertToType(deviceStore.records, [XT1Record]);
      data.sort((a, b) => {
        if (!b.last_scanned) return -1;
        if (!a.last_scanned) return 1;
        return b.last_scanned.getTime() - a.last_scanned.getTime()
      });
      state.recordData = data;
    });
  }, [deviceStore.records]);

  return (
    <Box
      sx={{
        backgroundColor: 'white',
        m: '20px',
        borderRadius: '8px',
        padding: '35px 20px',
        marginBottom: '120px',
        '@media (min-width:767px)': {
          padding: '35px 40px',
          marginBottom: 'initial',
        },
      }}
    >
      <Typography variant="h5">Recent Records</Typography>
      <RecordsGridPremium
        type="all"
        rows={state.recordData}
        loading={deviceStore.recordsLoading}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
      />
    </Box>
  );
});

export default AllRecordsDataGrid;
