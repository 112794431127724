/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import Generic from '../model/Generic';
import LoginForm from '../model/LoginForm';
import OrganizationId from '../model/OrganizationId';
import OrganizationRegisterForm from '../model/OrganizationRegisterForm';
import PasswordChangeForm from '../model/PasswordChangeForm';
import PasswordResetConfirm from '../model/PasswordResetConfirm';
import PasswordResetEmail from '../model/PasswordResetEmail';
import PatchedSessionOrgContext from '../model/PatchedSessionOrgContext';
import PatchedUserRoleMembershipUpload from '../model/PatchedUserRoleMembershipUpload';
import PermissionRole from '../model/PermissionRole';
import PrivateUser from '../model/PrivateUser';
import PublicUser from '../model/PublicUser';
import UserRegisterForm from '../model/UserRegisterForm';

/**
* Auth service.
* @module api/AuthApi
* @version 0.0.0
*/
export default class AuthApi {

    /**
    * Constructs a new AuthApi. 
    * @alias module:api/AuthApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * @param {module:model/PasswordChangeForm} passwordChangeForm 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/PrivateUser} and HTTP response
     */
    changePasswordWithHttpInfo(passwordChangeForm) {
      let postBody = passwordChangeForm;
      // verify the required parameter 'passwordChangeForm' is set
      if (passwordChangeForm === undefined || passwordChangeForm === null) {
        throw new Error("Missing the required parameter 'passwordChangeForm' when calling changePassword");
      }

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['cookieAuth', 'tokenAuth'];
      let contentTypes = ['application/json', 'application/x-www-form-urlencoded', 'multipart/form-data'];
      let accepts = ['application/json'];
      let returnType = PrivateUser;
      return this.apiClient.callApi(
        '/api/auth/password-change/', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {module:model/PasswordChangeForm} passwordChangeForm 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/PrivateUser}
     */
    changePassword(passwordChangeForm) {
      return this.changePasswordWithHttpInfo(passwordChangeForm)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {module:model/OrganizationRegisterForm} organizationRegisterForm 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/OrganizationId} and HTTP response
     */
    createOrgWithHttpInfo(organizationRegisterForm) {
      let postBody = organizationRegisterForm;
      // verify the required parameter 'organizationRegisterForm' is set
      if (organizationRegisterForm === undefined || organizationRegisterForm === null) {
        throw new Error("Missing the required parameter 'organizationRegisterForm' when calling createOrg");
      }

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['cookieAuth', 'tokenAuth'];
      let contentTypes = ['application/json', 'application/x-www-form-urlencoded', 'multipart/form-data'];
      let accepts = ['application/json'];
      let returnType = OrganizationId;
      return this.apiClient.callApi(
        '/api/auth/orgs/', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {module:model/OrganizationRegisterForm} organizationRegisterForm 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/OrganizationId}
     */
    createOrg(organizationRegisterForm) {
      return this.createOrgWithHttpInfo(organizationRegisterForm)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Generic} and HTTP response
     */
    getCsrfWithHttpInfo() {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = Generic;
      return this.apiClient.callApi(
        '/api/auth/csrf/', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Generic}
     */
    getCsrf() {
      return this.getCsrfWithHttpInfo()
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/OrganizationId>} and HTTP response
     */
    getOrgsWithHttpInfo() {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['cookieAuth', 'tokenAuth'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [OrganizationId];
      return this.apiClient.callApi(
        '/api/auth/orgs/', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/OrganizationId>}
     */
    getOrgs() {
      return this.getOrgsWithHttpInfo()
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/PrivateUser} and HTTP response
     */
    getSessionWithHttpInfo() {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['cookieAuth', 'tokenAuth'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = PrivateUser;
      return this.apiClient.callApi(
        '/api/auth/session/', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/PrivateUser}
     */
    getSession() {
      return this.getSessionWithHttpInfo()
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/PermissionRole>} and HTTP response
     */
    listRolePermissionsWithHttpInfo() {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['cookieAuth', 'tokenAuth'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [PermissionRole];
      return this.apiClient.callApi(
        '/api/auth/role-permissions/', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/PermissionRole>}
     */
    listRolePermissions() {
      return this.listRolePermissionsWithHttpInfo()
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link Array.<module:model/PublicUser>} and HTTP response
     */
    listUsersWithHttpInfo() {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['cookieAuth', 'tokenAuth'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = [PublicUser];
      return this.apiClient.callApi(
        '/api/auth/users/', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link Array.<module:model/PublicUser>}
     */
    listUsers() {
      return this.listUsersWithHttpInfo()
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {module:model/LoginForm} loginForm 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/PrivateUser} and HTTP response
     */
    loginWithHttpInfo(loginForm) {
      let postBody = loginForm;
      // verify the required parameter 'loginForm' is set
      if (loginForm === undefined || loginForm === null) {
        throw new Error("Missing the required parameter 'loginForm' when calling login");
      }

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json', 'application/x-www-form-urlencoded', 'multipart/form-data'];
      let accepts = ['application/json'];
      let returnType = PrivateUser;
      return this.apiClient.callApi(
        '/api/auth/login/', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {module:model/LoginForm} loginForm 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/PrivateUser}
     */
    login(loginForm) {
      return this.loginWithHttpInfo(loginForm)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Generic} and HTTP response
     */
    logoutWithHttpInfo() {
      let postBody = null;

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['cookieAuth', 'tokenAuth'];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = Generic;
      return this.apiClient.callApi(
        '/api/auth/logout/', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Generic}
     */
    logout() {
      return this.logoutWithHttpInfo()
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {module:model/PasswordResetConfirm} passwordResetConfirm 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Generic} and HTTP response
     */
    passwordResetConfirmWithHttpInfo(passwordResetConfirm) {
      let postBody = passwordResetConfirm;
      // verify the required parameter 'passwordResetConfirm' is set
      if (passwordResetConfirm === undefined || passwordResetConfirm === null) {
        throw new Error("Missing the required parameter 'passwordResetConfirm' when calling passwordResetConfirm");
      }

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json', 'application/x-www-form-urlencoded', 'multipart/form-data'];
      let accepts = ['application/json'];
      let returnType = Generic;
      return this.apiClient.callApi(
        '/api/auth/password-reset-confirm/', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {module:model/PasswordResetConfirm} passwordResetConfirm 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Generic}
     */
    passwordResetConfirm(passwordResetConfirm) {
      return this.passwordResetConfirmWithHttpInfo(passwordResetConfirm)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {module:model/UserRegisterForm} userRegisterForm 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/PublicUser} and HTTP response
     */
    registerWithHttpInfo(userRegisterForm) {
      let postBody = userRegisterForm;
      // verify the required parameter 'userRegisterForm' is set
      if (userRegisterForm === undefined || userRegisterForm === null) {
        throw new Error("Missing the required parameter 'userRegisterForm' when calling register");
      }

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['cookieAuth', 'tokenAuth'];
      let contentTypes = ['application/json', 'application/x-www-form-urlencoded', 'multipart/form-data'];
      let accepts = ['application/json'];
      let returnType = PublicUser;
      return this.apiClient.callApi(
        '/api/auth/users/', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {module:model/UserRegisterForm} userRegisterForm 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/PublicUser}
     */
    register(userRegisterForm) {
      return this.registerWithHttpInfo(userRegisterForm)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * Overwrites all of a user's existing role(s) for the given organization with the newly provided role
     * @param {Object} opts Optional parameters
     * @param {module:model/PatchedUserRoleMembershipUpload} opts.patchedUserRoleMembershipUpload 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/PrivateUser} and HTTP response
     */
    replaceAssignedUserRoleWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = opts['patchedUserRoleMembershipUpload'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['cookieAuth', 'tokenAuth'];
      let contentTypes = ['application/json', 'application/x-www-form-urlencoded', 'multipart/form-data'];
      let accepts = ['application/json'];
      let returnType = PrivateUser;
      return this.apiClient.callApi(
        '/api/auth/user-roles/', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * Overwrites all of a user's existing role(s) for the given organization with the newly provided role
     * @param {Object} opts Optional parameters
     * @param {module:model/PatchedUserRoleMembershipUpload} opts.patchedUserRoleMembershipUpload 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/PrivateUser}
     */
    replaceAssignedUserRole(opts) {
      return this.replaceAssignedUserRoleWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {module:model/PasswordResetEmail} passwordResetEmail 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Generic} and HTTP response
     */
    requestPasswordResetWithHttpInfo(passwordResetEmail) {
      let postBody = passwordResetEmail;
      // verify the required parameter 'passwordResetEmail' is set
      if (passwordResetEmail === undefined || passwordResetEmail === null) {
        throw new Error("Missing the required parameter 'passwordResetEmail' when calling requestPasswordReset");
      }

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = [];
      let contentTypes = ['application/json', 'application/x-www-form-urlencoded', 'multipart/form-data'];
      let accepts = ['application/json'];
      let returnType = Generic;
      return this.apiClient.callApi(
        '/api/auth/password-reset/', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {module:model/PasswordResetEmail} passwordResetEmail 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Generic}
     */
    requestPasswordReset(passwordResetEmail) {
      return this.requestPasswordResetWithHttpInfo(passwordResetEmail)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {Object} opts Optional parameters
     * @param {module:model/PatchedSessionOrgContext} opts.patchedSessionOrgContext 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/PrivateUser} and HTTP response
     */
    switchOrgContextWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = opts['patchedSessionOrgContext'];

      let pathParams = {
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['cookieAuth', 'tokenAuth'];
      let contentTypes = ['application/json', 'application/x-www-form-urlencoded', 'multipart/form-data'];
      let accepts = ['application/json'];
      let returnType = PrivateUser;
      return this.apiClient.callApi(
        '/api/auth/session/', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {Object} opts Optional parameters
     * @param {module:model/PatchedSessionOrgContext} opts.patchedSessionOrgContext 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/PrivateUser}
     */
    switchOrgContext(opts) {
      return this.switchOrgContextWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


}
