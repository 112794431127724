import { autoEffect, store, view } from "@risingstack/react-easy-state";
import { useSearchParams } from "react-router-dom";
import { Box, Container, Typography } from "@mui/material";
import PageTitle from "../components/PageTitle";
import RecordSearchItem from "../components/RecordSearchItem";
import { RecordPagination } from "../components/RecordPagination";
import deviceStore from "../store/deviceStore";

const SearchResults = view(() => {
  let [searchParams] = useSearchParams();

  const state = store({
    page: 0,
    rowsPerPage: 5,
    searchTerm: searchParams.get("searchTerms"),
    get firstResultIndex() {
      return state.page * state.rowsPerPage;
    },
    get lastResultIndex() {
      return (state.page + 1) * state.rowsPerPage;
    },
    get filteredResults() {
      return deviceStore.records.filter((element) => {
        return Object.values(element)
          .join("")
          .toLowerCase()
          .includes(state.searchTerm.toLowerCase());
      });
    },
    get numFilteredResults() {
      return state.filteredResults.length;
    },
    get paginatedResults() {
      return state.filteredResults.filter((_result, index) => state.firstResultIndex <= index && index < state.lastResultIndex)
    },
  });

  autoEffect(() => {
    state.searchTerm = searchParams.get("searchTerms");
  }, [searchParams]);
  

  const handleChangePage = (_event, newPage) => {
    state.page = newPage;
  };

  const handleChangeRowsPerPage = (event) => {
    state.rowsPerPage = parseInt(event.target.value, 10);
    state.page = 0;
  };
  if (state.numFilteredResults > 0) {
    return (
      <Container variant="grid">
        <Box>
          <PageTitle title="Search Results" />
          <Box 
            variant="grid" 
            p={"20px"}
            sx={{
              padding: '20px',
              marginBottom: '100px',
              '@media (min-width:768px)': {
                marginBottom: 'initial'
              }
            }}
          >
            <Box component={"ul"} variant="grid" sx={{background:'white', padding:'30px 20px 20px', borderRadius:'16px', m:'0'}}>
              {state.paginatedResults.map((result) => (
                <RecordSearchItem key={result.id} result={result} />
              ))}
            </Box>
            <RecordPagination
              component="div"
              count={state.numFilteredResults}
              page={state.page}
              onPageChange={handleChangePage}
              rowsPerPage={state.rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Box>
        </Box>
      </Container>
    );
  } else {
    return (
      <Container variant="grid">
        <Box>
          <PageTitle title="Search Results" />
          <Box sx={{m:"20px",p:"20px", backgroundColor:"white",height: 'calc(100vh - 220px)'}}>
            <Typography component={"h5"} fontWeight="bold" color={"#1B1D20"}>
              No results found for "{state.searchTerm}"
            </Typography>
          </Box>
        </Box>
      </Container>
    );
  }
});

export default SearchResults;
