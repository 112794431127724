import { view } from "@risingstack/react-easy-state";
import { Typography } from "@mui/material";
import { Content } from "../RecordComponents";
// eslint-disable-next-line no-unused-vars
import { XT1Record } from "../../api/client/src";
import { styleDate } from "../../utils/utilFunctions";

const TripDetails = view(
  /** @param {{record: XT1Record}} */ ({ record }) => {
    if (record) {
      return (
        <>
          <Content>
            <Typography variant="h6" color="grey.500">First point</Typography>
            <Typography variant="body2">
              {styleDate(record.first_point, "No data")}
            </Typography>
          </Content>
          <Content>
            <Typography variant="h6" color="grey.500">Last point</Typography>
            <Typography variant="body2">
              {styleDate(record.last_point, "No data")}
            </Typography>
          </Content>
          <Content>
            <Typography variant="h6" color="grey.500">Number of points</Typography>
            <Typography variant="body2">{record.number_of_points ? record.number_of_points : "No data"}</Typography>
          </Content>
          <Content>
            <Typography variant="h6" color="grey.500">Trip length</Typography>
            <Typography variant="body2">
              {record.trip_length}
            </Typography>
          </Content>
        </>
      );
    } else {
      return <Typography>This record has not loaded yet.</Typography>;
    }
  }
);

export default TripDetails;
