import { store } from "@risingstack/react-easy-state";
// eslint-disable-next-line no-unused-vars
import { LoginForm, OrganizationId, PrivateUser, PermissionRole } from "../api/client/src";
import PatchedSessionOrgContext from "../api/client/src/model/PatchedSessionOrgContext";
import { authApi } from "../api/clientInstances";
import { haltPromiseChain } from "../api/StopPromise";
import browserHistory from "../router";
import alarmStore from "./alarmStore";
import deviceStore from "./deviceStore";

/**
 * @type {{
 * csrfToken: string,
 * orgs: OrganizationId[],
 * permissionRoleChoices: PermissionRole[],
 * loggedInUserInfo: PrivateUser | null,
 * clear: () => void,
 * }}
 */
const authStore = store({
  csrfToken: "",
  orgs: [],
  permissionRoleChoices: [],
  loggedInUserInfo: null,
  get sessionOrgContext() {return authStore.loggedInUserInfo.last_session_org_id},
  clear() {
    authStore.loggedInUserInfo = null;
    authStore.orgs = [];
  },
  login(emailOrUsername, password) {
    return authApi.login(new LoginForm(emailOrUsername, password))
      .then((user_details) => {
        authStore.loggedInUserInfo = user_details;
        authStore.getOrgs();
        deviceStore.getRecords();
        alarmStore.getAlarms();
        return user_details;
      });
  },
  logout() {
    authApi.logout()
      .then(() => {
        browserHistory.push('/login');
      })
      .catch((err) => {
        console.error(err);
      });
  },
  getSession() {
    return authApi.getSessionWithHttpInfo()
      .catch(() => {
        authStore.loggedInUserInfo = null;
        return haltPromiseChain();
      })
      .then((result) => {
        const data = result.data;
        authStore.loggedInUserInfo = data;
      });
  },
  getMyUserInfo() {
    return authApi.getMyUserInfo()
      .then((data) => {
        console.log("You are logged in as: " + data.username);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  getOrgs() {
    return authApi.getOrgs()
      .then((data) => {
        authStore.orgs.splice(0, authStore.orgs.length, ...data);
      })
  },
  getPermissionRoleChoices() {
    return authApi.listRolePermissions()
      .then((data) => {
        authStore.permissionRoleChoices.splice(0, authStore.permissionRoleChoices.length, ...data);
      })
  },
  registerUser(userRegisterForm) {
    return authApi.registerWithHttpInfo(userRegisterForm)
      .catch((err) => {
        console.error(err);
        return haltPromiseChain();
      })
      .then((result) => {
        if (result.data) {
          console.log(result.data);
        }
      });
  },
  createOrg(orgUploadForm) {
    return authApi.createOrgWithHttpInfo(orgUploadForm)
      .catch(err => {
        console.log(err);
        haltPromiseChain();
      })
      .then(result => {
        if (result.data) {
          authStore.orgs.push(result.data);
          return result.data;
        }
      })
  },
  changePassword(passwordChangeForm) {
    return authApi.changePasswordWithHttpInfo(passwordChangeForm)
      .catch(err => {
        console.log(err);
        haltPromiseChain();
      })
      .then(result => {
        if (result.data) {
          authStore.loggedInUserInfo = result.data;
        }
        return result.data;
      })
  },
  switchOrgContext(newOrgId) {
    return authApi.switchOrgContextWithHttpInfo({patchedSessionOrgContext: PatchedSessionOrgContext.constructFromObject({id: newOrgId})})
      .catch(err => {
        console.log(err);
        haltPromiseChain();
      })
      .then(result => {
        if (result.data) {
          authStore.loggedInUserInfo = result.data;
        }
      })
  },
});

export default authStore