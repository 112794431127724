import {Box, Typography, Grid } from "@mui/material"

const PageNotFound = () => {
  return (
    <Box
      sx= {{
        height: 'calc(100vh - 90px)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        background: 'white',
        padding: '0 15px'
      }}
    >
      <Grid>
        <Typography variant="h2" mb={"30px"}>Oops! That page can’t be found.</Typography>
        <Typography variant="body1">It looks like nothing was found at this location. </Typography>
      </Grid>
    </Box>
  )
}

export default PageNotFound
