import * as React from 'react';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import {store, view} from '@risingstack/react-easy-state';
import AddUserModal from '../modals/admin/AddUserModal';
import { Dialog, DialogContent } from '@mui/material';
import CreateOrgModal from '../modals/admin/CreateOrgModal';
import ChangePasswordModal from '../modals/admin/ChangePasswordModal';

const ServerAdmin = view(() => {
  const state = store({
    addUserModalOpen: false,
    createOrgModalOpen: false,
    changePasswordModalOpen: false,
  });

  return (
    <Container sx={{display: "flex", justifyContent: "center"}}>
      <Button variant="contained" onClick={() => state.addUserModalOpen = true}>
        Add User
      </Button>
      <Dialog open={state.addUserModalOpen} onClose={() => state.addUserModalOpen = false}>
        <DialogContent>
          <AddUserModal/>
        </DialogContent>
      </Dialog>
      <Button variant="contained" onClick={() => state.createOrgModalOpen = true}>
        Create Organization
      </Button>
      <Dialog open={state.createOrgModalOpen} onClose={() => state.createOrgModalOpen = false}>
        <DialogContent>
          <CreateOrgModal/>
        </DialogContent>
      </Dialog>
      <Button variant="contained" onClick={() => state.changePasswordModalOpen = true}>
        Change Password
      </Button>
      <Dialog open={state.changePasswordModalOpen} onClose={() => state.changePasswordModalOpen = false}>
        <DialogContent>
          <ChangePasswordModal/>
        </DialogContent>
      </Dialog>
    </Container>
  );
});

export default ServerAdmin;