import React from "react";
import { Grid, Box, } from "@mui/material";
import { styled } from "@mui/material/styles";
import Battery20Icon from '@mui/icons-material/Battery20';
import BatteryChargingFull from "@mui/icons-material/BatteryChargingFull";

export const RecordBox = styled(Grid)(({ theme }) => ({
  flexWrap: 'wrap',
  '@media (min-width:769px)': {
    flexWrap: 'nowrap',
  },
}));
export const LeftBox = styled(Grid)(({ theme }) => ({
  minWidth: '380px',
  width: '100%',
  '@media (min-width:769px)': {
    paddingRight: '10px',
    width: 'initial',
  },
}));
export const RightBox = styled(Grid)(({theme})=> ({
  padding: '20px',
  height:'100%',
  width: '100%',
  marginBottom: '110px',
  '@media (min-width:769px)': {
    width: 'initial',
    marginBottom: 'initial',
  },
}))

export const SummaryBox = styled(Box)(({theme})=> ({
  height: '100%',
  backgroundColor: 'white',
  padding: '30px 0',
}))

export const RecordImage = styled(Box)(({theme})=> ({
  width: "121px",
  height: "120px",
  marginBottom: "37px"
}))

export const Content= styled("div")(({theme})=> ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}))

export const ContentBox= styled("div")(({theme})=> ({
  marginBottom: '40px'
}))

export const RecordTitle = styled("h4")(({theme}) => ({
  whiteSpace: 'pre-wrap',
  fontSize: '14px',
  '& .MuiButton-root' : {
    border: 'none',
    color: '#797B80',
    fontWeight: '400',
    '&:hover': {
      color: theme.palette.primary.main
    }
  }
}))

export const Status = styled("span")`
  background: ${props => props.status === "Alarm" ? 'rgba(240, 62, 62, 0.5)': 'rgba(55, 178, 77, 0.5)'};
  border-radius: 35px;
  color: #262626;
  padding: 0 10px;
  font-weight: bold;
  line-height: 1.5;
  display: inline-block
`;

export const BatteryOK = () => {
  return (
    <>
      <BatteryChargingFull sx={{transform: 'rotate(-90deg)',marginRight:'4px', color: '#37B24D'}} />
      <Box component={"span"} sx={{fontWeight: 'bold', color: '#262626'}}>OK</Box>
    </>
  )
}

export const BatteryLow = () => {
  return (
    <>
      <Battery20Icon sx={{transform: 'rotate(-90deg)',marginRight:'4px', color: '#F03E3E'}} />
      <Box component={"span"} sx={{fontWeight: 'bold', color: '#262626'}}>Low</Box>
    </>
  )
}
