import { store } from "@risingstack/react-easy-state";
// eslint-disable-next-line no-unused-vars
import { XT1Record, XT1Scan } from "../api/client/src";
import { devicesApi } from "../api/clientInstances";

/**
 * @type {{
 * records: XT1Record[],
 * recordsLoading: boolean,
 * scans: XT1Scan[],
 * scansLoading: boolean,
 * clear: () => void,
 * getRecords: () => Promise<XT1Record>,
 * getScans: (record_ids: str[]) => Promise<XT1Scan>,
 * generateAndDownloadPDF: (record_id: str) => void,
 * }}
 */
const deviceStore = store({
  records: [],
  recordsLoading: true,
  scans: [],
  scansLoading: false,
  clear() {
    deviceStore.records = [];
    deviceStore.scans = [];
    deviceStore.recordsLoading = true;
    deviceStore.scansLoading = false;
  },
  getRecords() {
    return devicesApi.getRecords().then(data => {
      deviceStore.records = data;
      deviceStore.recordsLoading = false;
      return data;
    });
  },
  getScans(record_ids) {
    deviceStore.scans = [];
    deviceStore.scansLoading = true;
    return devicesApi.getRecordScansWithHttpInfo(record_ids)
      .then(response_and_data => {
        deviceStore.scans = response_and_data.data;
        deviceStore.scansLoading = false;
        return deviceStore.scans;
      })
  },
  generateAndDownloadPDF(record_id) {
    devicesApi.generateReport({record_id: record_id}).then(({localUrl}) => {
      const link = document.createElement('a');
      link.href = localUrl;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    })
  },
});

export default deviceStore