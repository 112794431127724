class StopPromise extends Promise{
  then() {
    return new StopPromise((resolve) => resolve());
  }
  catch() {
    return new StopPromise((resolve) => resolve());
  }
}

function haltPromiseChain() {
  return new StopPromise((resolve) => resolve());
}

export {haltPromiseChain, StopPromise}