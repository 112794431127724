import { view } from "@risingstack/react-easy-state";
import { Box, Link } from "@mui/material";

const SupportEmail = view(() => {
  return (
    <Box>
      <Box component="span" color="text.secondary" sx={{ fontSize: "14px" }}>
        Need help?{" "}
      </Box>
      <Link href="mailto:support@cubisens.com">
        Email support@cubisens.com.
      </Link>
    </Box>
  );
});

export default SupportEmail;
