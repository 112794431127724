import { createTheme } from "@mui/material";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      ms: 768,
      md: 900,
      ml: 1024,
      lg: 1200,
      xl: 1536,
    },
  },
  palette: {
    common: {
      black: "#262626",
      white: "#FFF",
    },
    primary: {
      main: "#0B2FBB",
      light: "#546DCF",
      dark: "#0A2385",
      contrastText: "#FFF",
    },
    secondary: {
      main: "#F26222",
      light: "#F8B091",
      dark: "#973D15",
      contrastText: "#262626",
    },
    error: {
      main: "#F03E3E",
    },
    warning: {
      main: "#F59F00",
    },
    info: {
      main: "#F26222",
    },
    success: {
      main: "#37B24D",
    },
    grey: {
      50: "#596781",
      100: "#E5E7EF",
      200: "#D5D7DF",
      300: "#596781",
      400: "#989A9F",
      500: "#797B80",
      600: "#5B5C60",
      700: "#3D3D40",
      800: "#262626",
      900: "#1E1F20"
    },
    text: {
      primary: "#262626",
      secondary: "#596781",
      disabled: "#D5D9DF",
    },
    background: {
      paper: "#FFF",
      default: "#F3F6FF",
    },
    accent: {
      astronautblue: "#003F5C",
      cornflower: "#29487a",
      deeppurple: "#60498c",
      redviolet: "#b73c83",
      cerisered: "#e43c5d"
    }
  },
  typography: {
    fontFamily: "'Inter', sans-serif",
    h1: {
      fontWeight: "700",
      color: "#262626",
      fontSize: '36px',
      lineHeight: "1.17",
      letterSpacing: "-0.8px",
      '@media (min-width:768px)': {
        fontSize: "48px",
      },
    },
    h2: {
      fontWeight: "700",
      color: "#262626",
      fontSize: "24px",
      lineHeight: "1.1",
      letterSpacing: "-0.8px",
      '@media (min-width:768px)': {
        fontSize: "40px",
      },
    },
    h3: {
      fontWeight: "700",
      color: "#262626",
      fontSize: "32px",
      lineHeight: "1.125",
      letterSpacing: "-0.8px"
    },
    h4: {
      fontWeight: "700",
      color: "#262626",
      lineHeight: "1.5",
      fontSize: '14px',
      '@media (min-width:768px)': {
        fontSize: "24px",
      },
    },
    h5: {
      fontWeight: "700",
      color: "#262626",
      fontSize: "16px",
      lineHeight: "2.25",
    },
    h6: {
      fontWeight: "700",
      color: "#262626",
      fontSize: "14px",
      lineHeight: "2",
    },
    body1: {
      fontWeight: "400",
      color: "#596781",
      lineHeight: "1.5",
      fontSize: '14px',
      '@media (min-width:768px)': {
        fontSize: "16px",
      },
    },
    body2: {
      fontFamily: "'Inter', sans-serif",
      fontWeight: "400",
      fontSize: "14px",
      color: "#797B80",
      lineHeight: "2"
    },
    button: {
      textTransform: "uppercase",
      fontSize: "16px",
      lineHeight: "2.25",
      fontWeight: "700",
      borderRadius: "2px",
      outline: 'none !important',
    },
    caption: {
      fontSize: "12px",
      lineHeight: "3",
      fontWeight: "700",
    },
    detail: {
      fontSize: "12px",
      lineHeight: "1.5",
      fontWeight: "500",
      color: "#797B80"
    }
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      }
    },
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          ...(ownerState.variant === 'contained' &&
            ownerState.color === 'primary' && {
              minHeight: '40px',
              padding: '9px 12px',
              borderRadius: '2px',
              boxShadow: 'none',
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.common.white,
              '&:hover': {
                backgroundColor: theme.palette.primary.light,
              },
              '&:active': {
                backgroundColor: theme.palette.primary.dark
              },
              fontSize: '14px',
              lineHeight: 1.65,
              '@media (min-width:768px)': {
                lineHeight: '1.65',
              },
            }),
            ...(ownerState.variant === 'contained' &&
            ownerState.size === 'small' && {
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.common.white,
              '&:hover': {
                backgroundColor: theme.palette.primary.light,
              },
              '&:active': {
                backgroundColor: theme.palette.primary.dark
              },
              fontSize: "13px",
              lineHeight: 1.65,
              '@media (min-width:768px)': {
                fontSize: '13px',
                lineHeight: '1.65',
              },
            }),
            ...(ownerState.variant === 'outlined' && {
              backgroundColor: theme.palette.common.white,
              borderColor: theme.palette.primary.main,
              color: theme.palette.primary.main,
              '&:hover': {
                borderColor: theme.palette.primary.dark,
                backgroundColor: 'initial',
                color: theme.palette.primary.dark
              },
              '&:active': {
                backgroundColor: '#E5E7EF',
                borderColor: theme.palette.primary.dark,
                color: theme.palette.primary.dark
              }
            }),
        }),
      },
      variants: [
        {
          props: { variant: 'link' },
          style: {
            padding: '0px',
            borderWidth: '1px',
            borderStyle: 'none none solid none',
            borderRadius: '0',
            borderColor: '#262626',
            lineHeight: '1.3',
            textTransform: 'none',
            color: '#262626',
            '&:hover': {
              color: '#0B35B5',
              borderColor:'#0B35B5',
              backgroundColor: 'initial'
            },
            '&:active': {
              backgroundColor: '#CED4DA',
            } 
          },
        },
        {
          props: { variant: 'trietary' },
          style: {
            backgroundColor: '#fff',
            border: '1px solid #CED4DA',
            color: '#797B80',
            '&:hover': {
              borderColor: '#797B80',
              backgroundColor: 'initial'
            },
            '&:active': {
              backgroundColor: '#E5E7EF',
              borderColor: 'initial',
            }
          },
        },
      ],
    },
    MuiInput: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          border: '1px solid #CED4DA',
          borderRadius: '4px',
          padding: '4px 10px',
          transition: 'border-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
          '&::before,&::after' : {
            top: '0px',
            borderBottom: 'none'
          },
          '&:hover': {
            border: `1px solid ${theme.palette.primary.main}`,
            transition: 'border-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
          },
          '&:hover:not(.Mui-disabled):before': {
            borderBottom: `1px solid ${theme.palette.primary.main}`
          },
          '&:focus:not(.Mui-disabled):before': {
            borderBottom: `2px solid ${theme.palette.primary.main}`
          },
        })
      }
    },
    MuiLink: {
      styleOverrides: {
        root: {
          fontSize: "14px",
          color: '#262626',
          textDecorationColor: '#262626',
          '&:hover': {
            color: '#0B35B5',
            textDecorationColor: '#0B35B5',
          },
          '&:active': {
            backgroundColor: '#CED4DA',
          } 
        }
      },
      variants: [
        {
          props: {variant: 'record'},
          style: {
            fontSize: "14px",
            color: '#1B1D20',
            textDecoration: 'none',
            cursor: 'pointer'
          }
        }
      ]
    },
    MuiContainer: {
      styleOverrides: {
        root: {

        }
      },
      variants: [
        {
          props: { variant: 'card' },
          style: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#FFF",
            width:'100%',
            '@media (min-width:576px)': {
              width: '90%',
              maxWidth: '582px',
              borderRadius: '8px',
              boxShadow: '0px 4px 45px rgba(11, 53, 181, 0.1)',
              padding: '48px',
            },
          }
        },
        {
          props: {variant: 'grid'},
          style: {
            paddingLeft: '0px',
            paddingRight: '0px',
            '@media (min-width:425px)': {
              paddingLeft: 'initial',
              paddingRight: 'initial',
            },
            '@media (min-width:1200px)': {
              maxWidth: 'initial'
            },
          }
        }
      ]
    },
    MuiPaper: {
      variants: [
        {
          props: { variant: 'background' },
          style: {
            display: "flex",
            alignItems: "center",
            height: "100vh",
            backgroundColor: "white",
            '@media (min-width:576px)': {
              backgroundImage: `url(${process.env.PUBLIC_URL}/background.png)`,
              backgroundSize: 'cover',
            },
          }
        },
      ]
    },
    MuiToolbar: {
      variants: [
        {
          props: {variant: 'nopadding'},
          style: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '14px 0px',
            '@media (min-width:425px)': {
              padding: '6.2667px 0px',
            },
          }
        }
      ]
    },
    MuiListItemButton: {
      variants: [
        {
          props: {variant: 'custom'},
          style: {
            width: '102px',
            height:'109px',
            justifyContent: 'center',
            alignItems: 'center',
            color: '#D5D7DF',
          }
        },
        {
          props: {variant: 'custom-match'},
          style: {
            width: '102px',
            height:'109px',
            justifyContent: 'center',
            alignItems: 'center',
            color: '#FFFFFF',
            background: '#0A2385',
          }
        }
      ]
    },
    MuiListItemIcon: {
      variants: [
        {
          props: {variant: 'side-nav-custom'},
          style: {
            justifyContent: 'center',
            alignItems: 'center',
            color: '#D5D7DF',
          }
        },
        {
          props: {variant: 'side-nav-custom-match'},
          style: {
            justifyContent: 'center',
            alignItems: 'center',
            color: '#FFFFFF',
          }
        },
      ]
    },
    MuiMenu: {
      variants: [
        {
          props: {variant: 'menu'},
          style: {
            mt: "45px",
            '& .MuiPopover-paper': {
              borderRadius: '16px',
              boxShadow: "0px 4px 45px rgba(11, 53, 181, 0.1)",
              '& .MuiMenuItem-root': {
                padding: '3px 8px',
                background: 'initial',
                '& .MuiAlert-root': {
                  background: 'white',
                  borderRadius: '8px',
                  '&:hover': {
                    backgroundColor: '#F3F6FF',
                    '& .MuiAlert-icon': {
                      color: '#F03E3E'
                    }  
                  },
                  '& .MuiAlert-icon': {
                    color: '#5B5C60'
                  }
                }
              }
            }
          }
        }
      ]
    },
    MuiIconButton: {
      variants: [
        {
          props: {variant: 'notification'},
          style: {
            display:"none",
            '@media (min-width:768px)': {
              display: 'inline-block',
              marginRight: '10px',
              outline: 'none',
              '&:focus': {
                outline: 'none'
              },
              '& .css-1ixf1xf-MuiBadge-badge': {
                top: '13px',
                right: '8px',
                border: '2px solid white',
                color: 'white',
                padding: 0,
              }
            },
          }
        }
      ]
    },
    MuiPopper: {
      styleOverrides: {
        root: ({ ownerState, theme }) =>({
          '& .css-1t5wrdm-MuiDataGrid-filterForm': {
            flexDirection: 'column',
            '& .css-rne967-MuiFormControl-root-MuiDataGrid-filterFormDeleteIcon': {
              alignItems: 'flex-end',
              justifyContent: 'initial',
              '& .css-1pe4mpk-MuiButtonBase-root-MuiIconButton-root': {
                display: 'inline-block',
                width: '35px',
              }
            }
          }
          // transform: 'translate(1380px, 700px)!important',
          // ...(ownerState.data-popper-placement === 'contained' && {
            
          // }
          // '& .MuiDataGrid-panel': {
          //   display:'block'
          // }

        })
      }
    }
  },
  mixins: {
    toolbar: {
      minHeight: "67px",
    }
  }
});
export default theme;
