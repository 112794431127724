import { autoEffect, view } from "@risingstack/react-easy-state";
import { Box, Typography } from "@mui/material";
import { GridToolbar } from "@mui/x-data-grid-premium";
import RecordGridPremium from "../RecordGridPremium";
import alarmStore from "../../store/alarmStore";
import { styleDate } from "../../utils/utilFunctions";

const columns = [
  {
    field: "highest_recorded_temperature",
    headerName: "Highest Recorded Temperature",
    valueGetter: ({ row }) => {
      if (row.highest_recorded_temperature) {
        return row.highest_recorded_temperature;
      } else {
        return "No data";
      }
    },
    flex: 0.25,
  },
  {
    field: "lowest_recorded_temperature",
    headerName: "Lowest Recorded Temperature",
    valueGetter: ({ row }) => {
      if (row.lowest_recorded_temperature) {
        return row.lowest_recorded_temperature;
      } else {
        return "No data";
      }
    },
    flex: 0.25,
  },
  {
    field: "start_time",
    headerName: "Start Time",
    valueGetter: ({ row }) => {
      if (row.start_time) {
        return row.start_time;
      } else {
        return "No data";
      }
    },
    valueFormatter: ({value}) => {
      return styleDate(value, "No data");
    },
    flex: 1,
  },
  {
    field: "end_time",
    headerName: "End Time",
    valueGetter: ({ row }) => {
      if (row.end_time) {
        return row.end_time;
      } else {
        return "No data";
      }
    },
    valueFormatter: ({value}) => {
      return styleDate(value, "No data");
    },
    flex: 1,
  },
  {
    field: "violated_configuration",
    headerName: "Violated Configuration",
    valueGetter: ({ row }) => {
      if (row.above_or_below === "ABOVE") {
        return `High threshold (${row.high_threshold}°C)`;
      } else if (row.above_or_below === "BELOW") {
        return `Low threshold (${row.low_threshold}°C)`;
      } else {
        return "No data";
      }
    },
    flex: 0.5,
  },
  {
    field: "degree_minutes",
    headerName: "Degree Minutes",
    valueGetter: ({ row }) => {
      if (row.degree_minutes) {
        return Math.round(row.degree_minutes * 100) / 100;
      } else {
        return "No data";
      }
    },
    flex: 0.3,
  },
  {
    field: "duration",
    headerName: "Duration",
    valueGetter: ({ row }) => {
      if (row.duration || row.duration === 0) {
        return row.duration;
      } else {
        return "No data";
      }
    },
    flex: 0.25,
  },
];

const AlarmDetails = view(({ record }) => {
  autoEffect(() => {
    if (record) {
      alarmStore.getExcursionsForRecord(record.id);
    }
  }, [record]);

  if (record && alarmStore.excursions.length > 0) {
    return (
      <Box
        sx={{
          height: 300,
        }}
      >
        <RecordGridPremium
          components={{ Toolbar: GridToolbar }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
          disableDensitySelector
          rows={alarmStore.excursions}
          columns={columns}
          initialState={{
            sorting: {
              sortModel: [{ field: "end_time", sort: "desc" }],
            },
          }}
          pageSize={5}
          rowsPerPageOptions={[5]}
          disableSelectionOnClick
        />
      </Box>
    );
  } else if (record && alarmStore.excursions.length === 0) {
    return <Typography>This record does not have any alarms.</Typography>;
  } else {
    return <Typography>This record has not loaded yet.</Typography>;
  }
});

export default AlarmDetails;
