/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import OrganizationId from './OrganizationId';
import RoleMembership from './RoleMembership';
import SitePermissionChoices from './SitePermissionChoices';

/**
 * The PrivateUser model module.
 * @module model/PrivateUser
 * @version 0.0.0
 */
class PrivateUser {
    /**
     * Constructs a new <code>PrivateUser</code>.
     * @alias module:model/PrivateUser
     * @param id {String} 
     * @param username {String} Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only.
     * @param roleMemberships {Array.<module:model/RoleMembership>} 
     * @param currentSessionPermissions {Array.<module:model/SitePermissionChoices>} 
     */
    constructor(id, username, roleMemberships, currentSessionPermissions) { 
        
        PrivateUser.initialize(this, id, username, roleMemberships, currentSessionPermissions);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, id, username, roleMemberships, currentSessionPermissions) { 
        obj['id'] = id;
        obj['username'] = username;
        obj['role_memberships'] = roleMemberships;
        obj['current_session_permissions'] = currentSessionPermissions;
    }

    /**
     * Constructs a <code>PrivateUser</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/PrivateUser} obj Optional instance to populate.
     * @return {module:model/PrivateUser} The populated <code>PrivateUser</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new PrivateUser();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'String');
            }
            if (data.hasOwnProperty('username')) {
                obj['username'] = ApiClient.convertToType(data['username'], 'String');
            }
            if (data.hasOwnProperty('first_name')) {
                obj['first_name'] = ApiClient.convertToType(data['first_name'], 'String');
            }
            if (data.hasOwnProperty('last_name')) {
                obj['last_name'] = ApiClient.convertToType(data['last_name'], 'String');
            }
            if (data.hasOwnProperty('email')) {
                obj['email'] = ApiClient.convertToType(data['email'], 'String');
            }
            if (data.hasOwnProperty('date_joined')) {
                obj['date_joined'] = ApiClient.convertToType(data['date_joined'], 'Date');
            }
            if (data.hasOwnProperty('organizations')) {
                obj['organizations'] = ApiClient.convertToType(data['organizations'], [OrganizationId]);
            }
            if (data.hasOwnProperty('role_memberships')) {
                obj['role_memberships'] = ApiClient.convertToType(data['role_memberships'], [RoleMembership]);
            }
            if (data.hasOwnProperty('current_session_permissions')) {
                obj['current_session_permissions'] = ApiClient.convertToType(data['current_session_permissions'], [SitePermissionChoices]);
            }
            if (data.hasOwnProperty('last_session_org_id')) {
                obj['last_session_org_id'] = ApiClient.convertToType(data['last_session_org_id'], 'String');
            }
        }
        return obj;
    }


}

/**
 * @member {String} id
 */
PrivateUser.prototype['id'] = undefined;

/**
 * Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only.
 * @member {String} username
 */
PrivateUser.prototype['username'] = undefined;

/**
 * @member {String} first_name
 */
PrivateUser.prototype['first_name'] = undefined;

/**
 * @member {String} last_name
 */
PrivateUser.prototype['last_name'] = undefined;

/**
 * @member {String} email
 */
PrivateUser.prototype['email'] = undefined;

/**
 * @member {Date} date_joined
 */
PrivateUser.prototype['date_joined'] = undefined;

/**
 * @member {Array.<module:model/OrganizationId>} organizations
 */
PrivateUser.prototype['organizations'] = undefined;

/**
 * @member {Array.<module:model/RoleMembership>} role_memberships
 */
PrivateUser.prototype['role_memberships'] = undefined;

/**
 * @member {Array.<module:model/SitePermissionChoices>} current_session_permissions
 */
PrivateUser.prototype['current_session_permissions'] = undefined;

/**
 * @member {String} last_session_org_id
 */
PrivateUser.prototype['last_session_org_id'] = undefined;






export default PrivateUser;

