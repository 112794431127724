/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import SitePermissionChoices from './SitePermissionChoices';

/**
 * The PermissionRole model module.
 * @module model/PermissionRole
 * @version 0.0.0
 */
class PermissionRole {
    /**
     * Constructs a new <code>PermissionRole</code>.
     * @alias module:model/PermissionRole
     * @param id {String} 
     * @param name {String} 
     * @param permissionNames {Array.<module:model/SitePermissionChoices>} 
     * @param createdByOrganizationId {String} 
     */
    constructor(id, name, permissionNames, createdByOrganizationId) { 
        
        PermissionRole.initialize(this, id, name, permissionNames, createdByOrganizationId);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, id, name, permissionNames, createdByOrganizationId) { 
        obj['id'] = id;
        obj['name'] = name;
        obj['permission_names'] = permissionNames;
        obj['created_by_organization_id'] = createdByOrganizationId;
    }

    /**
     * Constructs a <code>PermissionRole</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/PermissionRole} obj Optional instance to populate.
     * @return {module:model/PermissionRole} The populated <code>PermissionRole</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new PermissionRole();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'String');
            }
            if (data.hasOwnProperty('name')) {
                obj['name'] = ApiClient.convertToType(data['name'], 'String');
            }
            if (data.hasOwnProperty('permission_names')) {
                obj['permission_names'] = ApiClient.convertToType(data['permission_names'], [SitePermissionChoices]);
            }
            if (data.hasOwnProperty('created_by_organization_id')) {
                obj['created_by_organization_id'] = ApiClient.convertToType(data['created_by_organization_id'], 'String');
            }
        }
        return obj;
    }


}

/**
 * @member {String} id
 */
PermissionRole.prototype['id'] = undefined;

/**
 * @member {String} name
 */
PermissionRole.prototype['name'] = undefined;

/**
 * @member {Array.<module:model/SitePermissionChoices>} permission_names
 */
PermissionRole.prototype['permission_names'] = undefined;

/**
 * @member {String} created_by_organization_id
 */
PermissionRole.prototype['created_by_organization_id'] = undefined;






export default PermissionRole;

