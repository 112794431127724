import React from "react";
import { view } from "@risingstack/react-easy-state";
import { Box, Button, Link } from "@mui/material";
import RecordsGridPremium from "./RecordsGridPremium";
import clsx from "clsx";
import { styleDate } from "../utils/utilFunctions";

const columns = [
  {
    field: "primary_reference",
    headerName: "Primary Reference",
    valueGetter: ({ row }) => {
      if (row.primary_reference) {
        return row.primary_reference;
      } else {
        return "No data";
      }
    },
    flex: 1,
  },
  {
    field: "id",
    headerName: "Record ID",
    renderCell: (params) => (
      <Link href={"/recorddetail/" + params.row.id}>
        {params.row.id}
      </Link>
    ),
    flex: 1,
  },
  {
    field: "status",
    headerName: "Status",
    valueGetter: ({ row }) => {
      if (row.status) {
        return row.status;
      } else {
        return "No data";
      }
    },
    cellClassName: ({ value }) => {
      return clsx("cell-status", {
        ok: value === "OK",
        alarm: value === "Alarm",
      });
    },
    flex: 1,
  },
  {
    field: "start_time",
    headerName: "Start Time",
    valueGetter: ({ row }) => {
      if (row.start_time) {
        return row.start_time.getTime();
      } else {
        return 0;
      }
    },
    valueFormatter: ({value}) => {
      return styleDate(value, "No data");
    },
    flex: 1,
  },
  {
    field: "stop_time",
    headerName: "Stop Time",
    valueGetter: ({ row }) => {
      if (row.stop_time) {
        return row.stop_time.getTime();
      } else {
        return 0;
      }
    },
    valueFormatter: ({value}) => {
      return styleDate(value, "No data");
    },
    flex: 1,
  },
  {
    field: "last_scanned",
    headerName: "Last Scanned",
    valueGetter: ({ row }) => {
      if (row.last_scanned) {
        return row.last_scanned.getTime();
      } else {
        return 0;
      }
    },
    valueFormatter: ({value}) => {
      return styleDate(value, "No data");
    },
    flex: 1,
  },
];

const RecentRecordsDashboardWidget = view(({recordData, selectedRows, setSelectedRows, loading}) => {
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column"
      }}
    >
      <RecordsGridPremium
        type="recent"
        selectionModel={selectedRows}
        onSelectionModelChange={setSelectedRows}
        rows={recordData}
        loading={loading}
        columns={columns}
        initialState={{
          sorting: {
            sortModel: [{ field: "last_scanned", sort: "desc" }],
          },
        }}
        pagination
        pageSize={5}
        rowsPerPageOptions={[5]}
        hideFooterSelectedRowCount={false}
      />
        <Button
          href={`/allrecords`}
          variant="contained"
          sx={{ alignSelf: "flex-end", mt: 1 }}
        >
          View All Records
        </Button>
    </Box>
  );
});

export default RecentRecordsDashboardWidget;
