import React from "react";
import { Box, Button,  Typography, } from "@mui/material";
import deviceStore from "../store/deviceStore";
import { view } from "@risingstack/react-easy-state";
// eslint-disable-next-line no-unused-vars
import { XT1Record } from "../api/client/src";
import { getdownloadButtonInfo } from "../utils/utilFunctions";

const PageTitle = view(/** @param {{title: string, single: boolean, recordToDownload: XT1Record}} */({title, single, recordToDownload}) => {
  const downloadButtonInfo = getdownloadButtonInfo(recordToDownload);

  return (
    <Box 
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        background: 'white',
        justifyContent: 'space-between',
        padding: '25px 22px',
        alignItems: 'center',
        borderBottom: '1px solid #D5D7DF',
        '@media (min-width:768px)': {
          padding: '46px 25px 21px',
        },
      }}
    >
      <Typography variant="h2" >{title}</Typography>
      {
        recordToDownload && downloadButtonInfo ? 
          <Button variant="contained" disabled={!downloadButtonInfo.enabled} onClick={() => deviceStore.generateAndDownloadPDF(recordToDownload.id)}>
            {downloadButtonInfo.text}
          </Button>
        : null
      }
    </Box>
  )
});

export default PageTitle