import { store, view } from "@risingstack/react-easy-state";
import browserHistory from "../../router";
import {
  Alert,
  Box,
  Button,
  Container,
  InputLabel,
  Input,
  Link,
  Paper
} from "@mui/material";
import { authApi } from "../../api/clientInstances";
import PasswordResetEmail from "../../api/client/src/model/PasswordResetEmail";
import { Logo } from "../../components/Logo";
import SupportEmail from "../../components/SupportEmail";

const ForgotPassword = view(() => {
  const state = store({
    email: "",
    error: "",
  });

  const handleEmailChange = (event) => {
    state.email = event.target.value;
  };

  const requestPasswordReset = (event) => {
    event.preventDefault();
    authApi.requestPasswordReset(new PasswordResetEmail(state.email))
      .then(() => browserHistory.push("/forgotpasswordconfirm"))
      .catch((err) => {
        console.log(err);
        state.error =
          "Please enter the email address associated with your account.";
      });
  };

  return (
    <Paper variant="background">
      <Container
        maxWidth="xs"
        variant="card"
      >
        <Logo />
        <Box sx={{ display: "flex", justifyContent: "center", width: "100%", mb:"25px" }}>
          <form onSubmit={requestPasswordReset} style={{width:"100%"}}>
            <InputLabel htmlFor="email" sx={{color:"text.primary", margin:"0", fontSize:"14px"}}>Email</InputLabel>
            <Input
              fullWidth
              id="email"
              name="email"
              label=""
              type="email"
              value={state.email}
              onChange={handleEmailChange}
              sx={{ mt: '0px', mb: '25px' }}
              autoFocus={false}
              required
            />
            {state.error ? (
              <Alert severity="error" sx={{ mb: 2 }}>
                {state.error}
              </Alert>
            ) : null}
            <Button type="submit" variant="contained" fullWidth sx={{fontWeight:"bold"}}>
              Request Instructions to Reset Password
            </Button>
          </form>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexDirection: "column",
            "@media (min-width:576px)": {
              flexDirection: "row",
            },
          }}
        >
          <Link href="/login">Return to the login screen.</Link>
          <SupportEmail />
        </Box>
      </Container>
    </Paper>
  );
});

export default ForgotPassword;
