import { autoEffect, store, view } from "@risingstack/react-easy-state";
import { Box, Container, Grid, Typography } from "@mui/material";
import WidgetCard from "../components/WidgetCard";
import RecentRecordsDashboardWidget from "../components/RecentRecordsDashboardWidget";
import TemperatureGraph from "../components/single-record-widgets/TemperatureGraph";
import CustomClient from "../api/CustomClient";
import { XT1Record } from "../api/client/src";
import PageTitle from "../components/PageTitle";
import deviceStore from "../store/deviceStore";

const Dashboard = view(() => {
  const state = store({
    get graphScans() {
      return [...new Map(deviceStore.scans.map(scan =>
        [scan.record, scan])).values()]
    },
    gridRecordData: [],
    selectedRows: [],
    setSelectedRows(rows) {
      const oldRows = state.selectedRows;
      state.selectedRows = rows;
      if (rows.length > 0) {
        if (rows.length !== oldRows.length || rows.some((row, idx) => row !== oldRows[idx])) {
          deviceStore.getScans(rows);
        }
      }
      else {
        deviceStore.scans = [];
      }
    },
  });

  autoEffect(() => {
    setTimeout(() => {
      const data = CustomClient.convertToType(deviceStore.records, [XT1Record]);
      data.sort((a, b) => {
        if (!b.last_scanned) return -1;
        if (!a.last_scanned) return 1;
        return b.last_scanned.getTime() - a.last_scanned.getTime()
      });
      state.gridRecordData = data;
      if (data.length > 0) {
        state.setSelectedRows([data[0].id]);
      }
    });
  }, [deviceStore.records]);

  return (
    <Container variant="grid">
      <Box>
        <PageTitle title="Dashboard"/>
        <Grid item xs={12} sm={12} md={12}>
            {/* <WidgetCard title="Map">
              <Typography>DEV TEAM TO INSERT MAP HERE</Typography>
            </WidgetCard> */}
             <Box sx={{position: "relative"}} > {/* "Position" is only needed for the map overlay. Okay to remove once the map is working. */}
              <Box
                component="img"
                sx={{
                height: '100%',
                width: '100%',
                minHeight: '300px',
                objectFit: 'cover',
              }}
                alt="map"
                src={`${process.env.PUBLIC_URL}/map.png`} 
              />
              {/* This Box component and the child Typography component are only needed for the map overlay. Okay to remove once the map is working. */}
              <Box
                sx={{
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  width: "100%",
                  bgcolor: "rgba(255, 255, 255, 0.65)",
                  color: "white",
                  height: "100%",
                  padding: "41.5px",
                  display: "flex",
                  alignItems: "flex-end",
                  justifyContent: "flex-end",
                }}
              >
                <Typography variant="h2" color="grey.500">
                  Map Coming Soon
                </Typography>
              </Box>
            </Box>
          </Grid>
        <Grid 
          container 
          spacing={2} 
          padding={"30px"} 
          sx={{
            padding: '15px',
            '@media (min-width:768px)': {
              padding: '30px',
            },
          }}
        >
          <Grid 
            item 
            rowSpacing={2} 
            xs={12} 
            lg={6}
            minHeight="400px"
          >
            <WidgetCard
              title="Recent Records"
              sx={{
                height: "690px",
                "@media (min-width:0px)": {
                  height: "720px",
                },
                "@media (min-width:600px)": {
                  height: "620px",
                },
                "@media (min-width:1024px)": {
                  height: "570px",
                },
                "@media (min-width:1200px)": {
                  height: "620px",
                },
                "@media (min-width:1776px)": {
                  height: "570px",
                },
              }}
            >
              <RecentRecordsDashboardWidget
                recordData={state.gridRecordData}
                selectedRows={state.selectedRows}
                setSelectedRows={state.setSelectedRows}
                loading={deviceStore.recordsLoading}
              />
            </WidgetCard>
          </Grid>
          <Grid item xs={12} lg={6} minHeight="400px">
            <WidgetCard 
              title="Temperature Graph" 
              sx={{
                height: "690px",
                "@media (min-width:0px)": {
                  height: "720px",
                },
                "@media (min-width:600px)": {
                  height: "620px",
                },
                "@media (min-width:1024px)": {
                  height: "570px",
                },
                "@media (min-width:1200px)": {
                  height: "620px",
                },
                "@media (min-width:1776px)": {
                  height: "570px",
                },
              }}
            >
              <TemperatureGraph scans={state.graphScans} />
            </WidgetCard>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
});

export default Dashboard;
