import {
  Alert,
  Box,
  Button,
  Container,
  Link,
  InputLabel,
  Input,
  Paper
} from "@mui/material";
import { autoEffect, store, view } from "@risingstack/react-easy-state";
import browserHistory from "../router";
import authStore from "../store/authStore";
import alarmStore from "../store/alarmStore";
import deviceStore from "../store/deviceStore";
import { Logo } from "../components/Logo";
import SupportEmail from "../components/SupportEmail";

const Login = view(() => {
  const state = store({
    emailOrUsername: "",
    password: "",
    error: "",
  });


  autoEffect(() => {
    alarmStore.clear();
    deviceStore.clear();
    authStore.clear();
  });

  const handlePasswordChange = (event) => {
    state.password = event.target.value;
  };

  const handleUserNameChange = (event) => {
    state.emailOrUsername = event.target.value;
  };

  const login = (event) => {
    event.preventDefault();
    authStore
      .login(state.emailOrUsername, state.password)
      .then(() => {
        state.emailOrUsername = "";
        state.password = "";
        state.error = "";
        browserHistory.push("/");
      })
      .catch((err) => {
        console.log(err);
        state.error =
          "Incorrect email address and/or password. Please try again.";
      });
  };

  return (
    <Paper variant="background">
      <Container
        maxWidth="xs"
        variant="card"
      >
        <Logo />
        <Box sx={{ display: "flex", justifyContent: "center", width: "100%", mb:"25px"}}>
          <form onSubmit={login} style={{width:"100%"}}>
            <InputLabel htmlFor="email" sx={{color:"text.primary", margin:"0", fontSize:"14px"}}>Email</InputLabel>
            <Input
              fullWidth
              id="email"
              name="email"
              label=""
              type="email"
              value={state.emailOrUsername}
              onChange={handleUserNameChange}
              sx={{ mt: '0px', mb: '15px' }}
              autoFocus={false}
            />
            <InputLabel htmlFor="password" sx={{color:"text.primary", margin:"0", fontSize:"14px"}}>Password</InputLabel>
            <Input
              fullWidth
              id="password"
              name="password"
              type="password"
              value={state.password}
              onChange={handlePasswordChange}
              size="small"
              sx={{ mt: '0px', mb: '32px' }}
            />
            {state.error ? (
              <Alert severity="error" sx={{ mb: 2 }}>
                {state.error}
              </Alert>
            ) : null}
            {/* <Box
              textAlign='center' 
              sx={{
                maxWidth:'277px',
                margin: 'auto',
                mb: '25px',
                '@media (min-width:425px)': {
                  maxWidth: '344px',
                },
              }}
            >
              <Box component='span' color="text.secondary" sx={{fontSize:"14px"}}>By logging in, you verify that you have read and </Box> 
              <Link href="#">agree to terms of use and privacy policy</Link>.
            </Box> */}
            <Button type="submit" variant="contained" fullWidth sx={{fontWeight:"bold" }}>
              Log In
            </Button>
          </form>
        </Box>
        <Box 
          sx={{
            width:"100%", 
            display:"flex", 
            justifyContent:"space-between", 
            alignItems:"center",
            flexDirection: 'column',
            '@media (min-width:576px)': {
              flexDirection: 'row',
            },
          }}
        >
          <Link variant="link" href="/forgotpassword" sx={{mb:'25px', '@media (min-width:576px)': {
            mb: 'initial'
          },}}>Forgot password?</Link>
          <SupportEmail />
        </Box>
      </Container>
    </Paper>
  );
});

export default Login;
