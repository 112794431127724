/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import BatteryHealthEnum from './BatteryHealthEnum';
import MemoryHealthEnum from './MemoryHealthEnum';
import StatusEnum from './StatusEnum';
import TagStatusEnum from './TagStatusEnum';
import TagTypeEnum from './TagTypeEnum';
import XT1ScanCreatedByUser from './XT1ScanCreatedByUser';
import XT1ScanTempEntry from './XT1ScanTempEntry';

/**
 * The XT1Scan model module.
 * @module model/XT1Scan
 * @version 0.0.0
 */
class XT1Scan {
    /**
     * Constructs a new <code>XT1Scan</code>.
     * @alias module:model/XT1Scan
     * @param id {String} 
     * @param startTime {Date} 
     * @param stopTime {Date} 
     * @param temperatures {Array.<module:model/XT1ScanTempEntry>} 
     * @param tagId {Number} 
     * @param latitude {Number} 
     * @param longitude {Number} 
     * @param highestRecordedTemp {Number} 
     * @param lowestRecordedTemp {Number} 
     * @param meanKineticTemp {Number} 
     * @param excursionDurationAbove {Number} 
     * @param excursionDurationBelow {Number} 
     * @param createdByUser {module:model/XT1ScanCreatedByUser} 
     * @param created {Date} 
     * @param timestamp {Date} 
     * @param tagStatus {module:model/TagStatusEnum} 
     * @param memoryHealth {module:model/MemoryHealthEnum} 
     * @param batteryHealth {module:model/BatteryHealthEnum} 
     * @param mobileDeviceType {String} 
     * @param mobileAppVersion {String} 
     * @param headerData {Blob} 
     * @param measurementInterval {Number} In minutes
     * @param startDelay {Number} In minutes
     * @param highThreshold {Number} In Degrees Celsius
     * @param lowThreshold {Number} In Degrees Celsius
     * @param record {String} 
     */
    constructor(id, startTime, stopTime, temperatures, tagId, latitude, longitude, highestRecordedTemp, lowestRecordedTemp, meanKineticTemp, excursionDurationAbove, excursionDurationBelow, createdByUser, created, timestamp, tagStatus, memoryHealth, batteryHealth, mobileDeviceType, mobileAppVersion, headerData, measurementInterval, startDelay, highThreshold, lowThreshold, record) { 
        
        XT1Scan.initialize(this, id, startTime, stopTime, temperatures, tagId, latitude, longitude, highestRecordedTemp, lowestRecordedTemp, meanKineticTemp, excursionDurationAbove, excursionDurationBelow, createdByUser, created, timestamp, tagStatus, memoryHealth, batteryHealth, mobileDeviceType, mobileAppVersion, headerData, measurementInterval, startDelay, highThreshold, lowThreshold, record);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, id, startTime, stopTime, temperatures, tagId, latitude, longitude, highestRecordedTemp, lowestRecordedTemp, meanKineticTemp, excursionDurationAbove, excursionDurationBelow, createdByUser, created, timestamp, tagStatus, memoryHealth, batteryHealth, mobileDeviceType, mobileAppVersion, headerData, measurementInterval, startDelay, highThreshold, lowThreshold, record) { 
        obj['id'] = id;
        obj['start_time'] = startTime;
        obj['stop_time'] = stopTime;
        obj['temperatures'] = temperatures;
        obj['tag_id'] = tagId;
        obj['latitude'] = latitude;
        obj['longitude'] = longitude;
        obj['highest_recorded_temp'] = highestRecordedTemp;
        obj['lowest_recorded_temp'] = lowestRecordedTemp;
        obj['mean_kinetic_temp'] = meanKineticTemp;
        obj['excursion_duration_above'] = excursionDurationAbove;
        obj['excursion_duration_below'] = excursionDurationBelow;
        obj['created_by_user'] = createdByUser;
        obj['created'] = created;
        obj['timestamp'] = timestamp;
        obj['tag_status'] = tagStatus;
        obj['memory_health'] = memoryHealth;
        obj['battery_health'] = batteryHealth;
        obj['mobile_device_type'] = mobileDeviceType;
        obj['mobile_app_version'] = mobileAppVersion;
        obj['header_data'] = headerData;
        obj['measurement_interval'] = measurementInterval;
        obj['start_delay'] = startDelay;
        obj['high_threshold'] = highThreshold;
        obj['low_threshold'] = lowThreshold;
        obj['record'] = record;
    }

    /**
     * Constructs a <code>XT1Scan</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/XT1Scan} obj Optional instance to populate.
     * @return {module:model/XT1Scan} The populated <code>XT1Scan</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new XT1Scan();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'String');
            }
            if (data.hasOwnProperty('start_time')) {
                obj['start_time'] = ApiClient.convertToType(data['start_time'], 'Date');
            }
            if (data.hasOwnProperty('stop_time')) {
                obj['stop_time'] = ApiClient.convertToType(data['stop_time'], 'Date');
            }
            if (data.hasOwnProperty('temperatures')) {
                obj['temperatures'] = ApiClient.convertToType(data['temperatures'], [XT1ScanTempEntry]);
            }
            if (data.hasOwnProperty('tag_id')) {
                obj['tag_id'] = ApiClient.convertToType(data['tag_id'], 'Number');
            }
            if (data.hasOwnProperty('latitude')) {
                obj['latitude'] = ApiClient.convertToType(data['latitude'], 'Number');
            }
            if (data.hasOwnProperty('longitude')) {
                obj['longitude'] = ApiClient.convertToType(data['longitude'], 'Number');
            }
            if (data.hasOwnProperty('highest_recorded_temp')) {
                obj['highest_recorded_temp'] = ApiClient.convertToType(data['highest_recorded_temp'], 'Number');
            }
            if (data.hasOwnProperty('lowest_recorded_temp')) {
                obj['lowest_recorded_temp'] = ApiClient.convertToType(data['lowest_recorded_temp'], 'Number');
            }
            if (data.hasOwnProperty('mean_kinetic_temp')) {
                obj['mean_kinetic_temp'] = ApiClient.convertToType(data['mean_kinetic_temp'], 'Number');
            }
            if (data.hasOwnProperty('excursion_duration_above')) {
                obj['excursion_duration_above'] = ApiClient.convertToType(data['excursion_duration_above'], 'Number');
            }
            if (data.hasOwnProperty('excursion_duration_below')) {
                obj['excursion_duration_below'] = ApiClient.convertToType(data['excursion_duration_below'], 'Number');
            }
            if (data.hasOwnProperty('created_by_user')) {
                obj['created_by_user'] = XT1ScanCreatedByUser.constructFromObject(data['created_by_user']);
            }
            if (data.hasOwnProperty('created')) {
                obj['created'] = ApiClient.convertToType(data['created'], 'Date');
            }
            if (data.hasOwnProperty('tag_type')) {
                obj['tag_type'] = TagTypeEnum.constructFromObject(data['tag_type']);
            }
            if (data.hasOwnProperty('timestamp')) {
                obj['timestamp'] = ApiClient.convertToType(data['timestamp'], 'Date');
            }
            if (data.hasOwnProperty('primary_reference')) {
                obj['primary_reference'] = ApiClient.convertToType(data['primary_reference'], 'String');
            }
            if (data.hasOwnProperty('secondary_reference')) {
                obj['secondary_reference'] = ApiClient.convertToType(data['secondary_reference'], 'String');
            }
            if (data.hasOwnProperty('shipping_reference')) {
                obj['shipping_reference'] = ApiClient.convertToType(data['shipping_reference'], 'String');
            }
            if (data.hasOwnProperty('tag_status')) {
                obj['tag_status'] = TagStatusEnum.constructFromObject(data['tag_status']);
            }
            if (data.hasOwnProperty('memory_health')) {
                obj['memory_health'] = MemoryHealthEnum.constructFromObject(data['memory_health']);
            }
            if (data.hasOwnProperty('battery_health')) {
                obj['battery_health'] = BatteryHealthEnum.constructFromObject(data['battery_health']);
            }
            if (data.hasOwnProperty('mobile_device_type')) {
                obj['mobile_device_type'] = ApiClient.convertToType(data['mobile_device_type'], 'String');
            }
            if (data.hasOwnProperty('mobile_app_version')) {
                obj['mobile_app_version'] = ApiClient.convertToType(data['mobile_app_version'], 'String');
            }
            if (data.hasOwnProperty('header_data')) {
                obj['header_data'] = ApiClient.convertToType(data['header_data'], 'Blob');
            }
            if (data.hasOwnProperty('measurement_interval')) {
                obj['measurement_interval'] = ApiClient.convertToType(data['measurement_interval'], 'Number');
            }
            if (data.hasOwnProperty('start_delay')) {
                obj['start_delay'] = ApiClient.convertToType(data['start_delay'], 'Number');
            }
            if (data.hasOwnProperty('high_threshold')) {
                obj['high_threshold'] = ApiClient.convertToType(data['high_threshold'], 'Number');
            }
            if (data.hasOwnProperty('low_threshold')) {
                obj['low_threshold'] = ApiClient.convertToType(data['low_threshold'], 'Number');
            }
            if (data.hasOwnProperty('num_consecutive_excursions')) {
                obj['num_consecutive_excursions'] = ApiClient.convertToType(data['num_consecutive_excursions'], 'Number');
            }
            if (data.hasOwnProperty('description')) {
                obj['description'] = ApiClient.convertToType(data['description'], 'String');
            }
            if (data.hasOwnProperty('status')) {
                obj['status'] = StatusEnum.constructFromObject(data['status']);
            }
            if (data.hasOwnProperty('record')) {
                obj['record'] = ApiClient.convertToType(data['record'], 'String');
            }
        }
        return obj;
    }


}

/**
 * @member {String} id
 */
XT1Scan.prototype['id'] = undefined;

/**
 * @member {Date} start_time
 */
XT1Scan.prototype['start_time'] = undefined;

/**
 * @member {Date} stop_time
 */
XT1Scan.prototype['stop_time'] = undefined;

/**
 * @member {Array.<module:model/XT1ScanTempEntry>} temperatures
 */
XT1Scan.prototype['temperatures'] = undefined;

/**
 * @member {Number} tag_id
 */
XT1Scan.prototype['tag_id'] = undefined;

/**
 * @member {Number} latitude
 */
XT1Scan.prototype['latitude'] = undefined;

/**
 * @member {Number} longitude
 */
XT1Scan.prototype['longitude'] = undefined;

/**
 * @member {Number} highest_recorded_temp
 */
XT1Scan.prototype['highest_recorded_temp'] = undefined;

/**
 * @member {Number} lowest_recorded_temp
 */
XT1Scan.prototype['lowest_recorded_temp'] = undefined;

/**
 * @member {Number} mean_kinetic_temp
 */
XT1Scan.prototype['mean_kinetic_temp'] = undefined;

/**
 * @member {Number} excursion_duration_above
 */
XT1Scan.prototype['excursion_duration_above'] = undefined;

/**
 * @member {Number} excursion_duration_below
 */
XT1Scan.prototype['excursion_duration_below'] = undefined;

/**
 * @member {module:model/XT1ScanCreatedByUser} created_by_user
 */
XT1Scan.prototype['created_by_user'] = undefined;

/**
 * @member {Date} created
 */
XT1Scan.prototype['created'] = undefined;

/**
 * @member {module:model/TagTypeEnum} tag_type
 */
XT1Scan.prototype['tag_type'] = undefined;

/**
 * @member {Date} timestamp
 */
XT1Scan.prototype['timestamp'] = undefined;

/**
 * @member {String} primary_reference
 */
XT1Scan.prototype['primary_reference'] = undefined;

/**
 * @member {String} secondary_reference
 */
XT1Scan.prototype['secondary_reference'] = undefined;

/**
 * @member {String} shipping_reference
 */
XT1Scan.prototype['shipping_reference'] = undefined;

/**
 * @member {module:model/TagStatusEnum} tag_status
 */
XT1Scan.prototype['tag_status'] = undefined;

/**
 * @member {module:model/MemoryHealthEnum} memory_health
 */
XT1Scan.prototype['memory_health'] = undefined;

/**
 * @member {module:model/BatteryHealthEnum} battery_health
 */
XT1Scan.prototype['battery_health'] = undefined;

/**
 * @member {String} mobile_device_type
 */
XT1Scan.prototype['mobile_device_type'] = undefined;

/**
 * @member {String} mobile_app_version
 */
XT1Scan.prototype['mobile_app_version'] = undefined;

/**
 * @member {Blob} header_data
 */
XT1Scan.prototype['header_data'] = undefined;

/**
 * In minutes
 * @member {Number} measurement_interval
 */
XT1Scan.prototype['measurement_interval'] = undefined;

/**
 * In minutes
 * @member {Number} start_delay
 */
XT1Scan.prototype['start_delay'] = undefined;

/**
 * In Degrees Celsius
 * @member {Number} high_threshold
 */
XT1Scan.prototype['high_threshold'] = undefined;

/**
 * In Degrees Celsius
 * @member {Number} low_threshold
 */
XT1Scan.prototype['low_threshold'] = undefined;

/**
 * Number of consecutive temperature points outside thresholds before an alarm is triggered
 * @member {Number} num_consecutive_excursions
 */
XT1Scan.prototype['num_consecutive_excursions'] = undefined;

/**
 * @member {String} description
 */
XT1Scan.prototype['description'] = undefined;

/**
 * @member {module:model/StatusEnum} status
 */
XT1Scan.prototype['status'] = undefined;

/**
 * @member {String} record
 */
XT1Scan.prototype['record'] = undefined;






export default XT1Scan;

