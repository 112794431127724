import { store, view } from "@risingstack/react-easy-state";
import authStore from "../store/authStore";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import {
  Avatar,
  Box,
  Container,
  // Button,
  // Dialog,
  // DialogContent,
  Grid,
  Typography,
} from "@mui/material";
// import EditProfileModal from "./EditProfileModal";

const ViewProfileModal = view(() => {
  const state = store({
    editProfile: false,
    showErrors: false,
  });

  return (
    <Container>
      <Box
        sx={{
          marginTop: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
          <AccountCircleIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Profile
        </Typography>
      </Box>
      <Box
        component="form"
        sx={{
          mt: 3,
          display: "flex",
          // flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h6">First name</Typography>
            <Typography variant="body2">
              {authStore.loggedInUserInfo.first_name
                ? authStore.loggedInUserInfo.first_name
                : "No information on file"}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h6">Last name</Typography>
            <Typography variant="body2">
              {authStore.loggedInUserInfo.last_name
                ? authStore.loggedInUserInfo.last_name
                : "No information on file"}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2, // Added to help with spacing while "Edit Profile" button is hidden. Remove when "Edit Profile" button is displayed.
            }}
          >
            <Typography variant="h6">Email address</Typography>
            <Typography variant="body2">
              {authStore.loggedInUserInfo.email
                ? authStore.loggedInUserInfo.email
                : "No information on file"}
            </Typography>
          </Grid>
          {/* Hide ability to edit profile ahead of commercial release
          <Button
            fullWidth
            variant="contained"
            onClick={() => (state.editProfile = true)}
            sx={{ mt: 3, mb: 2 }}
          >
            Edit Profile
          </Button>
          <Dialog
            open={state.editProfile}
            onClose={() => (state.editProfile = false)}
          >
            <DialogContent>
              <EditProfileModal />
            </DialogContent>
          </Dialog> */}
        </Grid>
      </Box>
    </Container>
  );
});

export default ViewProfileModal;
