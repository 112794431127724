/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The PatchedUserRoleMembershipUpload model module.
 * @module model/PatchedUserRoleMembershipUpload
 * @version 0.0.0
 */
class PatchedUserRoleMembershipUpload {
    /**
     * Constructs a new <code>PatchedUserRoleMembershipUpload</code>.
     * @alias module:model/PatchedUserRoleMembershipUpload
     */
    constructor() { 
        
        PatchedUserRoleMembershipUpload.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>PatchedUserRoleMembershipUpload</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/PatchedUserRoleMembershipUpload} obj Optional instance to populate.
     * @return {module:model/PatchedUserRoleMembershipUpload} The populated <code>PatchedUserRoleMembershipUpload</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new PatchedUserRoleMembershipUpload();

            if (data.hasOwnProperty('user_id')) {
                obj['user_id'] = ApiClient.convertToType(data['user_id'], 'String');
            }
            if (data.hasOwnProperty('role_id')) {
                obj['role_id'] = ApiClient.convertToType(data['role_id'], 'String');
            }
        }
        return obj;
    }


}

/**
 * @member {String} user_id
 */
PatchedUserRoleMembershipUpload.prototype['user_id'] = undefined;

/**
 * @member {String} role_id
 */
PatchedUserRoleMembershipUpload.prototype['role_id'] = undefined;






export default PatchedUserRoleMembershipUpload;

