import { useParams } from "react-router-dom";
import { store, view } from "@risingstack/react-easy-state";
import browserHistory from "../../router";
import { 
  Alert,
  Box,
  Button,
  Container,
  Input,
  InputLabel, 
  Paper 
} from "@mui/material";
// import PasswordChangeForm from "../../api/client/src/model/PasswordChangeForm";
import { Logo } from "../../components/Logo";
import { authApi } from "../../api/clientInstances";
import PasswordResetConfirm from "../../api/client/src/model/PasswordResetConfirm";
import SupportEmail from "../../components/SupportEmail";

const ChangePassword = view(() => {
  const { uuid, token } = useParams();

  const state = store({
    newPassword: "",
    newPasswordConfirm: "",
    error: "",
  });

  const changePassword = (event) => {
    event.preventDefault();
    authApi.passwordResetConfirm(new PasswordResetConfirm(uuid, token, state.newPassword))
      .then(() => {
        browserHistory.push("/passwordresetconfirm");
      })
      .catch((err) => {
        console.log(err);
        state.error =
          "Please enter a valid password.";
      });
  };

  return (
    <Paper variant="background">
      <Container
        maxWidth="xs"
        variant="card"
      >
        <Logo />
        <Box sx={{display: "flex", justifyContent: "center", width: "100%", mb:"20px"}}>
          <form onSubmit={changePassword} style={{width:"100%"}}>
            <InputLabel htmlFor="newPassword" sx={{color:"text.primary", margin:"0", fontSize:"14px"}}>New Password</InputLabel>
            <Input
              fullWidth
              id="newPassword"
              name="newPassword"
              label=""
              type="password"
              value={state.newPassword}
              onChange={(e) => (state.newPassword = e.target.value)}
              sx={{ mt: '0px', mb: '25px' }}
              autoFocus={false}
            />
            <InputLabel htmlFor="newPasswordConfirm" sx={{color:"text.primary", margin:"0", fontSize:"14px"}}>Confirm New Password</InputLabel>
            <Input
              fullWidth
              id="newPasswordConfirm"
              name="newPasswordConfirm"
              label=""
              type="password"
              value={state.newPasswordConfirm}
              onChange={(e) => state.newPasswordConfirm = e.target.value}
              sx={{ mt: '0px', mb: '25px' }}
              autoFocus={false}
            />
            {state.error ? (
              <Alert severity="error" sx={{ mb: 2 }}>
                {state.error}
              </Alert>
            ) : null}
            <Button type="submit" variant="contained" fullWidth sx={{ mb: 2 }}>
              Change Password
            </Button>
          </form>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            "@media (min-width:576px)": {
              flexDirection: "row",
            },
          }}
        >
          <SupportEmail />
        </Box>
      </Container>
    </Paper>
  );
});

export default ChangePassword;
