import React from "react";
import * as d3 from "d3";
// eslint-disable-next-line no-unused-vars
import { XT1Scan } from "../../api/client/src";
import { autoEffect, store, view } from "@risingstack/react-easy-state";
import { CircularProgress, Typography, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import deviceStore from "../../store/deviceStore";

const TemperatureGraph = view(/** @param {{scans: XT1Scan[]}} */ ({ scans }) => {
  const theme = useTheme();

  /**
   * Typedef for local store
   * @type {{
   *  dataHasBeenPlotted: Boolean,
   *  containerRef: React.Ref,
   *  containerEl: d3.Selection,
   *  svgRef: React.Ref,
   *  svgEl: d3.Selection,
   *  clippingRect: d3.Selection,
   *  xAxis: d3.Axis,
   *  xScale: d3.ScaleTime,
   *  yScale: d3.ScaleLinear,
   *  zoomedXScale: d3.ScaleTime,
   *  zoom: d3.ZoomBehavior,
   *  line: d3.Line,
   *  width: number,
   *  height: number,
   *  margin: {
   *    top: number,
   *    bottom: number,
   *    left: number,
   *    right: number,
   *  }
   * }}
   */
  const state = store({
    dataHasBeenPlotted: false,
    containerRef: React.useRef(null),
    get containerEl() { return d3.select(state.containerRef.current) },
    svgRef: React.useRef(null),
    get svgEl() { return d3.select(state.svgRef.current) },
    xAxis: d3.axisBottom(),
    xScale: d3.scaleTime(),
    yScale: d3.scaleLinear(),
    zoomedXScale: d3.scaleTime(),
    zoom: d3.zoom()
      .on("zoom", function(event) {
        if (!state.dataHasBeenPlotted) return;
        const g = state.svgEl.select("g#plotContents");

        state.zoomedXScale = event.transform.rescaleX(state.xScale);
        // const [minDate, maxDate] = state.xScale.domain();
        // if (state.zoomedXScale.domain()[0] < minDate) {
        //     state.zoom.translateTo([100, 0]);
        // } else if (state.xScale.domain()[1] > maxDate) {
        //     const x = state.zoom.zoomTransform().x - state.xScale(maxDate) + state.xScale.range()[1];
        //     state.zoom.translateTo([x, 0]);
        // }
        g.select("g#xAxisGroup")
          .call(state.xAxis.scale(state.zoomedXScale));
        g.selectAll(".dataPath")
          .attr("d", (d) => state.line(d.temperatures));
        const doDisplayTimes = (state.zoomedXScale.domain()[1] - state.zoomedXScale.domain()[0]) / (1000 * 60 * 60 * 24) < 5;
        state.xAxis
          .tickFormat((val) => new Intl.DateTimeFormat(undefined, {dateStyle: "short", timeStyle: doDisplayTimes ? "short" : undefined}).format(val));
      }),
    line: d3.line(),

    width: 0,
    height: 0,
    margin: {
      top: 10,
      bottom: 40,
      left: 20,
      right: 20,
    },

    initGraphAndResize: function() {
      const {width, height} = state.containerEl.node().getBoundingClientRect();
      state.width = width;
      state.height = height;
  
      const svgEl = state.svgEl;
  
      let g = svgEl.selectAll("g#plotContents");
      g = g
        .data([1])
        .enter()
          .append("g")
          .attr("id", "plotContents")
        .merge(g)
          .attr("transform", `translate(${state.margin.left},${state.margin.top})`);
      
      const clippingRect = g
        .append("clipPath")
        .attr("id", "clippingRect")
        .append("rect")
        .attr("width", state.width - state.margin.left - state.margin.right)
        .attr("height", state.height - state.margin.top - state.margin.bottom)
        .attr("fill", "none");
  
      const xAxisGroup = g.selectAll("g#xAxisGroup");
      xAxisGroup
        .data([1])
        .enter()
          .append("g")
          .attr("id", "xAxisGroup")
        .merge(xAxisGroup)
          .attr("transform", `translate(0, ${state.height - state.margin.bottom - state.margin.top})`);
      
      // yAxisGroup
      g.selectAll("g#yAxisGroup")
        .data([1])
        .enter()
          .append("g")
          .attr("id", "yAxisGroup");
      
      // Behavior
      state.svgEl.call(state.zoom);
      
    },
    drawAxes: function() {

    },
    /**
     * 
     * @param {XT1Scan[]} scans
     */
    plotData: function(scans) {
      const allTemperatures = scans.flatMap(scan => scan.temperatures);
  
      state.xScale
        .domain(d3.extent(allTemperatures, (d) => d.timestamp))
        .range([0, state.width - state.margin.left - state.margin.right]);
      state.yScale
        .domain([
          d3.min(allTemperatures, (d) => d.temperature) - 2,
          d3.max(allTemperatures, (d) => d.temperature) + 2
        ])
        .range([state.height - state.margin.top - state.margin.bottom, 0]);

      state.zoomedXScale = state.xScale.copy();
  
      const svgEl = state.svgEl;
  
      const g = svgEl.select("g#plotContents");
  
      // Add X grid lines with labels
      const doDisplayTimes = (state.zoomedXScale.domain()[1] - state.zoomedXScale.domain()[0]) / (1000 * 60 * 60 * 24) < 5;
      state.xAxis
        .scale(state.xScale)
        .ticks(5)
        .tickSize(-state.height + state.margin.bottom + state.margin.top)
        .tickFormat((val) => new Intl.DateTimeFormat(undefined, {dateStyle: "short", timeStyle: doDisplayTimes ? "short" : undefined}).format(val));
      const xAxisGroup = g.select("g#xAxisGroup")
        .call(state.xAxis);
      xAxisGroup.select(".domain").remove();
      xAxisGroup.selectAll("line").attr("stroke", "gray");
      xAxisGroup.selectAll("text")
        .attr("opacity", 0.5)
        .attr("color", "black")
        .attr("font-size", "0.75rem");
      // Add Y grid lines with labels
      const yAxis = d3.axisLeft(state.yScale)
        .ticks(5)
        .tickSize(-state.width + state.margin.left + state.margin.right)
        .tickFormat((val) => `${val}`);
      const yAxisGroup = g.select("g#yAxisGroup")
        .call(yAxis);
      yAxisGroup.select(".domain").remove();
      yAxisGroup.selectAll("line").attr("stroke", "gray");
      yAxisGroup.selectAll("text")
        .attr("opacity", 0.5)
        .attr("color", "black")
        .attr("font-size", "0.75rem");
      
      // Draw the data path
      state.line
        .x((d) => state.zoomedXScale(d.timestamp))
        .y((d) => state.yScale(d.temperature));
      const path = g.selectAll(".dataPath");
      const pathData = path
        .data(scans);
      
      pathData
        .exit()
          .remove();
      pathData
        .enter()
          .append("path")
          .classed("dataPath", true)
          .attr("fill", "none")
          .attr("stroke", theme.palette.primary.light)
          .attr("stroke-width", 3)
        .merge(path)
          .attr("clip-path", "url(#clippingRect)")
          .attr("d", (d) => state.line(d.temperatures));
      
  
      // Draw the threshold lines
      const thresholdLines = g.selectAll(".thresholdLine");
      const thresholdLinesData = thresholdLines
        .data(scans.flatMap(scan => [scan.high_threshold, scan.low_threshold]));

      thresholdLinesData
        .exit()
          .remove();
      
      thresholdLinesData
        .enter()
          .append("line")
          .classed("thresholdLine", true)
          .attr("stroke", theme.palette.error.main)
          .attr("stroke-width", "2px")
        .merge(thresholdLines)
          .attr("stroke-dasharray", (d) => (Math.min(...state.yScale.range()) > state.yScale(d) || state.yScale(d) > Math.max(...state.yScale.range())) ? "10, 5" : "")
          .attr("x1", state.xScale.range()[0])
          .attr("x2", state.xScale.range()[1])
          .attr("y1", (d) => Math.max(Math.min(state.yScale(d), state.yScale.range()[0]), state.yScale.range()[1]))
          .attr("y2", (d) => Math.max(Math.min(state.yScale(d), state.yScale.range()[0]), state.yScale.range()[1]));

      state.dataHasBeenPlotted = true;
    }
  });

  autoEffect(() => {
    if (!state.svgEl.empty() && state.svgEl.attr("width") === "0") {
      state.initGraphAndResize();
    }
  });

  autoEffect(() => {
    if (scans && scans.length) {
      state.loading = true;
      setTimeout(() => {
        state.plotData(scans);
        state.loading = false;
      });
    }
  }, [scans]);

  return (
    <Box
      ref={state.containerRef}
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      { !scans || state.loading || deviceStore.scansLoading ? 
        <Box 
          sx={{
            height: state.height || null,
            width: state.width || null,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress size={80} sx={{marginBottom: 4}}/>
          <Typography component="h4" variant="subtitle">
            Loading Scan Data
          </Typography>
        </Box>
        : null
      }
      { !!scans ?
        <svg
          ref={state.svgRef}
          height={state.height}
          width={state.width}
          style={{
            display: (state.loading || deviceStore.scansLoading) ? "none" : null,
            maxWidth: "100%",
          }}
        />
        : null
      }
    </Box>
  )
});

export default TemperatureGraph;
