/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The PasswordChangeForm model module.
 * @module model/PasswordChangeForm
 * @version 0.0.0
 */
class PasswordChangeForm {
    /**
     * Constructs a new <code>PasswordChangeForm</code>.
     * @alias module:model/PasswordChangeForm
     * @param oldPassword {String} 
     * @param newPassword {String} 
     * @param newPasswordConfirm {String} 
     */
    constructor(oldPassword, newPassword, newPasswordConfirm) { 
        
        PasswordChangeForm.initialize(this, oldPassword, newPassword, newPasswordConfirm);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, oldPassword, newPassword, newPasswordConfirm) { 
        obj['old_password'] = oldPassword;
        obj['new_password'] = newPassword;
        obj['new_password_confirm'] = newPasswordConfirm;
    }

    /**
     * Constructs a <code>PasswordChangeForm</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/PasswordChangeForm} obj Optional instance to populate.
     * @return {module:model/PasswordChangeForm} The populated <code>PasswordChangeForm</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new PasswordChangeForm();

            if (data.hasOwnProperty('old_password')) {
                obj['old_password'] = ApiClient.convertToType(data['old_password'], 'String');
            }
            if (data.hasOwnProperty('new_password')) {
                obj['new_password'] = ApiClient.convertToType(data['new_password'], 'String');
            }
            if (data.hasOwnProperty('new_password_confirm')) {
                obj['new_password_confirm'] = ApiClient.convertToType(data['new_password_confirm'], 'String');
            }
        }
        return obj;
    }


}

/**
 * @member {String} old_password
 */
PasswordChangeForm.prototype['old_password'] = undefined;

/**
 * @member {String} new_password
 */
PasswordChangeForm.prototype['new_password'] = undefined;

/**
 * @member {String} new_password_confirm
 */
PasswordChangeForm.prototype['new_password_confirm'] = undefined;






export default PasswordChangeForm;

