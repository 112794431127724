import React from "react";
import {
  Box,
} from "@mui/material";

export const Logo = () => {
  return (
    <Box
      component="img"
      sx={{
        width: "275px",
        height: "auto",
        marginBottom: "8px"
      }}
      src="../xtcloud-lockup-color.png"
      alt="XTCloud logo"
    />
  )
}