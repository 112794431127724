import { autoEffect, store, view } from "@risingstack/react-easy-state";
import { useParams } from "react-router-dom";
import { Box, Container, Grid, } from "@mui/material";
import WidgetCard from "../components/WidgetCard";
import TemperatureGraph from "../components/single-record-widgets/TemperatureGraph";
import AlarmDetails from "../components/single-record-widgets/AlarmDetails";
import TripDetails from "../components/single-record-widgets/TripDetails";
// import PDFReports from "../components/single-record-widgets/PDFReports";
import ScanDetails from "../components/single-record-widgets/ScanDetails";
import TemperatureData from "../components/single-record-widgets/TemperatureData";
import PageTitle from "../components/PageTitle";
import { RecordSummary } from "../components/RecordSummary";
import { RecordBox, LeftBox, RightBox } from "../components/RecordComponents";
import deviceStore from "../store/deviceStore";
// eslint-disable-next-line no-unused-vars
import { XT1Record, XT1Scan } from "../api/client/src";
import CustomClient from "../api/CustomClient";

const Record = view(() => {
  const { record_id } = useParams();
  /**
   * @type {{
   *  record: XT1Record,
   * }}
   */
  const state = store({
    get record() {
      if (deviceStore.recordsLoading) {
        return null;
      }
      return deviceStore.records.find((rec) => rec.id === record_id);
    },
    scansForGraph: [],
    scansForDetails: [],
  });

  autoEffect(() => {
    deviceStore.getScans([record_id]).then((scans) => {
      setTimeout(() => {
        const scansCopy = CustomClient.convertToType(scans, [XT1Scan]);
        const scansCopy2 = CustomClient.convertToType(scans, [XT1Scan]);
        state.scansForGraph =
          scansCopy.length > 0 ? [scansCopy[scansCopy.length - 1]] : scansCopy;
        state.scansForDetails = scansCopy2;
      });
    });
  });

  return (
    <Container variant="grid">
      <Box>
        <PageTitle title="Record Details" single recordToDownload={state.record} />
        <RecordBox container>
          <LeftBox item xs={12} sm={12} md={3}>
            <RecordSummary
              record={state.record !== null ? state.record : false}
            />
          </LeftBox>
          <RightBox item xs={12} sm={12} md={9}>
            <WidgetCard
              title="Temperature Graph"
              sx={{ height: "500px", mb: "20px" }}
            >
              <TemperatureGraph scans={state.scansForGraph} />
            </WidgetCard>
            <Grid container spacing={2} pb={2}>
              <Grid item xs={12} sm={12} md={12}>
                <WidgetCard title="Alarm Details">
                  <AlarmDetails record={state.record} />
                </WidgetCard>
              </Grid>
            </Grid>
            <Grid container spacing={2} pb={2}>
              <Grid item xs={12} sm={4} md={6}>
                <Box mb={2}>
                  <WidgetCard title="Trip Details">
                    <TripDetails record={state.record} />
                  </WidgetCard>
                </Box>
                <WidgetCard title="Scan Details">
                  <ScanDetails scans={state.scansForDetails} />
                </WidgetCard>
              </Grid>
              <Grid item xs={12} sm={4} md={6}>
                <WidgetCard title="Temperature Data">
                  <TemperatureData scans={deviceStore.scans} loading={deviceStore.scansLoading}/>
                </WidgetCard>
              </Grid>
            </Grid>
            {/* PDFReport widget hidden ahead of commercial release */}
            {/* <Grid container spacing={2}>
              <Grid item xs={12} sm={4} md={4}>
                <WidgetCard title="PDF Reports">
                  <PDFReports record={state.record} />
                </WidgetCard>
              </Grid>
            </Grid> */}
          </RightBox>
        </RecordBox>
      </Box>
    </Container>
  );
});

export default Record;
