/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The ConfigureActionUpload model module.
 * @module model/ConfigureActionUpload
 * @version 0.0.0
 */
class ConfigureActionUpload {
    /**
     * Constructs a new <code>ConfigureActionUpload</code>.
     * @alias module:model/ConfigureActionUpload
     * @param tagId {Number} 
     * @param timestamp {Date} 
     * @param highThreshold {Number} In Degrees Celsius
     * @param lowThreshold {Number} In Degrees Celsius
     * @param measurementInterval {Number} In minutes
     * @param startDelay {Number} In minutes
     */
    constructor(tagId, timestamp, highThreshold, lowThreshold, measurementInterval, startDelay) { 
        
        ConfigureActionUpload.initialize(this, tagId, timestamp, highThreshold, lowThreshold, measurementInterval, startDelay);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, tagId, timestamp, highThreshold, lowThreshold, measurementInterval, startDelay) { 
        obj['tag_id'] = tagId;
        obj['timestamp'] = timestamp;
        obj['high_threshold'] = highThreshold;
        obj['low_threshold'] = lowThreshold;
        obj['measurement_interval'] = measurementInterval;
        obj['start_delay'] = startDelay;
    }

    /**
     * Constructs a <code>ConfigureActionUpload</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/ConfigureActionUpload} obj Optional instance to populate.
     * @return {module:model/ConfigureActionUpload} The populated <code>ConfigureActionUpload</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new ConfigureActionUpload();

            if (data.hasOwnProperty('tag_id')) {
                obj['tag_id'] = ApiClient.convertToType(data['tag_id'], 'Number');
            }
            if (data.hasOwnProperty('timestamp')) {
                obj['timestamp'] = ApiClient.convertToType(data['timestamp'], 'Date');
            }
            if (data.hasOwnProperty('primary_reference')) {
                obj['primary_reference'] = ApiClient.convertToType(data['primary_reference'], 'String');
            }
            if (data.hasOwnProperty('secondary_reference')) {
                obj['secondary_reference'] = ApiClient.convertToType(data['secondary_reference'], 'String');
            }
            if (data.hasOwnProperty('shipping_reference')) {
                obj['shipping_reference'] = ApiClient.convertToType(data['shipping_reference'], 'String');
            }
            if (data.hasOwnProperty('latitude')) {
                obj['latitude'] = ApiClient.convertToType(data['latitude'], 'Number');
            }
            if (data.hasOwnProperty('longitude')) {
                obj['longitude'] = ApiClient.convertToType(data['longitude'], 'Number');
            }
            if (data.hasOwnProperty('header_data')) {
                obj['header_data'] = ApiClient.convertToType(data['header_data'], 'String');
            }
            if (data.hasOwnProperty('high_threshold')) {
                obj['high_threshold'] = ApiClient.convertToType(data['high_threshold'], 'Number');
            }
            if (data.hasOwnProperty('low_threshold')) {
                obj['low_threshold'] = ApiClient.convertToType(data['low_threshold'], 'Number');
            }
            if (data.hasOwnProperty('measurement_interval')) {
                obj['measurement_interval'] = ApiClient.convertToType(data['measurement_interval'], 'Number');
            }
            if (data.hasOwnProperty('start_delay')) {
                obj['start_delay'] = ApiClient.convertToType(data['start_delay'], 'Number');
            }
            if (data.hasOwnProperty('num_consecutive_excursions')) {
                obj['num_consecutive_excursions'] = ApiClient.convertToType(data['num_consecutive_excursions'], 'Number');
            }
        }
        return obj;
    }


}

/**
 * @member {Number} tag_id
 */
ConfigureActionUpload.prototype['tag_id'] = undefined;

/**
 * @member {Date} timestamp
 */
ConfigureActionUpload.prototype['timestamp'] = undefined;

/**
 * @member {String} primary_reference
 * @default ''
 */
ConfigureActionUpload.prototype['primary_reference'] = '';

/**
 * @member {String} secondary_reference
 * @default ''
 */
ConfigureActionUpload.prototype['secondary_reference'] = '';

/**
 * @member {String} shipping_reference
 * @default ''
 */
ConfigureActionUpload.prototype['shipping_reference'] = '';

/**
 * @member {Number} latitude
 * @default '-91.0000000000000000'
 */
ConfigureActionUpload.prototype['latitude'] = '-91.0000000000000000';

/**
 * @member {Number} longitude
 * @default '-181.0000000000000000'
 */
ConfigureActionUpload.prototype['longitude'] = '-181.0000000000000000';

/**
 * @member {String} header_data
 */
ConfigureActionUpload.prototype['header_data'] = undefined;

/**
 * In Degrees Celsius
 * @member {Number} high_threshold
 */
ConfigureActionUpload.prototype['high_threshold'] = undefined;

/**
 * In Degrees Celsius
 * @member {Number} low_threshold
 */
ConfigureActionUpload.prototype['low_threshold'] = undefined;

/**
 * In minutes
 * @member {Number} measurement_interval
 */
ConfigureActionUpload.prototype['measurement_interval'] = undefined;

/**
 * In minutes
 * @member {Number} start_delay
 */
ConfigureActionUpload.prototype['start_delay'] = undefined;

/**
 * Number of consecutive temperature points outside thresholds before an alarm is triggered
 * @member {Number} num_consecutive_excursions
 * @default 1
 */
ConfigureActionUpload.prototype['num_consecutive_excursions'] = 1;






export default ConfigureActionUpload;

