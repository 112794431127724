import { view } from "@risingstack/react-easy-state";
import { Box, Grid } from "@mui/material";
import { GridToolbar } from "@mui/x-data-grid-premium";
import CircularProgress from '@mui/material/CircularProgress';
import RecordGridPremium from "../RecordGridPremium";
import { styleDate } from "../../utils/utilFunctions";

const columns = [
  {
    field: "timestamp",
    headerName: "Time",
    valueFormatter: ({value}) => {
      return styleDate(value, "No data");
    },
    flex: 1,
  },
  {
    field: "temperature",
    headerName: "Temperature (°C)",
    flex: 1,
  },
];

const TemperatureData = view(({ scans, loading }) => {
  if (!loading) {
    return (
      <Box
        sx={{
          height: 623,
          pb: "20px"
        }}
      >
        <RecordGridPremium
          getRowId={(row) => String(row.timestamp)}
          components={{ Toolbar: GridToolbar }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
          disableDensitySelector
          rows={[...new Map(scans.flatMap(scan => scan.temperatures).map(temp => [temp.timestamp.toJSON(), temp])).values()]}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          disableSelectionOnClick
        />
      </Box>
    );
  } else {
    return <Grid container sx={{height: 400, display: 'flex', alignItems:'center', justifyContent:'center'}}><CircularProgress/></Grid>;
  }
});

export default TemperatureData;
