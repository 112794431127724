/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
/**
* Enum class ClientTypeEnum.
* @enum {}
* @readonly
*/
export default class ClientTypeEnum {
    
        /**
         * value: "WEB"
         * @const
         */
        "WEB" = "WEB";

    
        /**
         * value: "IOS"
         * @const
         */
        "IOS" = "IOS";

    
        /**
         * value: "ANDROID"
         * @const
         */
        "ANDROID" = "ANDROID";

    

    /**
    * Returns a <code>ClientTypeEnum</code> enum value from a Javascript object name.
    * @param {Object} data The plain JavaScript object containing the name of the enum value.
    * @return {module:model/ClientTypeEnum} The enum <code>ClientTypeEnum</code> value.
    */
    static constructFromObject(object) {
        return object;
    }
}

