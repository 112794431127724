import React from "react";
import { Typography, Button} from "@mui/material";
// import {Link} from "react-router-dom";
import { styled } from "@mui/material/styles";
import FiberSmartRecordIcon from "@mui/icons-material/FiberSmartRecord";

const RecordItem = styled("div")(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  width: '100%',
  padding: '20px 0 15px', 
  borderBottom:'1px solid #DEE2E6',
  flexDirection: 'column',
  [theme.breakpoints.up("sm")]: {
    flexDirection: 'row'
  },

}));
const Description = styled("div")(({ theme }) => ({
  '& .MuiButton-root': {
    border: 'none'
  },
  '& .MuiTypography-root': {
    color: '#1B1D20'
  }
}));
const RecordTitle = styled("div")(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  width: '100%',
  maxWidth: '250px',
  justifyContent: 'initial'
}));
const SummaryBox = styled("div")(({theme}) => ({
  minWidth: 'initial',
  marginTop: '20px',
  paddingLeft: '37px',
  width: '100%',
  maxWidth: '100%',
  [theme.breakpoints.up("sm")]: {
    paddingLeft: 'initial',
    marginTop: 'initial',
    maxWidth: '300px',
  },
}))
const Summary = styled("div")(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  width: '100%',
}));

const RecordSearchItem = ({result}) => {
  return (
    <RecordItem>
      <RecordTitle>
        <FiberSmartRecordIcon sx={{ fontSize: 24, mr: '13px', color:'#B6B9BF' }} />
        <Description>
          <Button href={`/recorddetail/${result.id}`} variant={"link"} sx={{lineHeight: "1.5"}}>Record ID: {result.id}</Button>
          <Typography variant="body1">Tag ID: {result.tag_id}</Typography>
        </Description>
      </RecordTitle>
      <SummaryBox>
        <Summary>
          <Typography variant={"body2"} fontWeight="bold">High threshold</Typography>
          <Typography variant={"detail"}>{result.high_threshold !== null ? result.high_threshold + "°C": "No data available"}</Typography>
        </Summary>
        <Summary>
          <Typography variant={"body2"} fontWeight="bold">Low threshold</Typography>
          <Typography variant={"detail"}>{result.high_threshold !== null ? result.low_threshold + "°C": "No data available"}</Typography>
        </Summary>
        <Summary>
          <Typography variant={"body2"} fontWeight="bold">Measurement Interval</Typography>
          <Typography variant={"detail"}>{result.high_threshold !== null ? result.measurement_interval + "mins": "No data available"}</Typography>
        </Summary>
        <Summary>
          <Typography variant={"body2"} fontWeight="bold">Start delay</Typography>
          <Typography variant={"detail"}>{result.high_threshold !== null ? result.start_delay + "mins": "No data available"}</Typography>
        </Summary>
      </SummaryBox>
    </RecordItem>
  )
}

export default RecordSearchItem