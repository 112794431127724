import debounce from "lodash.debounce";
import ApiClient from "./client/src/ApiClient";
import browserHistory from "../router/index";
import { haltPromiseChain } from "./StopPromise";

class CustomClient extends ApiClient {
  constructor(basePath) {
    super(basePath);
    this.enableCookies = true;
  }

  _reroute = debounce(
    () => {
      console.log("push");
      browserHistory.push("/login");
    },
    300,
    { leading: true, trailing: false }
  );

  _callApi(
    path,
    httpMethod,
    pathParams,
    queryParams,
    headerParams,
    formParams,
    bodyParam,
    authNames,
    contentTypes,
    accepts,
    returnType,
    apiBasePath
  ) {
    return super
      .callApi(
        path,
        httpMethod,
        pathParams,
        queryParams,
        headerParams,
        formParams,
        bodyParam,
        authNames,
        contentTypes,
        accepts,
        returnType,
        apiBasePath
      )
      .catch((err) => {
        console.log(browserHistory.location.pathname);
        if (err.error && !err.error.status) {
          return haltPromiseChain();
        }

        if (
          !["login", "forgotpassword", "forgotpasswordconfirm", "passwordreset", "passwordresetconfirm"].includes(browserHistory.location.pathname.split("/")[1]) &&
          (!err.response || err.status === 401)
        ) {
          this._reroute();
          return haltPromiseChain();
        }
        throw err;
      });
  }

  callApi(
    path,
    httpMethod,
    pathParams,
    queryParams,
    headerParams,
    formParams,
    bodyParam,
    authNames,
    contentTypes,
    accepts,
    returnType,
    apiBasePath
  ) {
    // if (!authStore.csrfToken) {
    //   if (!path.includes("/csrf")) {
    //     return authStore.getCSRF().then(() => {
    //       return this.callApi(path, httpMethod, pathParams, queryParams, headerParams, formParams, bodyParam, authNames, contentTypes, accepts, returnType, apiBasePath);
    //     });
    //   }
    // }
    // headerParams["X-CSRFToken"] = authStore.csrfToken;
    return this._callApi(
      path,
      httpMethod,
      pathParams,
      queryParams,
      headerParams,
      formParams,
      bodyParam,
      authNames,
      contentTypes,
      accepts,
      returnType,
      apiBasePath
    );
  }
}

export default CustomClient;
