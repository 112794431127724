/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
import ClientTypeEnum from './ClientTypeEnum';

/**
 * The AlarmDismissalUpload model module.
 * @module model/AlarmDismissalUpload
 * @version 0.0.0
 */
class AlarmDismissalUpload {
    /**
     * Constructs a new <code>AlarmDismissalUpload</code>.
     * @alias module:model/AlarmDismissalUpload
     * @param alarmId {String} 
     * @param clientType {module:model/ClientTypeEnum} 
     */
    constructor(alarmId, clientType) { 
        
        AlarmDismissalUpload.initialize(this, alarmId, clientType);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, alarmId, clientType) { 
        obj['alarm_id'] = alarmId;
        obj['client_type'] = clientType;
    }

    /**
     * Constructs a <code>AlarmDismissalUpload</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/AlarmDismissalUpload} obj Optional instance to populate.
     * @return {module:model/AlarmDismissalUpload} The populated <code>AlarmDismissalUpload</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new AlarmDismissalUpload();

            if (data.hasOwnProperty('alarm_id')) {
                obj['alarm_id'] = ApiClient.convertToType(data['alarm_id'], 'String');
            }
            if (data.hasOwnProperty('timestamp')) {
                obj['timestamp'] = ApiClient.convertToType(data['timestamp'], 'Date');
            }
            if (data.hasOwnProperty('client_type')) {
                obj['client_type'] = ClientTypeEnum.constructFromObject(data['client_type']);
            }
        }
        return obj;
    }


}

/**
 * @member {String} alarm_id
 */
AlarmDismissalUpload.prototype['alarm_id'] = undefined;

/**
 * @member {Date} timestamp
 */
AlarmDismissalUpload.prototype['timestamp'] = undefined;

/**
 * @member {module:model/ClientTypeEnum} client_type
 */
AlarmDismissalUpload.prototype['client_type'] = undefined;






export default AlarmDismissalUpload;

