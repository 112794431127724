/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The UserRegisterForm model module.
 * @module model/UserRegisterForm
 * @version 0.0.0
 */
class UserRegisterForm {
    /**
     * Constructs a new <code>UserRegisterForm</code>.
     * @alias module:model/UserRegisterForm
     * @param username {String} 
     * @param password {String} 
     * @param firstName {String} 
     * @param lastName {String} 
     * @param email {String} 
     * @param organizationId {String} 
     * @param roleId {String} 
     */
    constructor(username, password, firstName, lastName, email, organizationId, roleId) { 
        
        UserRegisterForm.initialize(this, username, password, firstName, lastName, email, organizationId, roleId);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, username, password, firstName, lastName, email, organizationId, roleId) { 
        obj['username'] = username;
        obj['password'] = password;
        obj['first_name'] = firstName;
        obj['last_name'] = lastName;
        obj['email'] = email;
        obj['organization_id'] = organizationId;
        obj['role_id'] = roleId;
    }

    /**
     * Constructs a <code>UserRegisterForm</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/UserRegisterForm} obj Optional instance to populate.
     * @return {module:model/UserRegisterForm} The populated <code>UserRegisterForm</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new UserRegisterForm();

            if (data.hasOwnProperty('username')) {
                obj['username'] = ApiClient.convertToType(data['username'], 'String');
            }
            if (data.hasOwnProperty('password')) {
                obj['password'] = ApiClient.convertToType(data['password'], 'String');
            }
            if (data.hasOwnProperty('first_name')) {
                obj['first_name'] = ApiClient.convertToType(data['first_name'], 'String');
            }
            if (data.hasOwnProperty('last_name')) {
                obj['last_name'] = ApiClient.convertToType(data['last_name'], 'String');
            }
            if (data.hasOwnProperty('email')) {
                obj['email'] = ApiClient.convertToType(data['email'], 'String');
            }
            if (data.hasOwnProperty('organization_id')) {
                obj['organization_id'] = ApiClient.convertToType(data['organization_id'], 'String');
            }
            if (data.hasOwnProperty('role_id')) {
                obj['role_id'] = ApiClient.convertToType(data['role_id'], 'String');
            }
        }
        return obj;
    }


}

/**
 * @member {String} username
 */
UserRegisterForm.prototype['username'] = undefined;

/**
 * @member {String} password
 */
UserRegisterForm.prototype['password'] = undefined;

/**
 * @member {String} first_name
 */
UserRegisterForm.prototype['first_name'] = undefined;

/**
 * @member {String} last_name
 */
UserRegisterForm.prototype['last_name'] = undefined;

/**
 * @member {String} email
 */
UserRegisterForm.prototype['email'] = undefined;

/**
 * @member {String} organization_id
 */
UserRegisterForm.prototype['organization_id'] = undefined;

/**
 * @member {String} role_id
 */
UserRegisterForm.prototype['role_id'] = undefined;






export default UserRegisterForm;

