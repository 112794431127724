/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The StopActionUpload model module.
 * @module model/StopActionUpload
 * @version 0.0.0
 */
class StopActionUpload {
    /**
     * Constructs a new <code>StopActionUpload</code>.
     * @alias module:model/StopActionUpload
     * @param tagId {Number} 
     * @param timestamp {Date} 
     * @param startTimeSeconds {Number} Seconds since custom epoch
     */
    constructor(tagId, timestamp, startTimeSeconds) { 
        
        StopActionUpload.initialize(this, tagId, timestamp, startTimeSeconds);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, tagId, timestamp, startTimeSeconds) { 
        obj['tag_id'] = tagId;
        obj['timestamp'] = timestamp;
        obj['start_time_seconds'] = startTimeSeconds;
    }

    /**
     * Constructs a <code>StopActionUpload</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/StopActionUpload} obj Optional instance to populate.
     * @return {module:model/StopActionUpload} The populated <code>StopActionUpload</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new StopActionUpload();

            if (data.hasOwnProperty('tag_id')) {
                obj['tag_id'] = ApiClient.convertToType(data['tag_id'], 'Number');
            }
            if (data.hasOwnProperty('timestamp')) {
                obj['timestamp'] = ApiClient.convertToType(data['timestamp'], 'Date');
            }
            if (data.hasOwnProperty('primary_reference')) {
                obj['primary_reference'] = ApiClient.convertToType(data['primary_reference'], 'String');
            }
            if (data.hasOwnProperty('secondary_reference')) {
                obj['secondary_reference'] = ApiClient.convertToType(data['secondary_reference'], 'String');
            }
            if (data.hasOwnProperty('shipping_reference')) {
                obj['shipping_reference'] = ApiClient.convertToType(data['shipping_reference'], 'String');
            }
            if (data.hasOwnProperty('latitude')) {
                obj['latitude'] = ApiClient.convertToType(data['latitude'], 'Number');
            }
            if (data.hasOwnProperty('longitude')) {
                obj['longitude'] = ApiClient.convertToType(data['longitude'], 'Number');
            }
            if (data.hasOwnProperty('header_data')) {
                obj['header_data'] = ApiClient.convertToType(data['header_data'], 'String');
            }
            if (data.hasOwnProperty('start_time_seconds')) {
                obj['start_time_seconds'] = ApiClient.convertToType(data['start_time_seconds'], 'Number');
            }
        }
        return obj;
    }


}

/**
 * @member {Number} tag_id
 */
StopActionUpload.prototype['tag_id'] = undefined;

/**
 * @member {Date} timestamp
 */
StopActionUpload.prototype['timestamp'] = undefined;

/**
 * @member {String} primary_reference
 * @default ''
 */
StopActionUpload.prototype['primary_reference'] = '';

/**
 * @member {String} secondary_reference
 * @default ''
 */
StopActionUpload.prototype['secondary_reference'] = '';

/**
 * @member {String} shipping_reference
 * @default ''
 */
StopActionUpload.prototype['shipping_reference'] = '';

/**
 * @member {Number} latitude
 * @default '-91.0000000000000000'
 */
StopActionUpload.prototype['latitude'] = '-91.0000000000000000';

/**
 * @member {Number} longitude
 * @default '-181.0000000000000000'
 */
StopActionUpload.prototype['longitude'] = '-181.0000000000000000';

/**
 * @member {String} header_data
 */
StopActionUpload.prototype['header_data'] = undefined;

/**
 * Seconds since custom epoch
 * @member {Number} start_time_seconds
 */
StopActionUpload.prototype['start_time_seconds'] = undefined;






export default StopActionUpload;

