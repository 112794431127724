/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';
/**
* Enum class SitePermissionChoices.
* @enum {}
* @readonly
*/
export default class SitePermissionChoices {
    
        /**
         * value: "sysAdminOrgInfo"
         * @const
         */
        "sysAdminOrgInfo" = "sysAdminOrgInfo";

    
        /**
         * value: "sysAdminUser"
         * @const
         */
        "sysAdminUser" = "sysAdminUser";

    
        /**
         * value: "sysAdminLoc"
         * @const
         */
        "sysAdminLoc" = "sysAdminLoc";

    
        /**
         * value: "sysOrderPlace"
         * @const
         */
        "sysOrderPlace" = "sysOrderPlace";

    
        /**
         * value: "sysOrderNotify"
         * @const
         */
        "sysOrderNotify" = "sysOrderNotify";

    
        /**
         * value: "sysDataRead"
         * @const
         */
        "sysDataRead" = "sysDataRead";

    
        /**
         * value: "sysReportView"
         * @const
         */
        "sysReportView" = "sysReportView";

    
        /**
         * value: "sysReportCreate"
         * @const
         */
        "sysReportCreate" = "sysReportCreate";

    
        /**
         * value: "sysReportEdit"
         * @const
         */
        "sysReportEdit" = "sysReportEdit";

    
        /**
         * value: "sysReportDelete"
         * @const
         */
        "sysReportDelete" = "sysReportDelete";

    
        /**
         * value: "sysConfigCreate"
         * @const
         */
        "sysConfigCreate" = "sysConfigCreate";

    
        /**
         * value: "sysConfigEdit"
         * @const
         */
        "sysConfigEdit" = "sysConfigEdit";

    
        /**
         * value: "sysConfigUse"
         * @const
         */
        "sysConfigUse" = "sysConfigUse";

    
        /**
         * value: "sysConfigDelete"
         * @const
         */
        "sysConfigDelete" = "sysConfigDelete";

    
        /**
         * value: "notifyStart"
         * @const
         */
        "notifyStart" = "notifyStart";

    
        /**
         * value: "notifyReceive"
         * @const
         */
        "notifyReceive" = "notifyReceive";

    
        /**
         * value: "notifyAll"
         * @const
         */
        "notifyAll" = "notifyAll";

    
        /**
         * value: "shareAll"
         * @const
         */
        "shareAll" = "shareAll";

    
        /**
         * value: "shareLink"
         * @const
         */
        "shareLink" = "shareLink";

    
        /**
         * value: "readerConfigCustom"
         * @const
         */
        "readerConfigCustom" = "readerConfigCustom";

    
        /**
         * value: "readerConfig"
         * @const
         */
        "readerConfig" = "readerConfig";

    
        /**
         * value: "readerStart"
         * @const
         */
        "readerStart" = "readerStart";

    
        /**
         * value: "readerStop"
         * @const
         */
        "readerStop" = "readerStop";

    
        /**
         * value: "readerScan"
         * @const
         */
        "readerScan" = "readerScan";

    
        /**
         * value: "readerEditRef1"
         * @const
         */
        "readerEditRef1" = "readerEditRef1";

    
        /**
         * value: "readerEditRef2"
         * @const
         */
        "readerEditRef2" = "readerEditRef2";

    
        /**
         * value: "readerEditRefShip"
         * @const
         */
        "readerEditRefShip" = "readerEditRefShip";

    

    /**
    * Returns a <code>SitePermissionChoices</code> enum value from a Javascript object name.
    * @param {Object} data The plain JavaScript object containing the name of the enum value.
    * @return {module:model/SitePermissionChoices} The enum <code>SitePermissionChoices</code> value.
    */
    static constructFromObject(object) {
        return object;
    }
}

