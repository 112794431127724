import { view } from "@risingstack/react-easy-state";
import browserHistory from "../router";
import { useLocation } from "react-router-dom";
import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Link,
} from "@mui/material";
import GridViewSharpIcon from "@mui/icons-material/GridViewSharp";
import FiberSmartRecordIcon from "@mui/icons-material/FiberSmartRecord";
import SettingsMenu from "./settings/SettingsMenu";

const menuItems = [
  {
    icon: <GridViewSharpIcon sx={{ fontSize: 35 }} />,
    title: "Dashboard",
    to: "/",
    onClick: () => browserHistory.push("/"),
  },
  {
    icon: <FiberSmartRecordIcon sx={{ fontSize: 35 }} />,
    title: "All Records",
    to: "/allrecords",
    onClick: () => browserHistory.push("/allrecords"),
  },
];

const SideNav = view(() => {
  const location = useLocation();

  const drawerWidth = 102;

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          border: 0,
          top: "initial",
          bottom: 0,
          height: "auto",
          width: "100%",
          boxSizing: "border-box",
          backgroundColor: (theme) => theme.palette.primary.main,
          padding: "0",
          "@media (min-width:768px)": {
            top: 0,
            bottom: "initial",
            height: "100%",
            width: drawerWidth,
            backgroundColor: (theme) => theme.palette.primary.main,
            padding: "17px 0",
          },
        },
      }}
    >
      <Link
        href="/"
        textAlign="center"
        mb="51px"
        sx={{
          display: "none",
          "@media (min-width:768px)": {
            display: "block",
          },
          "&:active": { background: "initial" },
        }}
      >
        <img
          src={`${process.env.PUBLIC_URL}/logo.png`}
          alt="XTCloud logo"
          height={"32px"}
          width={"50px"}
        />
      </Link>
      <Box
        sx={{
          height: "auto",
          "@media (min-width:768px)": {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
            overflow: "auto",
            height: "100%",
          },
        }}
      >
        <List
          sx={{
            display: "flex",
            width: "60%",
            margin: "auto",
            justifyContent: "space-around",
            padding: 0,
            "@media (min-width:768px)": {
              margin: "initial",
              display: "initial",
              width: "initial",
            },
          }}
        >
          {menuItems.map((menuItem) => {
            const isMatch = location.pathname === menuItem.to ? true : false;
            return (
              <ListItem
                key={menuItem.title}
                disablePadding
                sx={{
                  mb: "initial",
                  "@media (min-width:768px)": {
                    display: "block",
                  },
                }}
              >
                <ListItemButton
                  variant={isMatch ? "custom-match" : "custom"}
                  onClick={menuItem.onClick}
                  sx={{ display: "flex", flexDirection: "column", p: "0" }}
                >
                  <ListItemIcon
                    variant={isMatch ? "side-nav-custom-match" : "side-nav-custom"}
                    sx={{ mt: "32px" }}
                  >
                    {menuItem.icon}
                  </ListItemIcon>
                  <ListItemText
                    primaryTypographyProps={{
                      mb: "25px",
                      variant: "body2",
                      color: isMatch ? `white` : "#D5D7DF",
                    }}
                    primary={menuItem.title}
                  />
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
        <SettingsMenu />
      </Box>
    </Drawer>
  );
});

export default SideNav;
