import { Container, Box } from "@mui/material";
import AllRecordsDataGrid from "../components/AllRecordsDataGrid";
import PageTitle from "../components/PageTitle";

const AllRecords = () => {
  return (
    <Container variant="grid">
      <Box>
        <PageTitle title="All Records"/>
        <AllRecordsDataGrid />
      </Box>
    </Container>
  );
};

export default AllRecords;
