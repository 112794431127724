import { store, view } from "@risingstack/react-easy-state";
import authStore from "../../store/authStore";
import {
  Dialog,
  DialogContent,
  Divider,
  Tooltip,
  Menu,
  IconButton,
  MenuItem,
  Typography,
} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import ViewProfileModal from "../../modals/ViewProfileModal";
import ChangePasswordModal from "../../modals/admin/ChangePasswordModal";
// import AddUserModal from "../../modals/admin/AddUserModal";

const SettingsMenu = view(() => {
  const state = store({
    anchorEl: null,
    userSettings: [
      {
        title: "View Profile",
        open: false,
        component: <ViewProfileModal />,
      },
      {
        title: "Change Password",
        open: false,
        component: <ChangePasswordModal />,
      },
    ],
    // adminSettings: [
    //   {
    //     title: "Add User",
    //     open: false,
    //     component: <AddUserModal />,
    //   },
    // ],
    logout: [
      {
        title: "Log Out",
        onClick: authStore.logout,
      },
    ],
  });

  const handleOpenMenu = (event) => {
    state.anchorEl = event.currentTarget;
  };

  const handleCloseMenu = () => {
    state.anchorEl = null;
  };

  return (
    <>
      <Tooltip title="View settings">
        <IconButton
          onClick={handleOpenMenu}
          aria-label="menu"
          sx={{
            display: "none",
            "@media (min-width:768px)": {
              display: "block",
            },
          }}
        >
          <SettingsIcon
            fontSize="large"
            sx={{
              color: "common.white",
            }}
          />
        </IconButton>
      </Tooltip>
      <Menu
        sx={{ mt: "-45px" }}
        id="menu-appbar"
        anchorEl={state.anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={Boolean(state.anchorEl)}
        onClose={handleCloseMenu}
      >
        {state.userSettings.map((userMenuItem) => (
          <MenuItem key={userMenuItem.title} onClick={handleCloseMenu}>
            <Typography
              onClick={() => (userMenuItem.open = true)}
              textAlign="center"
            >
              {userMenuItem.title}
            </Typography>
            <Dialog
              open={userMenuItem.open}
              onClose={() => (userMenuItem.open = false)}
            >
              <DialogContent>{userMenuItem.component}</DialogContent>
            </Dialog>
          </MenuItem>
        ))}
        <Divider />
        {/* {state.adminSettings.map((adminMenuItem) => (
          <MenuItem key={adminMenuItem.title} onClick={handleCloseMenu}>
            <Typography
              onClick={() => (adminMenuItem.open = true)}
              textAlign="center"
            >
              {adminMenuItem.title}
            </Typography>
            <Dialog
              open={adminMenuItem.open}
              onClose={() => (adminMenuItem.open = false)}
            >
              <DialogContent>{adminMenuItem.component}</DialogContent>
            </Dialog>
          </MenuItem>
        ))}
        <Divider /> */}
        {state.logout.map((logoutMenuItem) => (
          <MenuItem key={logoutMenuItem.title} onClick={handleCloseMenu}>
            <Typography onClick={logoutMenuItem.onClick} textAlign="center">
              {logoutMenuItem.title}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
});

export default SettingsMenu;
