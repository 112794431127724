/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The RoleMembership model module.
 * @module model/RoleMembership
 * @version 0.0.0
 */
class RoleMembership {
    /**
     * Constructs a new <code>RoleMembership</code>.
     * @alias module:model/RoleMembership
     * @param id {String} 
     * @param roleId {String} 
     * @param roleName {String} 
     * @param organizationId {String} 
     */
    constructor(id, roleId, roleName, organizationId) { 
        
        RoleMembership.initialize(this, id, roleId, roleName, organizationId);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj, id, roleId, roleName, organizationId) { 
        obj['id'] = id;
        obj['role_id'] = roleId;
        obj['role_name'] = roleName;
        obj['organization_id'] = organizationId;
    }

    /**
     * Constructs a <code>RoleMembership</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/RoleMembership} obj Optional instance to populate.
     * @return {module:model/RoleMembership} The populated <code>RoleMembership</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new RoleMembership();

            if (data.hasOwnProperty('id')) {
                obj['id'] = ApiClient.convertToType(data['id'], 'String');
            }
            if (data.hasOwnProperty('role_id')) {
                obj['role_id'] = ApiClient.convertToType(data['role_id'], 'String');
            }
            if (data.hasOwnProperty('role_name')) {
                obj['role_name'] = ApiClient.convertToType(data['role_name'], 'String');
            }
            if (data.hasOwnProperty('organization_id')) {
                obj['organization_id'] = ApiClient.convertToType(data['organization_id'], 'String');
            }
        }
        return obj;
    }


}

/**
 * @member {String} id
 */
RoleMembership.prototype['id'] = undefined;

/**
 * @member {String} role_id
 */
RoleMembership.prototype['role_id'] = undefined;

/**
 * @member {String} role_name
 */
RoleMembership.prototype['role_name'] = undefined;

/**
 * @member {String} organization_id
 */
RoleMembership.prototype['organization_id'] = undefined;






export default RoleMembership;

