import { DataGridPremium } from "@mui/x-data-grid-premium";
import { styled } from "@mui/material/styles";

const RecordGridPremium = styled(DataGridPremium)(({theme, type}) => ({
  border: 'none',
  '& .css-n3z9fz-MuiPopper-root-MuiDataGrid-panel': {
    position: 'relative'
  },
  '& .MuiDataGrid-columnHeaderTitle': {
    textTransform: 'Uppercase',
    fontSize: '12px',
    color: '#797B80'
  },
  '& .MuiDataGrid-columnHeadersInner': {
    width: '100%',
    justifyContent: 'flex-start'
  },
  // Commented out because hiding the column separator prevents users from being able to resize columns
  // '& .MuiDataGrid-columnSeparator': {
  //   display: 'none'
  // },
  // Commented out to allow users to see which column data are being sorted by
  // '& .MuiDataGrid-iconButtonContainer': {
  //   display: 'none'
  // },
  '& .MuiDataGrid-virtualScrollerRenderZone': {
    width: '100%',
    '& .MuiDataGrid-row': {
      width: '100%'
    }
  },
  '& .MuiTablePagination-displayedRows': {
    margin: 0,
  },
  '& .MuiTablePagination-actions': {
    '& .MuiIconButton-root': {
      border: '1px solid #CED4DA',
      borderRadius: 'initial',
      marginLeft: '10px'
    }
  },
  // Style toolbar
  '& .MuiDataGrid-toolbarContainer': {
    '& .css-3be3ve-MuiFormControl-root-MuiTextField-root-MuiDataGrid-toolbarQuickFilter': {
      // order: '-1'
    },
    // Style search bar
    '& .css-etj7cd-MuiInputBase-root-MuiInput-root': {
      height: '40px',
      borderRadius: '2px',
      fontSize: '14px'
    },
    '& .MuiButton-root': {
      height: '40px',
      border: '1px solid #CED4DA',
      color: '#797B80',
      paddingLeft: '12px',
      paddingRight: '40px',
      marginRight: '8px',
      position: 'relative',
      borderRadius: '2px',
      transition: 'all 0.3s ease-in-out',
      fontSize: '14px',
      fontWeight: 'normal',
      textTransform: 'capitalize',
      justifyContent: 'left',
      // order:'1',
      '&:hover': {
        border: '1px solid #0B35B5',
        transition: 'all 0.3s ease-in-out',
      },
      '&:nth-of-type(3)': {
        border: '1px solid #0B35B5',
        paddingLeft: '22px',
        paddingRight: '40px',
        marginRight: '8px',
        color: '#0B35B5',
        fontWeight: '700',
        textTransform: 'uppercase',
        '& .MuiButton-startIcon': {
          display: 'none'
        },
        '&::before': {
          borderColor: '#0B35B5 transparent transparent transparent'
        }
      },
      '&::before': {
        content: '""',
        position: 'absolute',
        borderWidth: '6px',
        borderColor: '#797B80 transparent transparent transparent',
        borderStyle: 'solid',
        right: '12px',
        top: '15px',
      },
    }
  },
  '& .MuiDataGrid-cell--textLeft': {
    maxWidth: 'initial !important',
    // '&:nth-child(3)': {
      '&.cell-status.alarm': {
        '& .MuiDataGrid-cellContent': {
          padding: '0 5px',
          border: '1px solid rgba(240, 62, 62, 0.5)',
          background: 'rgba(240, 62, 62, 0.5)',
          color: '#262626',
          borderRadius: '20px',
          textAlign: 'center',
          minWidth: '40px',
          fontWeight: 'bold',
          fontSize: '12px'
        },
      },
      '&.cell-status.ok': {
        '& .MuiDataGrid-cellContent': {
          padding: '0 5px',
          color: '#262626',
          borderRadius: '20px',
          textAlign: 'center',
          minWidth: '40px',
          fontWeight: 'bold',
          fontSize: '12px',
          border: '1px solid rgba(55, 178, 77, 0.5)',
          background: 'rgba(55, 178, 77, 0.5)',
        }
      },
      '& .MuiDataGrid-cell': {
        '&:last-child': {
          width: '0!important'
        },
      },
    // },
    // '&:last-child': {
    //   width: '0!important'
    // }
  },
  '& .MuiDataGrid-cell': {
    '&:last-child': {
      width: '0!important'
    },
  },
  ...(type === 'recent' && {
    '& .MuiDataGrid-columnHeader--sortable': {
      width: '18.3% !important',
      maxWidth: 'initial !important',
      // '&:nth-child(3)': {
      //   width: '3% !important'
      // }
    },
    '& .MuiDataGrid-cell': {
      '&:last-child': {
        width: '0!important'
      },
    },
    '& .MuiDataGrid-cell--textLeft': {
      width: '30%',
      maxWidth: 'initial !important',
      // '&:nth-child(3)': {
        '&.cell-status.alarm': {
          '& .MuiDataGrid-cellContent': {
            padding: '0 5px',
            border: '1px solid rgba(240, 62, 62, 0.5)',
            background: 'rgba(240, 62, 62, 0.5)',
            color: '#262626',
            borderRadius: '20px',
            textAlign: 'center',
            minWidth: '40px',
            fontWeight: 'bold',
            fontSize: '12px'
          },
        },
        '&.cell-status.ok': {
          '& .MuiDataGrid-cellContent': {
            padding: '0 5px',
            color: '#262626',
            borderRadius: '20px',
            textAlign: 'center',
            minWidth: '40px',
            fontWeight: 'bold',
            fontSize: '12px',
            border: '1px solid rgba(55, 178, 77, 0.5)',
            background: 'rgba(55, 178, 77, 0.5)',
          }
        },
      // },
      // '&:last-child': {
      //   width: '0!important'
      // }
    },
  }),
  ...(type === 'all' && {
    '& .MuiDataGrid-columnHeader--sortable': {
      minWidth: '50px',
      width: '6.3%!important',
      maxWidth: 'initial !important',
      // '&:nth-child(3)': {
      //   width: '3% !important'
      // }
    },
    '& .MuiDataGrid-cell--textLeft': {
      width: '8%',
      maxWidth: 'initial !important',
        '&.cell-starttime': {
          width: '11%'
        },
        '&.cell-status.alarm': {
          '& .MuiDataGrid-cellContent': {
            padding: '0 5px',
            border: '1px solid rgba(240, 62, 62, 0.5)',
            background: 'rgba(240, 62, 62, 0.5)',
            color: '#262626',
            borderRadius: '20px',
            textAlign: 'center',
            minWidth: '40px',
            fontWeight: 'bold',
            fontSize: '12px'
          },
        },
        '&.cell-status.ok': {
          '& .MuiDataGrid-cellContent': {
            padding: '0 5px',
            color: '#262626',
            borderRadius: '20px',
            textAlign: 'center',
            minWidth: '40px',
            fontWeight: 'bold',
            fontSize: '12px',
            border: '1px solid rgba(55, 178, 77, 0.5)',
            background: 'rgba(55, 178, 77, 0.5)',
          }
        },
        '&.cell-battery': {
          minWidth: '50px',
          width: '90px',
          justifyContent: 'center'
        },
        '&.cell-lastmodify': {
          width: '11%'
        },
        '& .MuiDataGrid-cell': {
          '&:last-child': {
            width: '0!important'
          },
        },
    },
    '& .MuiDataGrid-columnHeadersInner': {
      width: '100%',
      justifyContent: 'flex-start'
    },
    
  })
}))

export default RecordGridPremium;
