import TablePagination from '@mui/material/TablePagination'
import { styled } from "@mui/material/styles";

export const RecordPagination = styled(TablePagination)(({ theme }) => ({
  background: 'white',
  borderRadius: '0 0 16px 16px',
  '&:last-child': {
    padding: '0px 20px 25px',
  },
  '& .MuiTablePagination-selectLabel': {
    display: 'none'
  },
  '& .MuiTablePagination-select': {
    display: 'none'
  },
  '& .MuiSelect-icon': {
    display: 'none'
  },
  '& .MuiTablePagination-displayedRows': {
    margin: '0'
  },
  '& .MuiTablePagination-actions .MuiButtonBase-root': {
    border: '1px solid #CED4DA',
    borderRadius: 'initial'
  },
  '& .MuiTablePagination-actions .MuiButtonBase-root:first-child': {
    marginRight: '10px'
  }
}));
