
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Avatar, TextField, Grid, Box, Typography, Button } from '@mui/material';
import { view, store } from '@risingstack/react-easy-state';
import PasswordChangeForm from '../../api/client/src/model/PasswordChangeForm';
import authStore from '../../store/authStore';

const ChangePasswordModal = view(() => {
  const store_ = store({
    users: [],
    showErrors: false,
  });
  const form = store(new PasswordChangeForm("", "", ""));

  const handleSubmit = (event) => {
    store_.showErrors = true;
    event.preventDefault();
    
    console.log(form);
    authStore.changePassword(form).then(res => console.log(res));
  };

  return (
    <Box
      sx={{
        marginTop: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        Change Password
      </Typography>
      <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              name="oldPassword"
              id="oldPassword"
              label="Current Password"
              value={form.old_password}
              onChange={(e) => form.old_password = e.target.value}
              required
              fullWidth
              autoFocus
              type="password"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="newPassword"
              id="newPassword"
              label="New Password"
              value={form.new_password}
              onChange={(e) => form.new_password = e.target.value}
              required
              fullWidth
              type="password"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              name="newPasswordConfirm"
              id="newPasswordConfirm"
              label="Confirm New Password"
              value={form.new_password_confirm}
              onChange={(e) => form.new_password_confirm = e.target.value}
              required
              fullWidth
              type="password"
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
});

export default ChangePasswordModal;