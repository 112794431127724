import * as React from "react";
import { Box } from "@mui/material";
import { view } from "@risingstack/react-easy-state";
import Header from "./Header";
import SideNav from "./SideNav";

const AllNavigation = view(() => {
  return (
    <Box>
      <Header />
      <SideNav />
    </Box>
  );
});

export default AllNavigation;
