import { view } from "@risingstack/react-easy-state";
import { Card, CardContent, Typography } from "@mui/material";

const WidgetCard = view(({ title, children, sx, ...props }) => {
  return (
    <Card
      elevation={0}
      sx={{
        backgroundColor: "#FFF",
        display: "flex",
        borderRadius: "8px",
        py: "9px",
        px: "12.5px",
        ...sx,
      }}
      {...props}
    >
      <CardContent sx={{ flex: "1 1 auto" }}>
        <Typography component="h2" variant="h5">
          {title}
        </Typography>
        {children}
      </CardContent>
    </Card>
  );
});

export default WidgetCard;
